import { View, StyleSheet, Text, TouchableOpacity } from 'react-native'
import colors from '../config/colors'

export default function AppOneWideButton({ Button, onButtonPress }) {
    return (

        <>
            <TouchableOpacity style={Styles.ButtonContainer} onPress={onButtonPress}>
                <Text style={Styles.buttonText}>{Button}</Text>
            </TouchableOpacity>
        </>
    )
}

const Styles = StyleSheet.create({

    ButtonContainer: {
        backgroundColor: colors.AppCanvasMain,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        paddingLeft: 5,
        paddingRight: 5,
        width: '100%',
        height: 40,
        borderWidth: .2,
        borderRadius: 2,
        shadowColor: colors.secondary,
        elevation: .8,
        alignSelf: 'center',
    },
    buttonText: {
        fontSize: 17,
        fontWeight: '400',
    },
})