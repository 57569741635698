import axios from 'axios'
import AsyncStorage from '@react-native-async-storage/async-storage';

const apiClient = axios.create({
   baseURL: 'https://app.wedajlms.com',
})

apiClient.interceptors.request.use(
    async config => {
        const token = await AsyncStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
        
    },
    error => {
        Promise.reject(error);
    }
);
export default apiClient; 

// import { create } from 'apisauce';
// import axios from 'axios'
// import { useContext } from 'react';
// import AsyncStorage from '@react-native-async-storage/async-storage';

// const apiClient = axios.create({
//    baseURL: 'https://app.wedajlms.com',

// })

// apiClient.interceptors.request.use(
//     async config => {
//         const token = await AsyncStorage.getItem('token');
//         if (token) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//         else{
//             await AsyncStorage.removeItem('user');
//             await AsyncStorage.removeItem('token');
//             return config;
//         }
//     },
//     error => {
//         Promise.reject(error);
//     }
// );

// export default apiClient;
 