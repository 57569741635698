import React, { useState } from 'react';
import { View, Text, FlatList, Button, StyleSheet, TextInput, TouchableOpacity } from 'react-native';
import AddFOForm from './AddFOForm';

const FreightOG = () => {
    const [freightOrders, setFreightOrders] = useState([{ id: '5621', truck: '62321', trailer: '2365', startDate: '12/23/2023', departure: 'Djibouti', destination: 'Addis Abeba', tariff: '400x250', owner: 'ATD', remark: 'No Remark' },]);

    const [isFormVisible, setFormVisible] = useState(false);
    const handleOpenForm = () => {
        setFormVisible(true);
    };

    const handleCloseForm = () => {
        setFormVisible(false);
    };

    const handleSubmitForm = (formData) => {
        // Generate a unique ID for the new entry
        const id = new Date().getTime().toString();
        // Update the state with the new entry
        setFreightOrders([...freightOrders, { id, ...formData }]);
        // Close the form
        setFormVisible(false);
    };
    const renderHeader = () => (
        <View style={styles.tableRow}>
            <Text style={styles.tableCellHeader}>Freight Order</Text>
            <Text style={styles.tableCellHeader}>Truck</Text>
            <Text style={styles.tableCellHeader}>Trailer</Text>
            <Text style={styles.tableCellHeader}>Start Date</Text>
            <Text style={styles.tableCellHeader}>Departure</Text>
            <Text style={styles.tableCellHeader}>Destination</Text>
            <Text style={styles.tableCellHeader}>Tariff</Text>
            <Text style={styles.tableCellHeader}>Owner</Text>
            <Text style={styles.tableCellHeader}>Remark</Text>
        </View>
    );
    const renderItem = ({ item }) => (
        <View style={styles.tableRow}>
            <Text style={styles.tableCell}>{item.id}</Text>
            <Text style={styles.tableCell}>{item.truck}</Text>
            <Text style={styles.tableCell}>{item.trailer}</Text>
            <Text style={styles.tableCell}>{item.startDate}</Text>
            <Text style={styles.tableCell}>{item.departure}</Text>
            <Text style={styles.tableCell}>{item.destination}</Text>
            <Text style={styles.tableCell}>{item.tariff}</Text>
            <Text style={styles.tableCell}>{item.owner}</Text>
            <Text style={styles.tableCell}>{item.remark}</Text>
        </View>
    );

    return (
        <View style={styles.container}>
            <View style={styles.buttonContainer}>
                <Button title="New Order" onPress={handleOpenForm} />
            </View>
            <Text style={styles.header}>Freight Order List</Text>

            <View style={styles.searchContainer}>
                <TextInput style={styles.searchBar} placeholder='Search Orders...'></TextInput>


                <Button title="Search" onPress={handleOpenForm} />
            </View>

            <View style={styles.body}>
                <AddFOForm isVisible={isFormVisible} onClose={handleCloseForm} onSubmit={handleSubmitForm} />
                <View style={styles.table}>{renderHeader()}</View>
                <FlatList
                    data={freightOrders}
                    keyExtractor={(item) => item.id}
                    renderItem={renderItem}
                />
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        // padding: 16,
    },
    buttonContainer: {
        width: 140
    },
    header: {
        fontSize: 24,
        fontWeight: 'bold',
        marginBottom: 5,
    },
    searchContainer: {
        flexDirection: 'row',
        marginBottom: 3,
        height: 30,
    },
    searchBar: {
        width: 200,
        // height: 35,
        borderWidth: 1,
        borderColor: 'grey',
        borderRadius: 5,
        paddingLeft: 5,
        marginRight: 3,
    },
    body: {
        flex: 1,
    },
    table: {
        borderWidth: 1,
        borderColor: '#ddd',
    },
    tableRow: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
        paddingVertical: 8,
        backgroundColor: '#f0f0f0', // Header background color
    },
    tableCellHeader: {
        flex: 1,
        padding: 8,
        fontWeight: 'bold',
    },
    tableCell: {
        flex: 1,
        padding: 8,
        alignItems: 'center',
        justifyContent: 'center'
    },
});

export default FreightOG;
