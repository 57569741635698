import React, { useState, useContext, useEffect } from 'react';
import { View, Text, TouchableOpacity, ActivityIndicator, StyleSheet, Image, ScrollView,TouchableOpacityBase, Dimensions } from 'react-native';
import colors from '../config/colors';
import AsyncStorage from '@react-native-async-storage/async-storage';
import AuthContext from '../auth/context';
import FormattedDateFancy from '../components/FormattedDateFancy'
import { Ionicons, FontAwesome, EvilIcons, FontAwesome6, MaterialCommunityIcons  } from '@expo/vector-icons';
//import { FontAwesome6 } from '@expo/vector-icons';

import {LineChart, BarChart, PieChart, ProgressChart, ContributionGraph, StackedBarChart } from "react-native-chart-kit";
import dashboard_api from '../api/dashboard_api';
import ActivityList from '../components/ActivityList'

const SCREEN_HEIGHT = Dimensions.get('window').height;


const Dashboard = () => {

  useEffect(() => {
    getActivities()
    const intervalID = setInterval(getActivities, 5000)
    return () => clearInterval(intervalID)
  }, []);
const { user, setUser, selectedCo_name, setSelectedCo_name, selectedCo_Tin, setSelectedCo_Tin } = useContext(AuthContext)
const UserID = user.userId
const coTin = user.userCompTin

const [activities, setActivities] = useState([]);
const [errorGettingActivities, seterrorGettingActivities] = useState(false)

const getActivities = async () => {
  //setgettingActivities(true)
  try {
      const response = await dashboard_api.getActivitiesForDashboard(coTin);
      if (response.status === 200) {
          setActivities(response.data);
          seterrorGettingActivities(false);
      } else {
          console.error(`Error: ${response.status}`);
          seterrorGettingActivities(false);
      }
  } catch (error) {
      console.error(error);
      seterrorGettingActivities(false);
  }
};

  const data = {
    labels: ["AA to DJI", "DJI to AA", "MOJO to DJI", "DJI to MOJO", "Other"],
    datasets: [
      {
        data: [634, 834, 429, 757, 431],
        // colors: [
        //   (opacity = 1) => `rgba(255, 0, 0, ${opacity})`, // Red
        //   (opacity = 1) => `rgba(0, 255, 0, ${opacity})`, // Green
        //   (opacity = 1) => `rgba(0, 0, 255, ${opacity})`, // Blue
        //   (opacity = 1) => `rgba(255, 165, 0, ${opacity})`, // Orange
        //   (opacity = 1) => `rgba(75, 0, 130, ${opacity})`, // Indigo
        //   (opacity = 1) => `rgba(238, 130, 238, ${opacity})`, // Violet
        // ],
      }
    ]
  };

  return (
    <View style={styles.mainContainer}>
        <View style={styles.headerCotnainer}>
            <View style={styles.headerLeft}>
                <Text style={styles.greetingText}>Hello, {user ? user.userName:null} 👋</Text>
                <Text style={styles.greetingSubText}>Happy <FormattedDateFancy dateString={new Date().toISOString()}/></Text>
            </View>
            <TouchableOpacity style={styles.headerRight}>
                <Ionicons name="notifications-outline" size={24} color="black" />
            </TouchableOpacity>
        </View>
        <View style={styles.canvaContainer}>
            <View style={styles.rowTile}>
                <View style={styles.numbersTile}>
                    <View style={styles.topNumbersTile}>
                        <TouchableOpacity style={styles.numTile}>
                            <Text style={styles.tileText}>6,833,726.53</Text>
                            <Text style={styles.tileSubText}>Sale MTD</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.numTile}>
                            <Text style={styles.tileText}>6,748,714.74</Text>
                            <Text style={styles.tileSubText}>Expense MTD</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.numTile}>
                            <Text style={styles.tileText}>255,033.00</Text>
                            <Text style={styles.tileSubText}>Commission MTD</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.topNumbersTile}>
                        <TouchableOpacity style={styles.numTile}>
                            <Text style={styles.tileText}>95,373,536.84</Text>
                            <Text style={styles.tileSubText}>Sale YTD</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.numTile}>
                            <Text style={styles.tileText}>93,472,330.74</Text>
                            <Text style={styles.tileSubText}>Expense YTD</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.numTile}>
                            <Text style={styles.tileText}>1,901,206.10</Text>
                            <Text style={styles.tileSubText}>Commission YTD</Text>
                        </TouchableOpacity>
                    </View>
                    <View style={styles.bottomNumbersTile}>
                        <TouchableOpacity style={styles.bottomNumTile}>
                            <Text style={styles.bottomTileText}>480</Text>
                            <Text style={styles.bottomTileSubText}>Delivery MTD</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.bottomNumTile}>
                            <Text style={styles.bottomTileText}>2,880</Text>
                            <Text style={styles.bottomTileSubText}>Delivery YTD</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={styles.bottomNumTile}>
                            <Text style={styles.bottomTileText}>140</Text>
                            <Text style={styles.bottomTileSubText}>Active Trips</Text>
                        </TouchableOpacity>
                        {/* <TouchableOpacity style={styles.bottomNumTile}>
                            <Text style={styles.bottomTileText}>224</Text>
                            <Text style={styles.bottomTileSubText}>Completed Trips MTD</Text>
                        </TouchableOpacity> */}
                    </View>
                </View>
                <View style={styles.chartTileContainer}>
                  <View style={styles.chartTileHeader}>
                    <Text style={styles.greetingTextReminderTile}>Delivery Overview YTD</Text>
                  </View>
                    <View style={styles.chartTile}>
                      <BarChart
                        data={data}
                        width={925}
                        height={350}
                        //data={data}
                        //width={screenWidth - 20}
                        //height={455}
                        //chartConfig={chartConfig}
                        //style={styles.chartStyle}
                        fromZero
                        showValuesOnTopOfBars
                        //chartConfig={chartConfig}
                        // chartConfig={{
                        //   backgroundColor: colors.ForeGroundWhite,
                        //   backgroundGradientFrom: "#fb8c00",
                        //   backgroundGradientTo: "#ffa726",
                        //   decimalPlaces: 0, // optional, defaults to 2dp
                        //   color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                        //   labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
                        //   style: {
                        //     borderRadius: 5
                        //   },
                        //   propsForDots: {
                        //     r: "3",
                        //     strokeWidth: "2",
                        //     stroke: colors.ForeGroundWhite
                        //   }
                        // }}
                         chartConfig = {{
                          backgroundColor: colors.ForeGroundWhite,
                          backgroundGradientFrom: colors.ForeGroundWhite,
                          backgroundGradientTo: colors.ForeGroundWhite,
                          elevation: 7,
                          decimalPlaces:0,
                          color: (opacity = 1) => `rgba(0, 45, 98 ${opacity})`,
                          labelColor: (opacity = 1) => `rgba(0, 45, 98, ${opacity})`,
                          barPercentage: 1.5,
                          useShadowColorFromDataset: false,
                          propsForBackgroundLines: {
                            strokeDasharray: "2", // No dashed lines,
                            color:colors.primary
                          },
                          propsForLabels: {
                            fontSize: 14, // Set the font size for labels here
                        },
                      }}
                        bezier
                        style={{
                            borderRadius: 7,
                            backgroundColor: colors.ForeGroundWhite,
                            width: '100%',
                            height: '100%',
                        }}
                      />
                    </View>
                </View>
            </View>
            <View style={styles.columnTile}>
                <View style={styles.activitiesTileContainer}>
                  {errorGettingActivities?null: 
                  <View style={styles.activitiesHeader}>
                      <Text style={styles.greetingText}>Activities</Text>
                  </View>
                  }
                  {errorGettingActivities ? 
                  <View style={styles.loadingContainer}>
                    <Text style={styles.loadingText}>Getting activity list, please hold.</Text>
                    <ActivityIndicator size="small" color={colors.primary} />
                    </View>
                     : (
                        <ActivityList activities={activities} />
                    )}
                  </View>
                <View style={styles.reminderTileContiner}>
                  <View style={styles.reminderHeader}>
                    <Text style={styles.greetingTextReminderTile}>Upcoming Schedule</Text>
                  </View>
                  <ScrollView style={styles.reminderTile}>

                    <View style={styles.reminderTileCard}>
                        {/* <View style={styles.reminderUserIcon}>
                          <FontAwesome6 name="money-bill-transfer" size={24} color={colors.primary} />
                          <MaterialCommunityIcons name="bank-transfer-out" size={45} color={colors.primary} />
                        </View> */}
                      <View style={styles.reminderNameandActionContainer}>
                          <View style={styles.reminderNameContainer}>
                              <Text style={styles.reminderText}>12 Trucks Payment (ESLSE)</Text>
                              <Text style={styles.reminderSubText}></Text>
                          </View>
                          <View style={styles.activitiesActionContainer}>
                              <Text style={styles.reminderSubText}>Today</Text>
                          </View>
                      </View>
                    </View>

                    <View style={styles.reminderTileCard}>
                      {/* <View style={styles.reminderUserIcon}>
                        // {/* <FontAwesome6 name="money-bill-transfer" size={24} color={colors.primary} />
                        <MaterialCommunityIcons name="bank-transfer-out" size={45} color={colors.primary} />
                      </View> */}
                      <View style={styles.reminderNameandActionContainer}>
                          <View style={styles.reminderNameContainer}>
                              <Text style={styles.reminderText}>Generate 19 Appointments</Text>
                              <Text style={styles.reminderSubText}></Text>
                          </View>
                          <View style={styles.activitiesActionContainer}>
                              <Text style={styles.reminderSubText}>Today</Text>
                          </View>
                      </View>
                    </View>

                    <View style={styles.reminderTileCard}>
                        {/* <View style={styles.reminderUserIcon}>
                          <FontAwesome6 name="money-bill-transfer" size={24} color={colors.primary} />
                          <MaterialCommunityIcons name="bank-transfer-out" size={45} color={colors.primary} />
                        </View> */}
                      <View style={styles.reminderNameandActionContainer}>
                          <View style={styles.reminderNameContainer}>
                              <Text style={styles.reminderText}>17 Trucks Payment (ESLSE)</Text>
                              <Text style={styles.reminderSubText}></Text>
                          </View>
                          <View style={styles.activitiesActionContainer}>
                              <Text style={styles.reminderSubText}>Tomorrow</Text>
                          </View>
                      </View>
                    </View>

                    <View style={styles.reminderTileCard}>
                      {/* <View style={styles.reminderUserIcon}>
                        // {/* <FontAwesome6 name="money-bill-transfer" size={24} color={colors.primary} />
                        <MaterialCommunityIcons name="bank-transfer-out" size={45} color={colors.primary} />
                      </View> */}
                      <View style={styles.reminderNameandActionContainer}>
                          <View style={styles.reminderNameContainer}>
                              <Text style={styles.reminderText}>11 Trucks Payment (ESLSE)</Text>
                              <Text style={styles.reminderSubText}></Text>
                          </View>
                          <View style={styles.activitiesActionContainer}>
                              <Text style={styles.reminderSubText}>Monday June 24</Text>
                          </View>
                      </View>
                    </View>

                    <View style={styles.reminderTileCard}>
                      {/* <View style={styles.reminderUserIcon}>
                        // {/* <FontAwesome6 name="money-bill-transfer" size={24} color={colors.primary} />
                        <MaterialCommunityIcons name="bank-transfer-out" size={45} color={colors.primary} />
                      </View> */}
                      <View style={styles.reminderNameandActionContainer}>
                          <View style={styles.reminderNameContainer}>
                              <Text style={styles.reminderText}>Generate 23 Appointments</Text>
                              <Text style={styles.reminderSubText}></Text>
                          </View>
                          <View style={styles.activitiesActionContainer}>
                              <Text style={styles.reminderSubText}>Monday June 24</Text>
                          </View>
                      </View>
                    </View>

                    <View style={styles.reminderTileCard}>
                      {/* <View style={styles.reminderUserIcon}>
                        // {/* <FontAwesome6 name="money-bill-transfer" size={24} color={colors.primary} />
                        <MaterialCommunityIcons name="bank-transfer-out" size={45} color={colors.primary} />
                      </View> */}
                      <View style={styles.reminderNameandActionContainer}>
                          <View style={styles.reminderNameContainer}>
                              <Text style={styles.reminderText}>13 Trucks Payment (ESLSE)</Text>
                              <Text style={styles.reminderSubText}></Text>
                          </View>
                          <View style={styles.activitiesActionContainer}>
                              <Text style={styles.reminderSubText}>Tuesday June 25</Text>
                          </View>
                      </View>
                    </View>

                    <View style={styles.reminderTileCard}>
                      {/* <View style={styles.reminderUserIcon}>
                        // {/* <FontAwesome6 name="money-bill-transfer" size={24} color={colors.primary} />
                        <MaterialCommunityIcons name="bank-transfer-out" size={45} color={colors.primary} />
                      </View> */}
                      <View style={styles.reminderNameandActionContainer}>
                          <View style={styles.reminderNameContainer}>
                              <Text style={styles.reminderText}>Generate 21 Appointments</Text>
                              <Text style={styles.reminderSubText}></Text>
                          </View>
                          <View style={styles.activitiesActionContainer}>
                              <Text style={styles.reminderSubText}>Wednesday June 25</Text>
                          </View>
                      </View>
                    </View>

                    <View style={styles.reminderTileCard}>
                      {/* <View style={styles.reminderUserIcon}>
                        <EvilIcons name="user" size={45} color={colors.primary} />
                      </View> */}
                      <View style={styles.reminderNameandActionContainer}>
                          <View style={styles.reminderNameContainer}>
                              <Text style={styles.reminderText}>ESLSE Invoice Prep for June</Text>
                              <Text style={styles.reminderSubText}></Text>
                          </View>
                          <View style={styles.activitiesActionContainer}>
                              <Text style={styles.reminderSubText}>In Two Weeks</Text>
                          </View>
                      </View>
                    </View>

                </ScrollView>
                  </View>
            </View>
        </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    //flex:1,
    width:'100%',
    height: SCREEN_HEIGHT,
    flexDirection: 'column',
    backgroundColor: colors.white,
  },
  headerCotnainer:{
   // flex:1,
    flexDirection:'row',
    justifyContent:'space-between',
    paddingHorizontal:19,
    paddingTop:5,
    paddingBottom:3
  },
  greetingText:{
    fontSize:22,
    // fontWeight:'400',
    paddingBottom:3,
    fontFamily:'Roboto-Bold',
    color:colors.primary
  },
  greetingTextReminderTile:{
    fontSize:22,
    //fontWeight:'700',
    marginBottom:5,
    fontFamily:'Roboto-Bold',
    color:colors.primary
  },
  greetingSubText:{
    fontSize:15,
    fontWeight:'300'
  },
  headerLeft:{
    flexDirection:'column'
  },
  headerRight:{
    alignItems:'center',
    justifyContent:'center',
    paddingRight:20
  },
  canvaContainer:{
    flex:1,
    flexDirection:'row'
  },
  rowTile: {
    flex: 1,
    flexDirection: 'column',
    width:'60%',
    alignItems:'center',
    backgroundColor: colors.white,
  },
  numbersTile: {
    height:'40%',
    width:'100%',
    flexDirection:'column',
    // flex: 1, // Provide flex to fill available space
    backgroundColor: colors.white, // Temporary background for visibility
  },
  topNumbersTile: {
    //flex: 1, // Provide flex to fill available space
    height:'32%',
    width:'100%',
    flexDirection:'row',
    justifyContent:'space-evenly',
    alignItems:'center',
    backgroundColor: colors.white, // Temporary background for visibility
  },
  numTile:{
    height:'90%',
    width:'31%',
    borderRadius:5,
    backgroundColor:colors.ForeGroundWhite,
    elevation:10,
    shadowRadius:.5,
    shadowColor:colors.primary,
    alignItems:'flex-start',
    justifyContent:'center',
  },
  bottomNumTile:{
    height:'90%',
    width:'31%',
    borderRadius:7,
    backgroundColor:colors.ForeGroundWhite,
    elevation:10,
    shadowRadius:.5,
    shadowColor:colors.primary,
    alignItems:'flex-start',
    justifyContent:'center',
  },
  tileText:{
    fontSize:30,
    fontWeight:'700',
    paddingBottom:7,
    paddingLeft:'10%',
    // alignSelf:'center',
    color:colors.primary,
    fontFamily:'Roboto-Regular'
  },
  bottomTileText:{
    fontSize:30,
    fontWeight:'700',
    paddingBottom:7,
    paddingLeft:'10%',
    // alignSelf:'center',
    color:colors.primary,
    fontFamily:'Roboto-Regular'
  },
  tileSubText:{
    fontSize:16,
    fontWeight:'400',
    // paddingBottom:3,
    // alignSelf:'center',
    paddingLeft:'10%',
    color:colors.RasinBlack,
    fontFamily:'Roboto-Light'
  },
  bottomTileSubText:{
    fontSize:16,
    fontWeight:'400',
    // paddingBottom:3,
    // alignSelf:'center',
    paddingLeft:'10%',
    color:colors.RasinBlack,
    fontFamily:'Roboto-Light'
  },
  bottomNumbersTile: {
    //flex: 1, // Provide flex to fill available space
    height:'32%',
    width:'100%',
    flexDirection:'row',
    alignItems:'center',
    justifyContent:'space-evenly',
    backgroundColor: colors.white,
  },
  chartTileContainer: {
    //flex: 1, // Provide flex to fill available space
    width:'100%',
    height:'60%',
    // alignItems:'center', 
    // justifyContent:'center',
    backgroundColor:colors.white, // Temporary background for visibility
  },
  chartTileHeader:{
    flexDirection:'row',
    alignItems:'flex-start',
    marginLeft:15,
    fontFamily:'Roboto-Regular'
  },
  chartTile:{
    height: '90%',
    width: '97%',
    backgroundColor: colors.ForeGroundWhite,
    borderRadius: 7,
    elevation: 10,
    shadowRadius: 0.5,
    //shadowColor: colors.primary,
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf:'center'
  },
  columnTile: {
    flex: 0.4, // Use flex instead of height and width
    flexDirection: 'column',
    width:'40%',
    backgroundColor: colors.white,
  },
  activitiesTileContainer: {
    //flex: 1, // Provide flex to fill available space
    height:'60%',
    backgroundColor: colors.white, // Temporary background for visibility
    alignItems:'center',
    //justifyContent:'center',
    paddingVertical:5,
    //alignSelf:'flex-start',
    width:'100%'
  },
  activitiesHeader:{
    paddingTop:0,
    paddingLeft:5,
    paddingBottom:3,
    width:'100%'
  },
  reminderTileContiner: {
    //flex: 1, // Provide flex to fill available space
    height:'38%',
    alignItems:'flex-start',
    justifyContent:'center',
    paddingTop:7,
    backgroundColor: colors.white, // Temporary background for visibility
  },
  reminderHeader:{
    paddingTop:0,
    paddingLeft:5,
    paddingBottom:3,
  },
  reminderTile:{
    height:'97%',
    width:'97%',
    backgroundColor:colors.ForeGroundWhite,
    // paddginTop:2,
    // paddingRight:2,
    // paddingBottom:2
    elevation:10,
    shadowRadius:.5,
    shadowColor:colors.primary,
    borderRadius:7,
    paddingTop:5,
    paddingBottom:5,
    fontFamily:'Roboto-Regular'
  },
  reminderTileCard:{
    height:40,
    flexDirection:'row',
    backgroundColor:colors.white,
    marginHorizontal:5,
    borderRadius:4,
    marginBottom:4,
  },
  reminderUserIcon:{
    width:'1%',
    width:'12%',
    alignItems:'center',
    justifyContent:'center',
    //backgroundColor:'white',
  },
  reminderNameandActionContainer:{
    alignItems:'flex-start',
    justifyContent:'center',
    marginLeft:2
  },
  reminderNameContainer:{
    flexDirection:'row'
  },
  reminderText:{
    fontSize:16,
    fontWeight:'400',
    paddingBottom:3,
    color:colors.primary,
    fontFamily:'Roboto-Medium'
  },
  reminderSubText:{
    fontSize:15,
    fontWeight:'300',
    color:colors.primary,
    fontFamily:'Roboto-Light'
},
loadingContainer:{
  alignItems:'center',
  justifyContent:'center',
  flex:1,
},
  loadingText:{
    fontSize:17,
    fontWeight:'500',
    color:colors.primary,
    paddingBottom:7,
},
});

export default Dashboard;

