
// function FormattedDate({ dateString }) {
//     const formatDate = (dateString) => {
//         const date = new Date(dateString);
//         date.setHours(date.getHours() + 2);
//         const options = {
//             year: 'numeric',
//             month: 'short',
//             day: 'numeric',
//             hour: 'numeric',
//             minute: 'numeric',
//             hour12: false
//         };

//         const formatter = new Intl.DateTimeFormat('en-US', options);
//         const formattedDate = formatter.format(date);

//         // Replace comma with a space before the time
//         return formattedDate.replace(', ', ' ');
//     };

//     const formattedDate = formatDate(dateString);

//     return formattedDate;
// }
// export default FormattedDate;

function FormattedDate({ dateString }) {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        //date.setHours(date.getHours() + 2);

        const currentYear = new Date().getFullYear();
        const isCurrentYear = date.getFullYear() === currentYear;


        const options = {
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            hour12: false
        };

        if (!isCurrentYear) {
            options.year = 'numeric';
        }
        const formatter = new Intl.DateTimeFormat('en-US', options);
        const formattedDate = formatter.format(date);

        // Replace comma with a space before the time
        return formattedDate.replace(', ', ' ');
    };

    const formattedDate = formatDate(dateString);

    return <span>{formattedDate}</span>;
}

export default FormattedDate;