import React, { useState, useRef, useContext, useEffect } from 'react';
import { View, FlatList, TouchableOpacity, Text, StyleSheet, TextInput, RefreshControl, ActivityIndicator, Button, Dimensions, Alert, Window, Platform } from 'react-native';
// import AppCanvas from '../config/AppCanvas';
// import Buttons from "../components/Buttons";
import FormattedDate from '../components/FormattedDate';
import MoneyFormat from '../components/MoneyFormat';
import * as Print from 'expo-print';
// import { shareAsync } from 'expo-sharing';
// import { Print } from 'expo';
import FOLister from '../components/FOLister';
import colors from '../config/colors';
import { Feather } from '@expo/vector-icons';
import AuthContext from '../auth/context';
import services from '../api/services';
import AddFOForm from './AddFOForm';
// import MapComponent from '../components/MapComponent';
import EditFOForm from './EditFOForm'
import HomeScreen from './HomeScreen';
import { AntDesign } from '@expo/vector-icons';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import PrintableComponent from '../components/PrintableComponent'
import "rsuite/dist/rsuite.min.css";
import { DatePicker } from "rsuite";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
const SCREEN_HEIGHT = Dimensions.get('window').height;

//document.title = "WEDAJ LMS";

export default function Income({ navigation }) {

    const { user, setUser, selectedCo_name, setSelectedCo_name, selectedCo_Tin, setSelectedCo_Tin } = useContext(AuthContext)

    const [fOrders, setFOrders] = useState('')
    const [refreshing, setRefreshing] = useState(true);

    const [freightOrdersPerTruck, setFreightOrdersPerTruck] = useState('')
    const [gettingTruckHistory, setGettingTruckHistory] = useState(false)
    const [selectedTruck, setSelectedTruck] = useState(false)

    const UserID = user.userId
    const coTin = user.userCompTin
    const compInUse = user.userCompName

    useEffect(() => {
        const timeoutId = setInterval(() => { }, 2000);
        getFO()
        getUnpaidFOforIncome()
        getIncomePosted()
        return () => clearInterval(timeoutId);
    }, []);

    const getFO = async () => {
        try {
            const response = await services.getFOForView(coTin);
            if (response.status === 200) {
                setFOrders(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    const searchInputRef = useRef('');

    const searchFO = async (newinput) => {
        try {
            const response = await services.searchFOForView(coTin, newinput);
            if (response.status === 200) {
                setFOrders(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    }

    const [isFormVisible, setFormVisible] = useState(false);
    const [isEditFormVisible, setEditFormVisible] = useState(false);

    const handleAddFOForm = () => {
        setFormVisible(true);
    };
    const handleEditForm = () => {
        setEditFormVisible(true)
    }
    const handleCloseForm = () => {
        setFormVisible(false);
        setFO('');
        setTruck('');
        setgrossWeight('');
        setOrderDeliveryDate(null);
        setAppDate(null);
        setOrigin('Djibouti');
        setDestination('Addis Abeba');
        setTariff('');
        setConsignee('');
        setOrderGenerator('Company')
        setRemark('');
    }

    const AddNewFO = async (formData) => {
        console.log(formData)
        console.log(coTin)
        try {
            setaddingIncome(true)
            const response = await services.postFO(formData.remark, formData.startDate, formData.orderDeliveryAgreementDate, formData.consignee, formData.fo, formData.truck, formData.grossWeight, formData.tariff, formData.origin, formData.destination, formData.orderGenerator, coTin, UserID);
            if (response.status === 200) {
                getFO()
                setaddingIncome(false);
                window.alert("Freight Order Posted.")
            } else {
                setaddingIncome(false);
                window.alert("Error posting Freight Order. Please Try again.")
                console.error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            setaddingIncome(false);
        }
        setFormVisible(false);
    };

    const UpdateFO = (item) => {
        // navigation.navigate('Edit', { item });
        console.log(item)
    };

    const getFODetail = (item) => {
        console.log(item)
    }

    const getTruckFreightHistory = async (item) => {
        setGettingTruckHistory(true)
        setSelectedTruck(item)
        try {
            const response = await services.getFOForTruckHistory(coTin, item);
            if (response.status === 200) {
                setFreightOrdersPerTruck(response.data);
                setGettingTruckHistory(false);
                console.log(freightOrdersPerTruck)
            } else {
                console.error(`Error: ${response.status}`);
                setGettingTruckHistory(false);
            }
        } catch (error) {
            console.error(error);
            setGettingTruckHistory(false);
        }
        console.log(freightOrdersPerTruck)
    };
    const renderHeader = () => (
        <View style={Styles.tableRow}>
            <Text style={Styles.tableCellHeader}>From</Text>
            <Text style={Styles.tableCellHeader}>To</Text>
            <Text style={Styles.tableCellHeader}>Owner</Text>
            <Text style={Styles.tableCellHeader}>Freight No.</Text>
            <Text style={Styles.tableDateCellHeader}>Departure</Text>
            <Text style={Styles.tableDateCellHeader}>Arrival</Text>
            {/* <Text style={Styles.tableCellHeader}>Truck</Text> */}
            {/* <Text style={Styles.tableCellHeader}>Trailer</Text> */}
            <Text style={Styles.tableCellHeader}>Tariff</Text>
            {/* <Text style={Styles.tableCellHeader}>Weight</Text> */}
            {/* <Text style={Styles.tableCellHeader}>Penality</Text>
            <Text style={Styles.tableCellHeader}>Reason</Text> */}
            <Text style={Styles.tableCellHeader}>Status</Text>
        </View>
    );
    const [selectedScreen, setSelectedScreen] = useState(null);

    const renderScreenContent = () => {
        switch (selectedScreen) {
            case 'truckTable':
                return renderTruckTable();
            case 'Appointment':
                return renderAppointment();
            case 'Loan':
                return renderLoan();
            case 'Penality':
                return renderPenality();
            case 'Income':
                return renderIncome();
            case 'Invoice':
                return renderInvoive();
            case 'Expense':
                return renderExpense();
            default:
                return renderAppointment();
            // return <HomeScreen />;
        }
    };
    const renderTruckTable = () => (
        <View style={Styles.truckDetailContainer}>
            <View style={Styles.truckDetailHeaderContainer}> {/* title container */}
                <Text style={Styles.modalTitle}>{selectedTruck ? <View style={Styles.loadedModalTitle}>Deployment History For Truck {selectedTruck}</View> : <>Deployment History</>}</Text>
                {/* <View style={Styles.twoColContainer}>
                            <View style={Styles.truckDetailLeft}>

                            </View>
                            <View style={Styles.truckDetailRight}>

                            </View>
                        </View> */}
            </View>
            <View style={Styles.tableHeader}>
                {renderHeader()}
            </View>
            <FlatList
                data={freightOrdersPerTruck}
                keyExtractor={(item) => item.idfreightOrder}
                renderItem={renderItem}
            />
        </View>
    );
    const renderExpense = () => (UserID == 1 ? <View><Text>Expense</Text></View> : <View><Text>Unauthorized</Text></View>)
    const renderInvoive = () => (<View><Text>Invoice</Text></View>)
    const renderIncome = () => (<View><Text>Income</Text></View>)
    const renderPenality = () => (<View><Text>Penality</Text></View>)
    const renderLoan = () => (<View><Text>Loan</Text></View>)

    // const printableRef = useRef();



    //---------------------------------------APPOINTMENTS---------------------------------------------

    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [input, setinput] = useState(null);
    const foInputRef = useRef('');
    const amountInputRef = useRef('');
    const voucherInputRef = useRef('');
    const reasonInputRef = useRef('');
    const AppoSearchInputRef = useRef('');
    const ExpenseSearchInputRef = useRef('');

    const [unpaidFO, setUnpaidFO] = useState('')
    const [deletingIncome, setdeletingIncome] = useState(false)
    const [incomeRegistered, setIncomeRegistered] = useState('')
    const [downloadingIncome, setDownloadingIncome] = useState(false)

    const getUnpaidFOforIncome = async () => {
        try {
            const response = await services.getUnpaidFOforIncome(coTin);
            if (response.status === 200) {
                setUnpaidFO(response.data);
                // console.log(response.data)
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    const getIncomePosted = async () => {
        try {
            const response = await services.getIncomePosted(coTin);
            if (response.status === 200) {
                setIncomeRegistered(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };
    // const renderAppointment = () => (

    // );
    const listIncome = ({ item, index }) => {

        const calculateBackgroundColor = (index) => {
            const isEven = index % 2 === 0;
            const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
            return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
        };
        return (<View style={Styles.tableContainer}>
            {/* <PrintableComponent ref={printableRef} item={item} /> */}
            <View style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }}>
                <Text style={Styles.tableCell}>{item.ip_voucher ? item.ip_voucher : null}</Text>
                <Text style={Styles.tableCell}>{item.idfreightOrder ? item.idfreightOrder : null}</Text>
                <Text style={Styles.tableCell}>{item.truck_plate ? item.truck_plate : null}</Text>
                {/* <Text style={Styles.tableCell}>{item.origin == 'Addis Ababa' ? 'A.A' : item.origin}</Text>
                <Text style={Styles.tableCell}>{item.destination == 'Addis Ababa' ? 'A.A' : item.destination}</Text> */}
                {/* <Text style={Styles.tableCell}>{item.cus_name}</Text> */}
                <Text style={Styles.tableDateCell}>{item.orderDeparture ? <FormattedDate dateString={item.orderDeparture} /> : null}</Text>
                <Text style={Styles.tableDateCell}>{item.orderUnloaingComplete ? <FormattedDate dateString={item.orderUnloaingComplete} /> : null}</Text>
                <Text style={Styles.tableCell}>{item.incoming_payment_made ? <MoneyFormat amount={item.incoming_payment_made} /> : null}</Text>
                <Text style={Styles.tableCell}>{item.ip_reason}</Text>
                <Text style={Styles.tableDateCell}>{item.ip_added_date ? <FormattedDate dateString={item.ip_added_date} /> : null}</Text>
                {/* <Text style={Styles.tableCell}>{item.orderStatus}</Text> */}
                <View style={Styles.tableCellIcon}>{deletingIncome ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="delete" size={14} color="red" onPress={() => deleteIncome(item.idfreightOrder,item.id_ip, item.ip_voucher)} />}</View>
                {/* <View style={Styles.tableCellIcon}>{deletingApp ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="printer" size={14} color={colors.primary} onPress={() => printToFile(item)} />}</View> */}
            </View>
        </View>)
    };

    const clearForm = () => {
        foInputRef.current.clear()
        amountInputRef.current.clear()
        voucherInputRef.current.clear()
        reasonInputRef.current.clear()
    }
    const clearAppSearch = () => {
        AppoSearchInputRef.current.clear()
    }
    const clearIncomePostedSearch = () => {
        ExpenseSearchInputRef.current.clear()
        setFromDate(null)
        setToDate(null)
    }
    const submitIncome = async () => {
        try {
            setaddingIncome(true)
            const response = await services.postIncome(fo, amount, voucher, reason, coTin, UserID);
            if (response.status === 200) {
                clearForm()
                setaddingIncome(false);
                getIncomePosted()
                getUnpaidFOforIncome()
                getFO()
                window.alert("Income Posted.")
            } else {
                setaddingIncome(false);
                window.alert("Error posting Income. Please try again.")
                console.error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            setaddingIncome(false);
            clearForm()
        }
    };

    const deleteIncome = async (idfreightOrder,id_ip, ip_voucher) => {
        try {
            const confirmed = window.confirm(`Are you sure you want to delete voucher: ${idfreightOrder} payment?`);
            if (!confirmed) {
                return;
            }
            setdeletingIncome(true);
            const response = await services.deleteIncome(idfreightOrder,id_ip, coTin, UserID);
            if (response.status === 200) {
                getIncomePosted();
                setdeletingIncome(false);
                window.alert("Payment deleted.");
            } else {
                setdeletingIncome(false);
                window.alert("Error deleting payment. Please try again.");
                console.error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            setdeletingIncome(false);
        }
    };

    const printToFile = async (item) => {
        const htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Payment Appointment</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 10px;
            }
            #header {
              text-align: center;
              padding: 10px;
              background-color: #f0f0f0;
            }
            #headerText {
              font-size: 18px;
              font-weight: bold;
            }
            #appointmentDetails {
              margin-top: 20px;
              border: 1px solid #ddd;
              padding: 10px;
            }
            #signature {
              margin-top: 20px;
            }
            #date {
              margin-top: 10px;
            }
          </style>
        </head>
        <body>
          <div id="header">
            <p id="headerText">Unity Transport and Logistics SC</p>
          </div>
          <div id="appointmentDetails">
            <p>Truck: ${item.truck_plate}</p>
            <p>Freight ID: ${item.idfreightOrder}</p>
            <p>From: ${item.origin}</p>
            <p>To: ${item.destination}</p>
            <!-- <p>Payment Amount: - </p> -->
             <p>Payment Date: ${item.orderPayAppointment}</p>
            <!-- Add other appointment details as needed -->
          </div>
          <div id="signature">
            <p>Prepared By: ${UserID}</p>
            <p>Signature: ____________________________</p>
          </div>
          <div id="date">
            <p>Date: ${new Date().toLocaleDateString()}</p>
          </div>
        </body>
        </html>
      `;


        // Use WebView to open a new window with the printable content
        if (Platform.OS === 'web') {
            // Create an iframe to load the HTML content
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);

            // Set the HTML content in the iframe
            iframe.contentDocument.open();
            iframe.contentDocument.write(htmlContent);
            iframe.contentDocument.close();

            // Callback function to notify the main window when the print dialog is closed
            const onPrintDialogClose = () => {
                document.body.removeChild(iframe);
                window.removeEventListener('afterprint', onPrintDialogClose);
            };

            // Listen for the 'afterprint' event, which is triggered when the print dialog is closed
            window.addEventListener('afterprint', onPrintDialogClose);

            // Trigger the print dialog
            iframe.contentWindow.print();
        } else {
            // For mobile platforms, you can use Expo Print API
            // Note: This might not work as expected on the web
            await WebView.printAsync({ html: htmlContent });
        }
    };
    const listUnpaidIncome = ({ item, index }) => {

        const calculateBackgroundColor = (index) => {
            const isEven = index % 2 === 0;
            const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
            return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
        };
        return (<>
            {/* <PrintableComponent ref={printableRef} item={item} /> */}
            <View style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }}>
                <Text style={Styles.tableCell}>{item.idfreightOrder}</Text>
                <Text style={Styles.tableCell}>{item.truck_plate}</Text>
                <Text style={Styles.tableCell}>{item.cus_name}</Text>
                <Text style={Styles.tableCell}>{item.orderDeparture ? <FormattedDate dateString={item.orderDeparture} /> : null}</Text>
                {/* <Text style={Styles.tableDateCell}>{item.orderUnloaingComplete ? <FormattedDate dateString={item.orderUnloaingComplete} /> : null}</Text> */}
                <Text style={Styles.tableCell}>{item.orderWeight} x {item.orderTariff}</Text>
                {/* <Text style={Styles.tableDateCell}>{item.orderPayAppointment ? <FormattedDate dateString={item.orderPayAppointment} /> : null}</Text> */}
                <Text style={Styles.tableCell}>{item.orderStatus}</Text>
                {/* <View style={Styles.tableCellIcon}>{deletingApp ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="delete" size={14} color="red" onPress={() => deleteAppointment(item.idfreightOrder)} />}</View> */}
                {/* <View style={Styles.tableCellIcon}>{deletingApp ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="printer" size={14} color={colors.primary} onPress={() => printToFile(item)} />}</View> */}
            </View>
        </>)
    };

    const [fo, setFO] = useState('')
    const [amount, setAmount] = useState('')
    const [voucher, setVoucher] = useState('')
    const [reason, setReason] = useState('Delivery')

    const [addingIncome, setaddingIncome] = useState(false)
    const [searchingIncome, setSearchingIncome] = useState(false)

    const searchIncome = async () => {
        // let input = null
        // console.log(input)
        console.log(fromDate)
        console.log(toDate + " to date")
        setSearchingIncome(true)
        try {
            if (!input && fromDate && toDate) {
                console.log("Entering first condition")
                const response = await services.searchIncomePostedonDates(coTin, fromDate, toDate);
                if (response.status === 200) {
                    setIncomeRegistered(response.data);
                    console.log(response)
                    setSearchingIncome(false);
                } else {
                    console.error(`Error: ${response.status}`);
                    setSearchingIncome(false);
                }
                return
            }
            else if (input && !fromDate && !toDate) {
                console.log("Entering second condition")
                const response = await services.searchIncomePosted(coTin, input);
                if (response.status === 200) {
                    setIncomeRegistered(response.data);
                    console.log(response.data)
                    setSearchingIncome(false);
                } else {
                    console.error(`Error: ${response.status}`);
                    setSearchingIncome(false);
                }
                return
            }
            else if (input && fromDate && toDate) {
                console.log("Entering third condition")
                console.log(input)
                const response = await services.searchIncomeInputWdates(coTin, input, fromDate, toDate);
                if (response.status === 200) {
                    setIncomeRegistered(response.data);
                    console.log(response.data)
                    setSearchingIncome(false);
                } else {
                    console.error(`Error: ${response.status}`);
                    setSearchingIncome(false);
                }
                return
            }

        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
        setSearchingIncome(false)
    };
    const searchReceivables = async (input) => {
        try {
            if (!input || input.trim() === '') {
                return;
            }
            const response = await services.searchReceivables(coTin, input);
            if (response.status === 200) {
                setUnpaidFO(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    const downloadIncome = async () => {
        // Create a worksheet with custom headers
        const headers = [
            'Voucher',
            'Truck',
            'Freight Order No',
            'Departure Date',
            'Arrival Date',
            'Received Amount',
            'Reason',
            'Posted'
        ];

        const data = incomeRegistered.map(item => [
            item.ip_voucher,
            item.truck_plate,
            item.idfreightOrder,
            item.orderDeparture,
            item.orderUnloaingComplete,
            item.incoming_payment_made,
            item.ip_reason,
            item.ip_added_date
        ]);

        // Create a worksheet with custom headers and data
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

        // Create a workbook
        const wb = XLSX.utils.book_new();

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, worksheet, 'Income');

        // Generate a Blob
        const wbout = XLSX.write(wb, {
            bookType: 'xlsx',
            type: 'array',
        });

        const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const filename = 'Income.xlsx';

        saveAs(blob, filename);
    };
    return (
        <View style={Styles.truckDetailContainer}>
            <View style={Styles.truckDetailHeaderContainer}> {/* title container */}
                <View style={Styles.modalTitleContainer}><Text style={Styles.modalTitle}>New Income</Text></View>
                <View style={Styles.formContainer}>
                    <TextInput
                        ref={foInputRef}
                        style={Styles.input}
                        placeholder="Freight order No."
                        onChangeText={setFO}
                    />
                    <TextInput
                        ref={amountInputRef}
                        style={Styles.input}
                        placeholder="Amount"
                        onChangeText={setAmount}
                    />
                    <TextInput
                        ref={voucherInputRef}
                        style={Styles.input}
                        placeholder="Voucher No."
                        onChangeText={setVoucher}
                    />
                    <TextInput
                        ref={reasonInputRef}
                        style={Styles.input}
                        placeholder="Reason"
                        onChangeText={setReason}
                    />
                    <TouchableOpacity style={Styles.buttonContainer} onPress={clearForm}>
                        <Text style={Styles.buttonText}>Clear</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={Styles.buttonContainer} onPress={submitIncome}>
                        {addingIncome ? <ActivityIndicator size="small" color={colors.white} /> : <Text style={Styles.buttonText}>Submit</Text>}
                    </TouchableOpacity>
                </View>
                {/* <View style={Styles.twoColContainer}>
                            <View style={Styles.truckDetailLeft}>

                            </View>
                            <View style={Styles.truckDetailRight}>

                            </View>
                        </View> */}
            </View>
            <View style={Styles.modalTitleContainer}><Text style={Styles.tableTitle}>  Receivables</Text></View>
            <View style={Styles.searchContainer}>
                <TextInput
                    ref={AppoSearchInputRef}
                    style={Styles.inputTableSearch}
                    placeholder="Search Receivable"
                    onChangeText={(input) => searchReceivables(input)}
                />
                <TouchableOpacity style={Styles.smallButtonContainer} onPress={() => { getUnpaidFOforIncome(), clearAppSearch() }}>
                    <Text style={Styles.buttonText}>Clear</Text>
                </TouchableOpacity>
            </View>
            <View style={Styles.topTable}>
                <View style={Styles.tableHeader}>
                    <View style={Styles.tableRow}>
                        <Text style={Styles.tableCellHeader}>Freight</Text>
                        <Text style={Styles.tableCellHeader}>Truck</Text>
                        <Text style={Styles.tableCellHeader}>Owner</Text>
                        <Text style={Styles.tableCellHeader}>Departure</Text>
                        <Text style={Styles.tableCellHeader}>Tarrif</Text>
                        <Text style={Styles.tableCellHeader}>Status</Text>
                    </View>
                </View>
                <FlatList
                    data={unpaidFO}
                    keyExtractor={(item) => item.idfreightOrder}
                    renderItem={listUnpaidIncome}
                />
            </View>
            <View style={Styles.modalTitleContainer}><Text style={Styles.tableTitle}>  Posted Income</Text></View>
            <View style={Styles.searchContainer}>
                {/* <TextInput
                        ref={foInputRef}
                        style={Styles.inputsmall}
                        placeholder="Freight Order"
                        onChangeText={setFO}
                    />
                    <TextInput
                        ref={amountInputRef}
                        style={Styles.inputsmall}
                        placeholder="Customer"
                        onChangeText={setAmount}
                    /> */}
                <TextInput
                    ref={ExpenseSearchInputRef}
                    style={Styles.inputTableSearch}
                    placeholder="Search Income"
                    onChangeText={(input) => setinput(input)}
                />
                <DatePicker
                    style={Styles.datepickerStyle}
                    appearance="default"
                    size='sm'
                    showicon='true'
                    cleanable
                    defaultValue={fromDate}
                    selected={fromDate}
                    onChange={(date) => setFromDate(date)}
                    // showTimeSelect
                    placeholder='From Date'
                    dateformat="Pp"
                />
                {fromDate || toDate ? <Text style={Styles.toText}> To </Text> : null}
                <DatePicker
                    style={Styles.datepickerStyle}
                    appearance="default"
                    size='sm'
                    cleanable
                    showicon='true'
                    defaultValue={toDate}
                    selected={toDate}
                    onChange={(date) => setToDate(date)}
                    // showTimeSelect
                    placeholder='To Date'
                    dateformat="Pp"
                />
                <TouchableOpacity style={Styles.smallButtonContainer} onPress={() => { getIncomePosted(), clearIncomePostedSearch() }}>
                    <Text style={Styles.buttonText}>Clear</Text>
                </TouchableOpacity>
                <TouchableOpacity style={Styles.smallButtonContainer} onPress={searchIncome}>
                    {searchingIncome ? <ActivityIndicator size="small" color={colors.white} /> : <Text style={Styles.buttonText}>Search</Text>}
                </TouchableOpacity>
                {incomeRegistered != '' ?
                    <TouchableOpacity style={Styles.smallButtonContainer} onPress={downloadIncome}>
                        {downloadingIncome ? <ActivityIndicator size="small" color={colors.white} /> : <><Text style={Styles.buttonText}><Feather name="download" size={14} color={colors.white} />  Download</Text></>}
                    </TouchableOpacity>
                    : null}
            </View>

            <View style={Styles.tableHeader}>
                <View style={Styles.tableRow}>
                    <Text style={Styles.tableCellHeader}>Voucher</Text>
                    <Text style={Styles.tableCellHeader}>Freight</Text>
                    <Text style={Styles.tableCellHeader}>Truck</Text>
                    {/* <Text style={Styles.tableCellHeader}>From</Text>
                    <Text style={Styles.tableCellHeader}>To</Text> */}
                    {/* <Text style={Styles.tableCellHeader}>Owner</Text> */}
                    <Text style={Styles.tableDateCellHeader}>Departure</Text>
                    <Text style={Styles.tableDateCellHeader}>Arrival</Text>
                    <Text style={Styles.tableCellHeader}>Amount</Text>
                    <Text style={Styles.tableCellHeader}>Reason</Text>
                    <Text style={Styles.tableDateCellHeader}>Posted</Text>
                    {/* <Text style={Styles.tableCellHeader}>Status</Text> */}
                    <Text style={Styles.tableCellHeaderSmall}>Delete</Text>
                    {/* <Text style={Styles.tableCellHeaderSmall}>Print</Text> */}
                </View>
            </View>
            <FlatList
                data={incomeRegistered}
                keyExtractor={(item) => item.id_ip}
                renderItem={listIncome}
            />
        </View>
    );
}

const Styles = StyleSheet.create({
    smallMenuContainer: {
        elevation: 8,
        backgroundColor: '#031D22',
        height: '10%',
        width: '100%',
        borderRadius: 5,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    smallMenuButton: {
        // backgroundColor: 'green',
        paddingHorizontal: 9,
        paddingVertical: 6,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    selectedsmallMenuButton: {
        // backgroundColor: 'grey',
        backgroundColor: '#09414c',
        paddingHorizontal: 9,
        paddingVertical: 6,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    smallMenuText: {
        fontSize: 16,
        fontWeight: 600,
        color: colors.offwhite,
    },
    truckDetailContainer: {
        height: '90%',
        width: '100%',
        margin: 2,
        elevation: 8,
        borderRadius: 5,
        backgroundColor: colors.AppCanvasMain,
    },
    truckDetailHeaderContainer: {
        // marginTop: 4,
        height: '12%',
        width: '99.7%',
        flexDirection: 'column',
        // alignItems: 'flex-start',
        backgroundColor: colors.AppCanvasMain,
        elevation: 9,
        borderRadius: 5,
        justifyContent: 'center',
        paddingLeft: 5,
        // marginBottom: 5,
        paddingTop: 5,
        borderColor: colors.skyblue,
        borderWidth: 1
    },
    modalTitleContainer: {
        // backgroundColor: 'orange',

    },
    twoColContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    truckDetailLeft: {
        height: '100%',
        width: '50%',
        // backgroundColor: 'ornage'
    },
    truckDetailRight: {
        height: '100%',
        width: '50%',
        backgroundColor: 'green'
    },
    topTable: {
        height: '35%',
        width: '100%',
    },
    tableHeader: {
        // height: '40%',
        width: '100%',
        // backgroundColor: 'green',
        borderWidth: 1,
        marginLeft: 4,
        borderColor: 'skyblue',
    },
    searchContainer: {
        // width: '25%',
        // flex: 1,
        flexDirection: "Row",
        // height: 30,
        marginBottom: 2
    },
    tableRow: {
        flex: 1,
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'skyblue',
        //paddingVertical: 3,
        marginHorizontal: 5,
        paddingVertical: 5,
        backgroundColor: '#f0f0f0', // Header background color,
        // alignItems: 'center',
        // justifyContent: 'center'
    },
    tableContainer: {
        maxHeight: '500',
    },
    tableCellHeader: {
        flex: .25,
        // padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 3,
    },
    tableCellHeaderSmall: {
        flex: .2,
        // padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableDateCellHeader: {
        flex: .5,
        padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCell: {
        flex: .25,
        // marginLeft: 3,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 3,
    },
    tableDateCell: {
        flex: .5,
        // marginLeft: 3,
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCellIcon: {
        flex: .2,
        // paddingLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop: 5,
    },
    tableTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop: 5,
        marginBottom: 2,
    },
    toText: {
        fontSize: 12,
        fontWeight: 'bold',
        marginTop: 5,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 2,
        marginHorizontal: 5,
    },
    formContainer: {
        flexDirection: 'row',
        //alignItems: 'center'
        marginTop: 5,
        marginBottom: 5,
        paddingBottom: 5,
    },
    input: {
        height: 35,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        width: 160
    },
    inputsmall: {
        height: 27,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        width: 100
    },
    inputTableSearch: {
        height: 30,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        marginLeft: 2,
        marginBottom: 2,
        width: 160
    },
    datepickerStyle: {
        // backgroundColor: 'grey',
        //  width: 50
        // height: 30
    },
    buttonContainer: {
        // width: 100,
        marginLeft: 3,
        // alignSelf: 'flex-end',
        // justifyContent: 'space-evenly',
        // flexDirection: 'row',
        marginTop: 2,
        paddingHorizontal: 5,
        backgroundColor: '#2196F3',
        height: 33,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    smallButtonContainer: {
        // width: 100,
        marginLeft: 2,
        // alignSelf: 'flex-end',
        // justifyContent: 'space-evenly',
        // flexDirection: 'row',
        marginTop: 2,
        paddingHorizontal: 3,
        backgroundColor: '#2196F3',
        height: 28,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    buttonText: {
        color: 'white',
        fontWeight: 600,
        paddingHorizontal: 3,
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.AppCanvasMain
    },
    list: {
        width: '100%',
    },
    contactItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'gray',
    },
    contactName: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    mainContainer: {
        flex: 1,
        width: '100%',
        // alignItems: 'center',
        // justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: colors.AppCanvasMain,
        height: SCREEN_HEIGHT,
    },
    headerContainer: {
        flexDirection: 'row',
        // alignItems: ''
        justifyContent: 'space-between',
        width: '100%',
        //  backgroundColor: colors.offwhite,
        paddingHorizontal: 12,
        backgroundColor: colors.AppCanvasMain,
        //paddingVertical: 10,
    },
    headerRight: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginRight: 
    },
    headeText: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    searchBarContainer: {
        width: "100%",
        height: 40,
        flexDirection: "row",
        alignContent: "space-between",
        // justifyContent: "center",
        backgroundColor: colors.AppCanvasMain
    },
    FOContainer: {
        width: "100%",
        // height: 350,
        flex: 1,
        flexDirection: "row",
        // alignContent: "space-between",
        justifyContent: "center",

        // alignItems: 'center',
        // backgroundColor: 'orange',
        paddingTop: 2,
        backgroundColor: colors.AppCanvasMain,
        // height: SCREEN_HEIGHT,
    },
    Leftview: {
        width: '27%',
        flexDirection: 'column',
        height: (SCREEN_HEIGHT - 5),
    },
    rightView: {
        backgroundColor: colors.AppCanvasMain,
        width: '73%',
        height: SCREEN_HEIGHT,
    },
    NoFOContainer: {
        width: "100%",
        // height: 350,
        flex: 1,
        flexDirection: "row",
        // alignContent: "space-between",
        justifyContent: "center",

        alignItems: 'center',
        // backgroundColor: 'orange',
        paddingTop: 5,
        flexDirection: 'column',
        backgroundColor: colors.AppCanvasMain
    },
    AppTextInput: {
        width: "80%",
        height: 40,
        borderRadius: 5,
        borderColor: colors.primary,
        borderWidth: 0.5,
        paddingLeft: 10,
        marginLeft: 5,
        //position: "relative",
        top: 0,
        //alignItems: "center",
        fontSize: 14,
        color: colors.primary,
    },
});