import React from 'react';
import { StyleSheet, View, Text } from 'react-native';

const PrintableComponent = ({ item }) => {
    return (
        <View style={styles.printableContainer}>
            <View style={styles.header}>
                <Text style={styles.headerText}>Unity Transport and Logistics SC</Text>
            </View>
            <View style={styles.content}>
                <Text style={styles.title}>Payment Appointment</Text>
                <Text>Freight ID: {item.idfreightOrder}</Text>
                <Text>Origin: {item.origin}</Text>
                <Text>Destination: {item.destination}</Text>
                {/* Add other row details as needed */}
            </View>
            <View style={styles.footer}>
                <Text style={styles.signature}>Signature: ____________________</Text>
                <Text style={styles.date}>Date: ____________________</Text>
            </View>
        </View>
    );
};

const styles = StyleSheet.create({
    printableContainer: {
        padding: 20,
        backgroundColor: '#fff',
        borderRadius: 10,
        border: '1px solid #ccc',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    header: {
        marginBottom: 20,
    },
    headerText: {
        fontSize: 20,
        fontWeight: 'bold',
    },
    content: {
        marginBottom: 20,
    },
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 10,
    },
    footer: {
        borderTop: '1px solid #ccc',
        paddingTop: 10,
    },
    signature: {
        marginBottom: 10,
    },
    date: {
        marginBottom: 10,
    },
});

export default PrintableComponent;
