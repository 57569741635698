import React from 'react';
import { StyleSheet, Text } from 'react-native';

import colors from '../config/colors';

export default function AppText({ children }) {
    return (
        <Text style={styles.header}>{children}</Text>
    )
}

const styles = StyleSheet.create({
    header: {
        fontSize: 13,
        fontFamily: 'sans-serif-light',
        fontWeight: '600',
        color: colors.red,
        alignSelf: 'flex-start',
        paddingLeft: 10,
    }
})