import React, { useState, useRef, useContext, useEffect } from 'react';
import { View, FlatList, TouchableOpacity, Text, StyleSheet, TextInput, RefreshControl, ActivityIndicator, Button, Dimensions, Alert, Window, Platform } from 'react-native';
// import AppCanvas from '../config/AppCanvas';
// import Buttons from "../components/Buttons";
import Checkbox from 'expo-checkbox'
import FormattedDate from '../components/FormattedDate';
import MoneyFormat from '../components/MoneyFormat';
import * as Print from 'expo-print';
// import { shareAsync } from 'expo-sharing';
// import { Print } from 'expo';
import FOLister from '../components/FOLister';
import colors from '../config/colors';
import { Feather } from '@expo/vector-icons';
import AuthContext from '../auth/context';
import services from '../api/services';
import AddFOForm from './AddFOForm';
// import MapComponent from '../components/MapComponent';
import EditFOForm from './EditFOForm'
import HomeScreen from './HomeScreen';
import { AntDesign } from '@expo/vector-icons';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import PrintableComponent from '../components/PrintableComponent'
import "rsuite/dist/rsuite.min.css";
import { DatePicker } from "rsuite";
import Expense from './Expense';
import Loan from './Loan';
import Penality from './Penality';
import Invoice from './Invoice';
import Income from './Income'
import FormattedDateNoHR from '../components/FormattedDateNoHR';

//document.title = "WEDAJ LMS";

const SCREEN_HEIGHT = Dimensions.get('window').height;

export default function Appointment({ navigation }) {

    const { user } = useContext(AuthContext)

    const [fOrders, setFOrders] = useState('')
    const [refreshing, setRefreshing] = useState(true);
    const [addingApp, setaddingNewApp] = useState(false)

    const [freightOrdersPerTruck, setFreightOrdersPerTruck] = useState('')
    const [gettingTruckHistory, setGettingTruckHistory] = useState(false)
    const [selectedTruck, setSelectedTruck] = useState(false)

    const UserID = user.userId
    const UserName = user.userName
    const coTin = user.userCompTin
    const compInUse = user.userCompName

    useEffect(() => {
        const timeoutId = setInterval(() => { }, 2000);
        getFO()
        getAppointments()
        const currentDate = new Date();
        const oneMonthFromNow = new Date();
        oneMonthFromNow.setMonth(currentDate.getMonth() + 1);
        setAppDate(oneMonthFromNow);
        return () => clearInterval(timeoutId);
    }, []);

    const getFO = async () => {
        try {
            const response = await services.getFOForView(coTin);
            if (response.status === 200) {
                setFOrders(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    const searchInputRef = useRef('');

    const searchFO = async (newinput) => {
        try {
            const response = await services.searchFOForView(coTin, newinput);
            if (response.status === 200) {
                setFOrders(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    }

    const [isFormVisible, setFormVisible] = useState(false);
    const [isEditFormVisible, setEditFormVisible] = useState(false);

    const handleAddFOForm = () => {
        setFormVisible(true);
    };
    const handleEditForm = () => {
        setEditFormVisible(true)
    }
    const handleCloseForm = () => {
        setFormVisible(false);
        setFO('');
        setTruck('');
        setgrossWeight('');
        setOrderDeliveryDate(null);
        setAppDate(null);
        setOrigin('Djibouti');
        setDestination('Addis Abeba');
        setTariff('');
        setConsignee('');
        setOrderGenerator('Company')
        setRemark('');
    }

    const AddNewFO = async (formData) => {
        console.log(formData)
        console.log(coTin)
        try {
            setaddingNewApp(true)
            const response = await services.postFO(formData.remark, formData.startDate, formData.orderDeliveryAgreementDate, formData.consignee, formData.fo, formData.truck, formData.grossWeight, formData.tariff, formData.origin, formData.destination, formData.orderGenerator, coTin, UserID);
            if (response.status === 200) {
                getFO()
                setaddingNewApp(false);
                window.alert("Freight Order Posted.")
            } else {
                setaddingNewApp(false);
                window.alert("Error posting Freight Order. Please Try again.")
                console.error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            setaddingNewApp(false);
        }
        setFormVisible(false);
    };

    const UpdateFO = (item) => {
        // navigation.navigate('Edit', { item });
        console.log(item)
    };

    const getFODetail = (item) => {
        console.log(item)
    }

    const getTruckFreightHistory = async (item) => {
        setGettingTruckHistory(true)
        setSelectedTruck(item)
        try {
            const response = await services.getFOForTruckHistory(coTin, item);
            if (response.status === 200) {
                setFreightOrdersPerTruck(response.data);
                setGettingTruckHistory(false);
                console.log(freightOrdersPerTruck)
            } else {
                console.error(`Error: ${response.status}`);
                setGettingTruckHistory(false);
            }
        } catch (error) {
            console.error(error);
            setGettingTruckHistory(false);
        }
        console.log(freightOrdersPerTruck)
    };
    const renderHeader = () => (
        <View style={Styles.tableRow}>
            <Text style={Styles.tableCellHeader}>From</Text>
            <Text style={Styles.tableCellHeader}>To</Text>
            <Text style={Styles.tableCellHeader}>Owner</Text>
            <Text style={Styles.tableCellHeader}>Freight No.</Text>
            <Text style={Styles.tableDateCellHeader}>Departure</Text>
            <Text style={Styles.tableDateCellHeader}>Arrival</Text>
            {/* <Text style={Styles.tableCellHeader}>Truck</Text> */}
            {/* <Text style={Styles.tableCellHeader}>Trailer</Text> */}
            <Text style={Styles.tableDateCellHeader}>Tariff</Text>
            {/* <Text style={Styles.tableCellHeader}>Weight</Text> */}
            {/* <Text style={Styles.tableCellHeader}>Penality</Text>
            <Text style={Styles.tableCellHeader}>Reason</Text> */}
            <Text style={Styles.tableCellHeader}>Status</Text>
        </View>
    );
    const [selectedScreen, setSelectedScreen] = useState(null);

    const renderScreenContent = () => {
        switch (selectedScreen) {
            case 'truckTable':
                return renderTruckTable();
            case 'Appointment':
                return renderAppointment();
            case 'Loan':
                return renderLoan();
            case 'Penality':
                return renderPenality();
            case 'Income':
                return renderIncome();
            case 'Invoice':
                return renderInvoive();
            case 'Expense':
                return renderExpense();
            default:
                return renderAppointment();
            // return <HomeScreen />;
        }
    };
    const renderTruckTable = () => (
        <View style={Styles.truckDetailContainer}>
        <View style={Styles.truckDetailHeaderContainerMain}> {/* title container */}
            {selectedTruck ? <View style={Styles.loadedModalTitle}><Text style={Styles.modalTitle}>Deployment History For Truck {selectedTruck}</Text></View> : <Text style={Styles.modalTitle}>No Deployment History Found</Text>}
            {/* <View style={Styles.twoColContainer}>
            <View style={Styles.truckDetailLeft}>

            </View>
            <View style={Styles.truckDetailRight}>

            </View>
        </View> */}
         <Button title='X' onPress={() => { setSelectedScreen(''), setFreightOrdersPerTruck(null)}} />
         </View>
            
        <View style={Styles.truckDetailTable}>
            {renderHeader()}
        </View>
        <FlatList
            data={freightOrdersPerTruck}
            keyExtractor={(item) => item.idfreightOrder}
            renderItem={renderItem}
        />
    </View>
    );
    const renderExpense = () => (<Expense />)
    const renderInvoive = () => (<Invoice />)
    const renderIncome = () => (<Income />)
    const renderPenality = () => (<Penality />)
    const renderLoan = () => (<Loan />)

    // const printableRef = useRef();



    //---------------------------------------APPOINTMENTS---------------------------------------------
    const [fo, setFO] = useState('')
    const [appDate, setAppDate] = useState(null);
    const [appRemark, setAppRemark] = useState('');
    const AppointmentInputRef = useRef('');
    const AppointmentRemarkInputRef = useRef('');
    const [appointments, setAppointments] = useState('')
    const [deletingApp, setdeletingApp] = useState(false)
    // checkbox list
    const [djiboutiWaybill, setDjiboutiWaybill] = useState(true)
    const [mojoIn, setMojoIn] = useState(true)
    const [gelanIn, setGelanIn] = useState(true)
    const [cometIn, setCometIn] = useState(true)
    const [mekeleIn, setMekeleIn] = useState(true)
    const [combolchaIn, setCombolchaIn] = useState(true)
    const [diredewaIn, setDiredewaIn] = useState(true)
    const [interchangeOut, setInterchangeOut] = useState(true)
    const [foDoc, setFoDoc] = useState(true)
    const [galafiLetter, setGalafiLetter] = useState(true)
    const [interchangeIn, setInterchangeIn] = useState(true)

    const [mojoOut, setMojoOut] = useState(true)
    const [gelanOut, setGelanOut] = useState(true)
    const [cometOut, setCometOut] = useState(true)
    const [mekeleOut, setMekeleOut] = useState(true)
    const [combolchaOut, setCombolchaOut] = useState(true)
    const [diredewaOut, setDiredewaOut] = useState(true)
    const [gcsDjibouti, setGcsDjibouti] = useState(true)

    const getAppointments = async () => {
        try {
            const response = await services.getAppointments(coTin);
            if (response.status === 200) {
                setAppointments(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };
    const AppoSearchInputRef = useRef('');
    const clearAppSearch = () => {
        AppoSearchInputRef.current.clear()
    }

    const renderAppointment = () => (
        <View style={Styles.truckDetailContainer}>
            <View style={Styles.appointmentHeaderContainer}> {/* title container */}
                <View style={Styles.modalTitleContainer}><Text style={Styles.modalTitle}>New Appointment</Text></View>
                <View style={Styles.formContainer}>
                    <TextInput
                        ref={AppointmentInputRef}
                        style={Styles.input}
                        placeholder="Freight order No."
                        onChangeText={setFO}
                    />
                    <TextInput
                        ref={AppointmentRemarkInputRef}
                        style={Styles.input}
                        placeholder="Remark"
                        onChangeText={setAppRemark}
                    />
                    <DatePicker
                        style={Styles.datepickerStyle}
                        appearance="default"
                        showIcon
                        defaultValue={appDate}
                        selected={appDate}
                        onChange={(date) => setAppDate(date)}
                        // showTimeSelect
                        placeholderText='Appointment Date'
                        dateFormat="Pp"
                    />
                    <TouchableOpacity style={Styles.buttonContainer} onPress={submitAppointment}>
                        {addingApp ? <ActivityIndicator size="small" color={colors.white} /> : <Text style={Styles.buttonText}>Submit</Text>}
                    </TouchableOpacity>
                </View>
                <View style={Styles.twoColContainer}>
                    <View style={Styles.firstRow}>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={djiboutiWaybill}
                                onValueChange={setDjiboutiWaybill}
                                color={djiboutiWaybill ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ጅቡቲ ዌብል</Text>
                        </View>

                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={foDoc}
                                onValueChange={setFoDoc}
                                color={foDoc ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>የጭነት ማዘዣ</Text>
                        </View>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={galafiLetter}
                                onValueChange={setGalafiLetter}
                                color={galafiLetter ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ጋላፊ ደብዳቤ</Text>
                        </View>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={gcsDjibouti}
                                onValueChange={setGcsDjibouti}
                                color={gcsDjibouti ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ጂ.ሲ.ኤስ አውት</Text>
                        </View>
                    </View>

                    <View style={Styles.secondRow}>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={mojoIn}
                                onValueChange={setMojoIn}
                                color={mojoIn ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ሞጆ ኢን</Text>
                        </View>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={gelanIn}
                                onValueChange={setGelanIn}
                                color={gelanIn ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ገላን ኢን</Text>
                        </View>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={cometIn}
                                onValueChange={setCometIn}
                                color={cometIn ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ኮሜት ኢን</Text>
                        </View>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={mekeleIn}
                                onValueChange={setMekeleIn}
                                color={mekeleIn ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>መቀሌ ኢን</Text>
                        </View>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={combolchaIn}
                                onValueChange={setCombolchaIn}
                                color={combolchaIn ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ኮምቦልቻ ኢን</Text>
                        </View>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={diredewaIn}
                                onValueChange={setDiredewaIn}
                                color={diredewaIn ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ድሬደዋ ኢን</Text>
                        </View>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={interchangeIn}
                                onValueChange={setInterchangeIn}
                                color={interchangeIn ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ኢንተርቼንጅ ኢን</Text>
                        </View>

                    </View>
                    <View style={Styles.thirdRow}>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={mojoOut}
                                onValueChange={setMojoOut}
                                color={mojoOut ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ሞጆ አውት</Text>
                        </View>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={gelanOut}
                                onValueChange={setGelanOut}
                                color={gelanOut ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ገላን አውት</Text>
                        </View>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={cometOut}
                                onValueChange={setCometOut}
                                color={cometOut ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ኮሜት አውት</Text>
                        </View>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={mekeleOut}
                                onValueChange={setMekeleOut}
                                color={mekeleOut ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>መቀሌ አውት</Text>
                        </View>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={combolchaOut}
                                onValueChange={setCombolchaOut}
                                color={combolchaOut ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ኮምቦልቻ አውት</Text>
                        </View>
                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={diredewaOut}
                                onValueChange={setDiredewaOut}
                                color={diredewaOut ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ድረደዋ አውት</Text>
                        </View>

                        <View style={Styles.checkboxContainer}>
                            <Checkbox
                                style={Styles.checkbox}
                                value={interchangeOut}
                                onValueChange={setInterchangeOut}
                                color={interchangeOut ? 'green' : undefined}
                            />
                            <Text style={Styles.checkBoxTitle}>ኢንተርቸንጅ አውት</Text>
                        </View>
                    </View>
                </View>
            </View>

            <View style={Styles.searchAppointmentContainer}>
                <View style={Styles.modalTitleContainer}><Text style={Styles.modalTitle}>Search Appointment</Text></View>
                <View style={Styles.searchContainer}>
                    <TextInput
                        ref={AppoSearchInputRef}
                        style={Styles.searchInput}
                        placeholder="Search Appointment"
                        onChangeText={(input) => searchAppointment(input)}
                    />
                    <TouchableOpacity style={Styles.smallButtonContainer} onPress={() => { getAppointments(), clearAppSearch() }}>
                        <Text style={Styles.buttonText}>Clear</Text>
                    </TouchableOpacity>
                </View>
            </View>

            <View style={Styles.truckDetailTable}>
                <View style={Styles.tableRow}>
                    <Text style={Styles.tableCellHeaderSmall}>Print</Text>
                    <Text style={Styles.tableCellHeader}>Freight</Text>
                    <Text style={Styles.tableCellHeader}>App Id</Text>
                    <Text style={Styles.tableCellHeaderLarge}>Truck</Text>
                    <Text style={Styles.tableCellHeader}>From</Text>
                    <Text style={Styles.tableCellHeader}>To</Text>
                    <Text style={Styles.tableCellHeader}>Owner</Text>
                    <Text style={Styles.tableCellHeader}>Departure</Text>
                    <Text style={Styles.tableCellHeader}>Arrival</Text>
                    <Text style={Styles.tableCellHeader}>Pay Day</Text>
                    {/* <Text style={Styles.tableCellHeader}>Status</Text> */}
                    <Text style={Styles.tableDateCellHeader}>Missing Doc</Text>
                    <Text style={Styles.tableCellHeader}>Remark</Text>
                    <Text style={Styles.tableCellHeaderSmall}>Delete</Text>
                </View>
            </View>
            <FlatList
                data={appointments}
                keyExtractor={(item) => item.idfreightOrder}
                renderItem={listAppointments}
            />
        </View>
    );
    const [documentsMissingIndices, setDocumentsMissingIndices] = useState([]);
    const listAppointments = ({ item, index }) => {

        const calculateBackgroundColor = (index) => {
            const isEven = index % 2 === 0;
            const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
            return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
        };
        const missingDocuments = [
            "mojoIn", "gelanIn", "cometIn", "mekeleIn",
            "combolchaIn", "diredewaIn", "interchangeOut",
            "foDoc", "galafiLetter", "interchangeIn",
            "mojoOut", "gelanOut", "cometOut", "mekeleOut",
            "combolchaOut", "diredewaOut"
        ];

        const documentsMissing = missingDocuments.filter((key) => item[key] === 0);

        return (<>
            {/* <PrintableComponent ref={printableRef} item={item} /> */}
            <View style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }}>
                <View style={Styles.tableCellIcon}>{<AntDesign name="printer" size={14} color={colors.primary} onPress={() => printToFile(item, documentsMissing)} />}</View>
                <Text style={Styles.tableCell}>   {item.idfreightOrder}</Text>
                <Text style={Styles.tableCell}>{item.id_app ? item.id_app : null}</Text>
                <Text style={Styles.tableCellLarge}>{item.truck_plate}</Text>
                <Text style={Styles.tableCell}>{item.origin == 'Addis Ababa' ? 'A.A' : item.origin}</Text>
                <Text style={Styles.tableCell}>{item.destination == 'Addis Ababa' ? 'A.A' : item.destination}</Text>
                <Text style={Styles.tableCell}>{item.cus_name}</Text>
                <Text style={Styles.tableCell}>{item.orderDeparture ? <FormattedDateNoHR dateString={item.orderDeparture} /> : null}</Text>
                <Text style={Styles.tableCell}>{<FormattedDateNoHR dateString={item.orderUnloaingComplete} />}</Text>
                <Text style={Styles.tableCell}>{<FormattedDateNoHR dateString={item.orderPayAppointment} />}</Text>
                {/* <Text style={Styles.tableCell}>{item.orderStatus == "Completed" ? <AntDesign name="checkcircle" size={14} color="green" /> : item.orderStatus}</Text> */}
                {/* <Text style={Styles.tableCell}>{item.orderStatus}</Text> */}
                <Text style={Styles.tableCell}>{documentsMissing.length > 0 ? documentsMissing.join(", ") : "-"}</Text>
                <Text style={Styles.tableCell}>{item.app_remark ? item.app_remark : "-"}</Text>
                <View style={Styles.tableCellIcon}>{deletingApp && deletingApp == item.idfreightOrder ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="delete" size={14} color="red" onPress={() => deleteAppointment(item.idfreightOrder)} />}</View>
            </View>
        </>)
    };
    const clearAppointment = () => {
        AppointmentInputRef.current.clear()
        AppointmentRemarkInputRef.current.clear()
        setDjiboutiWaybill(true), setMojoIn(true), setGelanIn(true), setCometIn(true), setMekeleIn(true), setCombolchaIn(true), setDiredewaIn(true), setInterchangeOut(true), setFoDoc(true), setGalafiLetter(true), setInterchangeIn(true), setMojoOut(true), setGelanOut(true), setCometOut(true), setMekeleOut(true), setCombolchaOut(true), setDiredewaOut(true), setGcsDjibouti(true)
    }
    const submitAppointment = async () => {

        try {
            setaddingNewApp(true)
            const response = await services.postAppointment(fo, appDate, appRemark, coTin, UserID, djiboutiWaybill, mojoIn, gelanIn, cometIn, mekeleIn, combolchaIn, diredewaIn, interchangeOut, foDoc, galafiLetter, interchangeIn, mojoOut, gelanOut, cometOut, mekeleOut, combolchaOut, diredewaOut, gcsDjibouti);
            if (response.status === 200) {
                clearAppointment()
                setaddingNewApp(false);
                getAppointments()
                getFO()
                window.alert("Appointment Posted.")
            } else {
                setaddingNewApp(false);
                window.alert("Error posting appointment. Please Try again.")
                console.error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            setaddingNewApp(false);
            clearAppointment()
        }
    };
    const searchAppointment = async (input) => {
        try {
            if (!input || input.trim() === '') {
                return;
            }
            const response = await services.searchAppointment(coTin, input);
            if (response.status === 200) {
                setAppointments(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    const deleteAppointment = async (freightID) => {
        try {
            const confirmed = window.confirm("Are you sure you want to delete this payment appointment?");
            if (!confirmed) {
                return;
            }
            setdeletingApp(freightID);
            const response = await services.deleteAppointment(freightID, coTin, UserID);
            if (response.status === 200) {
                getAppointments();
                window.alert("Appointment deleted.");
                setdeletingApp(false);
            } else {
                setdeletingApp(false);
                window.alert("Error deleting appointment. Please try again.");
                console.error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            setdeletingApp(false);
        }
    };

    const printToFile = async (item, documentsMissing) => {
        const htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
            <meta charset="UTF-8">
            <meta name="viewport" content="width=device-width, initial-scale=1.0">
            <title>Payment Appointment</title>
            <style>
                body {
                    font-family: 'Arial', sans-serif;
                    margin: 0;
                    padding: 20px;
                    color: #333;
                    background-color: #f4f4f4;
                }
                .container {
                    max-width: 800px;
                    margin: 0 auto;
                    padding: 20px;
                    background-color: #fff;
                    border-radius: 8px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                }
                #header {
                    text-align: center;
                    padding: 20px;
                    background-color: #e7e7e7;
                    border-bottom: 2px solid #ddd;
                    border-radius: 8px 8px 0 0;
                    position: relative;
                }
                #logo {
                    width: 100px;
                    height: auto;
                    position: absolute;
                    top: 10px;
                    left: 10px;
                }
                #headerText {
                    font-size: 24px;
                    font-weight: bold;
                    margin: 0;
                }
                #headerBodyText {
                    font-size: 14px;
                    margin: 5px 0 0 0;
                }
                #idText {
                    text-align: right;
                    font-size: 14px;
                    margin: 10px 0;
                }
                #appointmentDetails {
                    margin-top: 20px;
                    padding: 20px;
                    background-color: #f9f9f9;
                    border: 1px solid #ddd;
                    border-radius: 8px;
                }
                #appointmentDetails p {
                    margin: 5px 0;
                    font-size: 14px;
                }
                #signature {
                    margin-top: 20px;
                    padding-top: 20px;
                    border-top: 1px solid #ddd;
                }
                #signature p {
                    margin: 5px 0;
                }
                #footer {
                    margin-top: 40px;
                    padding-top: 40px;
                    
                }
                #footer p {
                    font-size: 9px;
                    margin: 0 0;
                }
            </style>
        </head>
        <body>
            <div class="container">
                <div id="header">
                    <p id="headerText">Unity Transport and Logistics SC</p>
                    <p id="headerBodyText">Tel: 0114708234/708688 | Djibouti: 0025377135907 | Mob: 0944739873 | Fax: 0114401521</p>
                </div>
                <div>
                    <p id="idText">No. ${item.id_app}</p>
                </div>
                <div id="appointmentDetails">
                    <p><strong>Truck:</strong> ${item.truck_plate} &nbsp; <strong>Freight ID:</strong> ${item.idfreightOrder}</p>
                    <p><strong>From:</strong> ${item.origin} &nbsp; <strong>To:</strong> ${item.destination}</p>
                    <p><strong>Tariff:</strong> ${item.orderWeight} x ${item.orderTariff} ${item.orderContainer ? ` &nbsp; <strong>Container:</strong> ${item.orderContainer}` : ''}</p>
                    <p>${documentsMissing.length > 0 ? `<strong>Missing Documents:</strong> ${documentsMissing.join(", ")}` : ''}</p>
                    <p><strong>Payment Date:</strong> ${item.orderPayAppointment}</p>
                    <p>${item.app_remark ? `<strong>Remark:</strong> ${item.app_remark}` : ''}</p>
                </div>
                <div id="signature">
                    <p>Prepared By: ${UserName}</p>
                    <p>Date: ${new Date().toLocaleDateString()}</p>
                    <p>Signature: _____________</p>
                </div>
                <div id="footer">
                    <p>Wedaj Logistic Management system by Wedaj Engineering 0910873030 | ወዳጅ ሎጂስቲክስ ማኔጅመንት ሲስተም በ ወዳጅ ኢንጅነሪንግ 0910873030</p>
                </div>
            </div>
        </body>
        </html>
        `;
    
        // Use WebView to open a new window with the printable content
        if (Platform.OS === 'web') {
            // Create an iframe to load the HTML content
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);
    
            // Set the HTML content in the iframe
            iframe.contentDocument.open();
            iframe.contentDocument.write(htmlContent);
            iframe.contentDocument.close();
    
            // Callback function to notify the main window when the print dialog is closed
            const onPrintDialogClose = () => {
                document.body.removeChild(iframe);
                window.removeEventListener('afterprint', onPrintDialogClose);
            };
    
            // Listen for the 'afterprint' event, which is triggered when the print dialog is closed
            window.addEventListener('afterprint', onPrintDialogClose);
    
            // Trigger the print dialog
            iframe.contentWindow.print();
        } else {
            // For mobile platforms, you can use Expo Print API
            // Note: This might not work as expected on the web
            await WebView.printAsync({ html: htmlContent });
        }
    };
 
    
    //---------------------------------------APPOINTMENTS END---------------------------------------------
    const renderItem = ({ item, index }) => {
        const calculateBackgroundColor = (index) => {
            const isEven = index % 2 === 0;
            const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
            return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
        };
        return (
            <View style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }}>
                <Text style={Styles.tableCell}>{item.origin == 'Addis Ababa' ? 'A.A' : item.origin}</Text>
                <Text style={Styles.tableCell}>{item.destination == 'Addis Ababa' ? 'A.A' : item.destination}</Text>
                <Text style={Styles.tableCell}>{item.cus_name}</Text>
                <Text style={Styles.tableCell}>{item.idfreightOrder}</Text>
                <Text style={Styles.tableDateCell}>{item.orderDeparture ? <FormattedDate dateString={item.orderDeparture} /> : null}</Text>
                <Text style={Styles.tableDateCell}>{item.orderUnloaingComplete ? <FormattedDate dateString={item.orderUnloaingComplete} /> : null}</Text>
                <Text style={Styles.tableDateCell}>{item.orderWeight} x {<MoneyFormat amount={item.orderTariff} />}</Text>
                <Text style={Styles.tableCell}>{item.orderStatus}</Text>
            </View>
        )
    };

    return (
        <View style={Styles.mainContainer}>
            <AddFOForm isVisible={isFormVisible} onClose={handleCloseForm} onSubmit={AddNewFO} />
            <EditFOForm isVisible={isEditFormVisible} onClose={handleCloseForm} onSubmit={UpdateFO} />
            <View style={Styles.Leftview}>
                <View style={Styles.headerContainer}>
                    {/* <TouchableOpacity onPress={handleAddFOForm}>
                        {addingFO ? <ActivityIndicator size="small" color={colors.primary} /> : <Text style={Styles.headeText}>Add Order</Text>}
                    </TouchableOpacity> */}
                    {/* <TouchableOpacity style={Styles.headerRight} onPress={() => navigation.navigate('Add')}>
                    <MaterialCommunityIcons name="magnify" size={26} color="black"
                        onPress={() => {
                            navigation.navigate('Add')
                        }}
                    />
                    <Text style={Styles.headeText}>Search</Text>
                </TouchableOpacity> */}
                </View>
                <View style={Styles.searchBarContainer}>
                    <TextInput
                        ref={searchInputRef}
                        style={Styles.AppTextInput}
                        onChangeText={newinput => searchFO(newinput)}
                        keyboardType="default"
                        placeholderTextColor={colors.Grey}
                        placeholder={'Order, Plate, Trailer, Cosignee'}
                    >
                    </TextInput>
                    <Button title='Clear' onPress={() => { searchInputRef.current.clear(), getFO() }} />
                </View>
                {!refreshing ?
                    <>
                        {fOrders.length > 0 ?
                            <>
                                <View style={Styles.FOContainer} >
                                    <FlatList data={fOrders}
                                        keyExtractor={(item) => item.idfreightOrder}
                                        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={getFO} />}
                                        renderItem={({ item }) => (
                                            <View>
                                                <FOLister
                                                    // id={item.acc_id}
                                                    freightOrderNo={item.idfreightOrder}
                                                    // topRightIcon={<Feather name="edit" size={20} color="black" />}
                                                    // topRightIconButton={() => handleEditForm(item)}
                                                    orderContainer={item.orderContainer}
                                                    commodity={item.orderCommodity}
                                                    employee_name={item.user_id + " " + item.user_name}
                                                    orderDeparture={<FormattedDate dateString={item.orderDeparture} />}
                                                    orderDeliveryAgreementDate={<FormattedDate dateString={item.orderDeliveryAgreementDate} />}
                                                    originPlace={item.origin}
                                                    destinationPlace={item.destination}
                                                    orderStatus={item.orderStatus}
                                                    truck_plate={gettingTruckHistory ? <ActivityIndicator size="small" color={colors.primary} /> : item.truck_plate}
                                                    onFOpress={() => { getFODetail(item.idfreightOrder) }}
                                                    onPlatePress={() => { setSelectedScreen('truckTable'), getTruckFreightHistory(item.truck_plate) }}
                                                    // trailer_plate={item.trailer_plate}
                                                    driver_name={item.driver_name}
                                                    middleName={item.middleName}
                                                    phone_addis={item.phone_addis}
                                                    phone_djibouti={item.phone_djibouti}
                                                    //orderWeight={item.orderWeight}
                                                    orderTariff={item.orderWeight + "x" + item.orderTariff}
                                                    orderTotal={<MoneyFormat amount={item.orderTotal} />}
                                                    paymentForTruck={item.outgoing_payment_made ? <MoneyFormat amount={item.outgoing_payment_made} /> : "-"}
                                                    paymentForCompany={item.incoming_payment_made ? item.paymentForCompany : "-"}
                                                    orderTotalTitle={item.incoming_payment_made != 0.00 ? 'Payment Received' : 'Expected Pay'}
                                                    orderPay={item.incoming_payment_made ? <MoneyFormat amount={item.incoming_payment_made} /> : <MoneyFormat amount={item.orderTotal} />}
                                                    // incoming_payment_made={<MoneyFormat amount={item.incoming_payment_made} />}
                                                    orderGenerator={item.orderGenerator}
                                                    cus_name={item.cus_name}
                                                    cus_phone={item.cus_phone}
                                                    id_pen={item.id_pen}
                                                    pen_from={item.pen_from}
                                                    pen_reason={item.pen_reason}
                                                    pen_ErrorFoundedFO={item.Pen_ErrorFoundedFO}
                                                    pen_amount={<MoneyFormat amount={item.pen_amount} />}
                                                    remark={item.orderRemark}
                                                    // qelem={item.pen_fo == null && item.pen_truck_id == item.truck_plate ? colors.red : (item.id_pen != null && item.pen_fo != null ? colors.orange : colors.tertiary)}
                                                    // qelem={!item.incoming_payment_made ? colors.stardust : ((item.incoming_payment_made + (item.orderTotal * 0.02)) < item.orderTotal && !item.id_pen ? colors.red : colors.tertiary)}
                                                    qelem={item.pen_fo == item.idfreightOrder && item.pen_from == 'Truck' ? colors.red : null}
                                                //cus_email={item.cus_email}
                                                // phone={" | " + item.comp_address}

                                                // subtext2={"| " + item.comp_phone}
                                                // interest={" " + removeFirstCharacter(item.comp_tin)}
                                                // subtext3={"| " + item.comp_status}
                                                // changeText={(quan) => update_sale(quan, item.id_item)}
                                                // actionName1={
                                                //     // <AntDesign name="edit" size={17} color={colors.secondary} />
                                                //     // <Ionicons name="ios-add" size={20} color={colors.secondary} />
                                                // }
                                                // ButtonPress1={() => setSelectedItem(item)} // console.log(item) 
                                                //ButtonPress1={()=>{console.log(item)} } ()=>setIssue(issue => [item,...issue]){()=>{getItemsList(item)}}
                                                />
                                            </View>
                                        )}
                                    />
                                </View>

                            </>
                            :
                            <View style={Styles.NoFOContainer}>
                                <Text>No Freight Order Available.</Text>
                                <Text>If this is a mistake hit clear to try again.</Text>
                            </View>

                        }
                    </>
                    :
                    <View style={Styles.FOContainer}>
                        <ActivityIndicator size="small" color={colors.primary} />
                    </View>
                }
            </View>
            <View style={Styles.rightView}>
                {/* <MapComponent /> */}
                {/* <View style={Styles.smallMenuContainer}>
                    <TouchableOpacity onPress={() => setSelectedScreen('Expense')} style={[Styles.smallMenuButton, selectedScreen === 'Expense' && Styles.selectedsmallMenuButton]}> <Text style={Styles.smallMenuText}>Cost</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => setSelectedScreen('Invoice')} style={[Styles.smallMenuButton, selectedScreen === 'Invoice' && Styles.selectedsmallMenuButton]}> <Text style={Styles.smallMenuText}>Invoice</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => setSelectedScreen('Income')} style={[Styles.smallMenuButton, selectedScreen === 'Income' && Styles.selectedsmallMenuButton]}> <Text style={Styles.smallMenuText}>Income</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => setSelectedScreen('Appointment')} style={[Styles.smallMenuButton, selectedScreen === 'Appointment' && Styles.selectedsmallMenuButton]}> <Text style={Styles.smallMenuText}>Appointment</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => setSelectedScreen('Penality')} style={[Styles.smallMenuButton, selectedScreen === 'Penality' && Styles.selectedsmallMenuButton]}> <Text style={Styles.smallMenuText}>Penality</Text></TouchableOpacity>
                    <TouchableOpacity onPress={() => setSelectedScreen('Loan')} style={[Styles.smallMenuButton, selectedScreen === 'Loan' && Styles.selectedsmallMenuButton]}> <Text style={Styles.smallMenuText}>Advance</Text></TouchableOpacity>
                </View> */}
                {renderScreenContent()}
            </View>
        </View>
    );
}

const Styles = StyleSheet.create({
    smallButtonContainer: {
        //width: 100,
        marginLeft: 2,
        //alignSelf: 'flex-end',
        //justifyContent: 'space-evenly',
        //flexDirection: 'row',
        marginTop: 2,
        paddingHorizontal: 3,
        backgroundColor: '#2196F3',
        height: 28,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    searchContainer: {
        width: '12%',
        flexDirection: "Row",
        height: 30,
        marginBottom: 2
    },
    smallMenuContainer: {
        elevation: 8,
        backgroundColor: '#031D22',
        height: '7%',
        width: '100%',
        borderRadius: 5,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    smallMenuButton: {
        //backgroundColor: 'green',
        paddingHorizontal: 9,
        paddingVertical: 6,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    selectedsmallMenuButton: {
        //backgroundColor: 'grey',
        backgroundColor: '#09414c',
        paddingHorizontal: 9,
        paddingVertical: 6,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    smallMenuText: {
        fontSize: 16,
        fontWeight: 600,
        color: colors.offwhite,
    },
    truckDetailContainer: {
        height: '98%',
        width: '100%',
        margin: 2,
        elevation: 8,
        borderRadius: 5,
        backgroundColor: colors.AppCanvasMain,
    },
    truckDetailHeaderContainerMain:{
        //marginTop: 4,
        height: '7%',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    appointmentHeaderContainer: {
        //marginTop: 4,
        height: '23%',
        width: '99.7%',
        flexDirection: 'column',
        //alignItems: 'flex-start',
        backgroundColor: colors.AppCanvasMain,
        elevation: 9,
        borderRadius: 5,
        justifyContent: 'center',
        paddingLeft: 5,
        marginBottom: 15,
        //paddingTop: 5,
        borderColor: colors.skyblue,
        borderWidth: 1
    },
    searchAppointmentContainer: {
        height: '10%',
        width: '99.7%',
        flexDirection: 'column',
        //alignItems: 'flex-start',
        backgroundColor: colors.AppCanvasMain,
        elevation: 9,
        borderRadius: 5,
        justifyContent: 'center',
        paddingLeft: 5,
        marginBottom: 15,
        //paddingTop: 5,
        borderColor: colors.skyblue,
        borderWidth: 1
    },
    truckDetailHeaderContainer: {
        //marginTop: 4,
        height: '10%',
        width: '99.7%',
        flexDirection: 'column',
        // alignItems: 'flex-start',
        backgroundColor: colors.AppCanvasMain,
        elevation: 9,
        borderRadius: 5,
        justifyContent: 'center',
        paddingLeft: 5,
        marginBottom: 15,
        //paddingTop: 5,
        borderColor: colors.skyblue,
        borderWidth: 1
    },
    modalTitleContainer: {
        // backgroundColor: 'orange',
    },
    twoColContainer: {
        flex: 1,
        flexDirection: 'column',
        // backgroundColor: colors.skyblue,
        marginBottom: 5,
        // ginRight: 3,
        width: '99.5%',
        borderRadius: 5,
        elevation: 6,
    },
    firstRow: {
        // flex: 1,
        flexDirection: 'row',
        width: '57%',
        justifyContent: 'flex-start'
    },
    secondRow: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
    },
    thirdRow: {
        flex: 1,
        flexDirection: 'row',
        width: '100%',
    },
    checkboxContainer: {
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    checkbox: {
        margin: 2,
        marginRight: 3
    },
    checkBoxTitle: {
        fontSize: 12,
    },
    documentsOut: {
        flex: 1,
        flexDirection: 'row',
        height: '100%',
        width: '100%',
    },
    truckDetailTable: {
        // height: '40%',
        width: '100%',
        // backgroundColor: 'green',
        borderWidth: 1,
        marginLeft: 4,
        borderColor: 'skyblue',
    },
    tableRow: {
        flex: 1,
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'skyblue',
        paddingVertical: 3,
        marginHorizontal: 5,
        backgroundColor: '#f0f0f0', 
        // Header background color,
        // alignItems: 'center',
        // justifyContent: 'center'
    },
    tableCellHeaderLarge: {
        flex: .4,
        // padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCellHeader: {
        flex: .3,
        // padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCellHeaderSmall: {
        flex: .2,
        // padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableDateCellHeader: {
        flex: .5,
        padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCellLarge: {
        flex: .4,
        // marginLeft: 3,
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCell: {
        flex: .3,
        // marginLeft: 3,
        alignItems: 'center',
        justifyContent: 'center',
        // marginHorizontal: 3,
    },

    tableDateCell: {
        flex: .5,
        // marginLeft: 3,
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCellIcon: {
        flex: .2,
        // paddingLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop: 5,
    },
    formContainer: {
        flexDirection: 'row',
        //alignItems: 'center'
        marginTop: 5,
        marginBottom: 5
    },
    input: {
        height: 35,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        width: 160
    },
    searchInput: {
        height: 30,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        width: 160
    },
    datepickerStyle: {
        // backgroundColor: 'grey',
        //  width: 50
        // height: 30
    },
    buttonContainer: {
        // width: 100,
        marginLeft: 3,
        // alignSelf: 'flex-end',
        // justifyContent: 'space-evenly',
        // flexDirection: 'row',
        marginTop: 2,
        paddingHorizontal: 5,
        backgroundColor: '#2196F3',
        height: 33,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    buttonText: {
        color: 'white',
        fontWeight: 600,
        paddingHorizontal: 3
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.AppCanvasMain
    },
    list: {
        width: '100%',
    },
    contactItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'gray',
    },
    contactName: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    mainContainer: {
        flex: 1,
        width: '100%',
        // alignItems: 'center',
        // justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: colors.AppCanvasMain,
        height: SCREEN_HEIGHT,
    },
    headerContainer: {
        flexDirection: 'row',
        // alignItems: ''
        justifyContent: 'space-between',
        width: '100%',
        //  backgroundColor: colors.offwhite,
        paddingHorizontal: 12,
        backgroundColor: colors.AppCanvasMain,
        //paddingVertical: 10,
    },
    headerRight: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginRight: 
    },
    headeText: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    searchBarContainer: {
        width: "100%",
        height: 40,
        flexDirection: "row",
        alignContent: "space-between",
        // justifyContent: "center",
        backgroundColor: colors.AppCanvasMain
    },
    FOContainer: {
        width: "100%",
        // height: 350,
        flex: 1,
        flexDirection: "row",
        // alignContent: "space-between",
        justifyContent: "center",

        // alignItems: 'center',
        // backgroundColor: 'orange',
        paddingTop: 2,
        backgroundColor: colors.AppCanvasMain,
        // height: SCREEN_HEIGHT,
    },
    Leftview: {
        width: '27%',
        flexDirection: 'column',
        height: (SCREEN_HEIGHT - 5),
    },
    rightView: {
        backgroundColor: colors.AppCanvasMain,
        width: '73%',
        height: SCREEN_HEIGHT,
    },
    NoFOContainer: {
        width: "100%",
        // height: 350,
        flex: 1,
        flexDirection: "row",
        // alignContent: "space-between",
        justifyContent: "center",

        alignItems: 'center',
        // backgroundColor: 'orange',
        paddingTop: 5,
        flexDirection: 'column',
        backgroundColor: colors.AppCanvasMain
    },
    AppTextInput: {
        width: "80%",
        height: 40,
        borderRadius: 5,
        borderColor: colors.primary,
        borderWidth: 0.5,
        paddingLeft: 10,
        marginLeft: 5,
        //position: "relative",
        top: 0,
        //alignItems: "center",
        fontSize: 14,
        color: colors.primary,
    },
});