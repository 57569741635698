import React, { useState, useRef, useContext, useEffect } from 'react';
import { View, FlatList, TouchableOpacity, Text, Picker, StyleSheet, TextInput, RefreshControl, ActivityIndicator, Button, Dimensions, Alert, Window, Platform } from 'react-native';
// import AppCanvas from '../config/AppCanvas';
// import Buttons from "../components/Buttons";
import FormattedDate from '../components/FormattedDate';
import MoneyFormat from '../components/MoneyFormat';
import * as Print from 'expo-print';
// import { shareAsync } from 'expo-sharing';
// import { Print } from 'expo';
import FOLister from '../components/FOLister';
import colors from '../config/colors';
import { Feather } from '@expo/vector-icons';
import AuthContext from '../auth/context';
import services from '../api/services';
import AddFOForm from './AddFOForm';
// import MapComponent from '../components/MapComponent';
import EditFOForm from './EditFOForm'
import HomeScreen from './HomeScreen'; 
import { AntDesign } from '@expo/vector-icons';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import PrintableComponent from '../components/PrintableComponent'
import "rsuite/dist/rsuite.min.css";
import { DatePicker } from "rsuite";
import * as XLSX from 'xlsx';
import FormattedDateNoHR from '../components/FormattedDateNoHR';
const SCREEN_HEIGHT = Dimensions.get('window').height;

export default function Expense({ navigation }) {

    const { user, setUser, selectedCo_name, setSelectedCo_name, selectedCo_Tin, setSelectedCo_Tin } = useContext(AuthContext)

    const [fOrders, setFOrders] = useState('')
    const [refreshing, setRefreshing] = useState(true);

    const [freightOrdersPerTruck, setFreightOrdersPerTruck] = useState('')
    const [gettingTruckHistory, setGettingTruckHistory] = useState(false)
    const [selectedTruck, setSelectedTruck] = useState(false)

    const UserID = user.userId
    const compInUse = user.userCompName
    
    useEffect(() => {
        const timeoutId = setInterval(() => { }, 2000);
        getFO()
        getAppointmentsForExpense()
        getReport()
        const currentDate = new Date();
        const oneMonthFromNow = new Date();
        oneMonthFromNow.setMonth(currentDate.getMonth() + 1);
        setAppDate(oneMonthFromNow);
        return () => clearInterval(timeoutId);
    }, []);
    
    const coTin = user.userCompTin
    const getFO = async () => {
        try {
            const response = await services.getFOForView(coTin);
            if (response.status === 200) {
                setFOrders(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    const searchInputRef = useRef('');

    const searchFO = async (newinput) => {
        try {
            const response = await services.searchFOForView(coTin, newinput);
            if (response.status === 200) {
                setFOrders(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    }

    const [isFormVisible, setFormVisible] = useState(false);
    const [isEditFormVisible, setEditFormVisible] = useState(false);

    const handleAddFOForm = () => {
        setFormVisible(true);
    };
    const handleEditForm = () => {
        setEditFormVisible(true)
    }
    const handleCloseForm = () => {
        setFormVisible(false);
        setFO('');
        setTruck('');
        setgrossWeight('');
        setOrderDeliveryDate(null);
        setAppDate(null);
        setOrigin('Djibouti');
        setDestination('Addis Abeba');
        setTariff('');
        setConsignee('');
        setOrderGenerator('Company')
        setRemark('');
    }

    const AddNewFO = async (formData) => {
        try {
            setaddingExpense(true)
            const response = await services.postFO(formData.remark, formData.startDate, formData.orderDeliveryAgreementDate, formData.consignee, formData.fo, formData.truck, formData.grossWeight, formData.tariff, formData.origin, formData.destination, formData.orderGenerator, coTin, UserID);
            if (response.status === 200) {
                getFO()
                setaddingExpense(false);
                window.alert("Freight Order Posted.")
            } else {
                setaddingExpense(false);
                window.alert("Error posting Freight Order. Please Try again.")
                console.error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            setaddingExpense(false);
        }
        setFormVisible(false);
    };

    const UpdateFO = (item) => {
        // navigation.navigate('Edit', { item });

    };

    const getFODetail = (item) => {

    }

    const getTruckFreightHistory = async (item) => {
        setGettingTruckHistory(true)
        setSelectedTruck(item)
        try {
            const response = await services.getFOForTruckHistory(coTin, item);
            if (response.status === 200) {
                setFreightOrdersPerTruck(response.data);
                setGettingTruckHistory(false);

            } else {
                console.error(`Error: ${response.status}`);
                setGettingTruckHistory(false);
            }
        } catch (error) {
            console.error(error);
            setGettingTruckHistory(false);
        }
    };

    const renderHeader = () => (
        <View style={Styles.tableRow}>
            <Text style={Styles.tableCellHeader}>From</Text>
            <Text style={Styles.tableCellHeader}>To</Text>
            <Text style={Styles.tableCellHeader}>Owner</Text>
            <Text style={Styles.tableCellHeader}>Freight No.</Text>
            <Text style={Styles.tableDateCellHeader}>Departure</Text>
            <Text style={Styles.tableDateCellHeader}>Arrival</Text>
            {/* <Text style={Styles.tableCellHeader}>Truck</Text> */}
            {/* <Text style={Styles.tableCellHeader}>Trailer</Text> */}
            <Text style={Styles.tableCellHeader}>Tariff</Text>
            {/* <Text style={Styles.tableCellHeader}>Weight</Text> */}
            {/* <Text style={Styles.tableCellHeader}>Penality</Text>
            <Text style={Styles.tableCellHeader}>Reason</Text> */}
            <Text style={Styles.tableCellHeader}>Status</Text>
        </View>
    );
    const [selectedScreen, setSelectedScreen] = useState(null);

    const renderScreenContent = () => {
        switch (selectedScreen) {
            case 'truckTable':
                return renderTruckTable();
            case 'Appointment':
                return renderAppointment();
            case 'Loan':
                return renderLoan();
            case 'Penality':
                return renderPenality();
            case 'Income':
                return renderIncome();
            case 'Invoice':
                return renderInvoive();
            case 'Expense':
                return renderExpense();
            default:
                return renderAppointment();
            // return <HomeScreen />;
        }
    };
    const renderTruckTable = () => (
        <View style={Styles.truckDetailContainer}>
            <View style={Styles.truckDetailHeaderContainer}> {/* title container */}
                <Text style={Styles.modalTitle}>{selectedTruck ? <View style={Styles.loadedModalTitle}>Deployment History For Truck {selectedTruck}</View> : <>Deployment History</>}</Text>
                {/* <View style={Styles.twoColContainer}>
                            <View style={Styles.truckDetailLeft}>

                            </View>
                            <View style={Styles.truckDetailRight}>

                            </View>
                        </View> */}
            </View>
            <View style={Styles.tableHeader}>
                {renderHeader()}
            </View>
            <FlatList
                data={freightOrdersPerTruck}
                keyExtractor={(item) => item.idfreightOrder}
                renderItem={renderItem}
            />
        </View>
    );
    const renderExpense = () => (UserID == 1 ? <View><Text>Expense</Text></View> : <View><Text>Unauthorized</Text></View>)
    const renderInvoive = () => (<View><Text>Invoice</Text></View>)
    const renderIncome = () => (<View><Text>Income</Text></View>)
    const renderPenality = () => (<View><Text>Penality</Text></View>)
    const renderLoan = () => (<View><Text>Loan</Text></View>)

    // const printableRef = useRef();



    //---------------------------------------APPOINTMENTS---------------------------------------------

    const [appDate, setAppDate] = useState(null);
    const foInputRef = useRef('');
    const amountInputRef = useRef('');
    const voucherInputRef = useRef('');
    const reasonInputRef = useRef('');
    const AppoSearchInputRef = useRef('');
    const ExpenseSearchInputRef = useRef('');

    const [appointments, setAppointments] = useState('')
    const [deletingPayment, setdeletingPayment] = useState(false)
    const [paidExpenses, setPaidExpenses] = useState('')

    const [searchingCustomer, setSearchingCustomer] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const getAppointmentsForExpense = async () => {
        try {
            const response = await services.getAppointmentsForExpense(coTin);
            if (response.status === 200) {
                setAppointments(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    const getReport = async () => {
        try {
            const response = await services.getCombinedView(coTin);
            if (response.status === 200) {
                setPaidExpenses(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };
    // const renderAppointment = () => (

    // );
    const listExpenses = ({ item, index }) => {

        const calculateBackgroundColor = (index) => {
            const isEven = index % 2 === 0;
            const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
            return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
        };
        return (<>
            {/* <PrintableComponent ref={printableRef} item={item} /> */}
            <View style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }}>
                <Text style={Styles.tableCellIcon}> {index+1}</Text>
                <Text style={Styles.tableCell}>{item.op_added_date ? <FormattedDateNoHR dateString={item.op_added_date}/> : '-'}</Text>
                <Text style={Styles.tableCell}>{item.op_Paid_To ? item.op_Paid_To : '-'}</Text>
                <Text style={Styles.tableCell}>{item.op_fo ? item.op_fo : '-'}</Text>
                <Text style={Styles.tableCell}>{item.truck_plate ? item.truck_plate : '-'}</Text>
                <Text style={Styles.tableCell}>{item.op_voucher ? item.op_voucher : '-'}</Text>
                <Text style={Styles.tableCell}>{item.op_transportCost ? <MoneyFormat amount={item.op_transportCost} />  : '-'}</Text>
                <Text style={Styles.tableCell}>{item.com_amount ? <MoneyFormat amount={item.com_amount} /> : '-'}</Text>
                <Text style={Styles.tableCell}>{item.pen_amount ? <MoneyFormat amount={item.pen_amount} /> : '-'}</Text>
                <Text style={Styles.tableCell}>{item.pen_amount && item.com_amount ? <MoneyFormat amount={item.pen_amount + item.com_amount} /> : <MoneyFormat amount={item.com_amount} />}</Text>
                <Text style={Styles.tableCell}>{item.outgoing_payment_made ? <MoneyFormat amount={item.outgoing_payment_made} /> : '-'}</Text>
                {/* <Text style={Styles.tableCell}>{item.origin == 'Addis Ababa' ? 'A.A' : item.origin}</Text>
                <Text style={Styles.tableCell}>{item.destination == 'Addis Ababa' ? 'A.A' : item.destination}</Text> */}
                {/* <Text style={Styles.tableCell}>{item.cus_name}</Text> */}
                {/* <Text style={Styles.tableCell}>{item.op_reason}</Text> */}
                {/* <Text style={Styles.tableCell}>{item.op_added_date ? <FormattedDate dateString={item.op_added_date} /> : null}</Text> */}
                {/* <Text style={Styles.tableCell}>{item.orderStatus}</Text> */}
                <View style={Styles.tableCellIcon}>{deletingPayment ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="delete" size={14} color="red" onPress={() => deletePayment(item.id_op,item.id_com,item.op_voucher,item.op_fo)} />}</View>
                {/* <View style={Styles.tableCellIcon}>{deletingApp ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="printer" size={14} color={colors.primary} onPress={() => printToFile(item)} />}</View> */}
            </View>
        </>)
    };
    const listAppointments = ({ item, index }) => {

        const calculateBackgroundColor = (index) => {
            const isEven = index % 2 === 0;
            const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
            return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
        };
        return (<>
            {/* <PrintableComponent ref={printableRef} item={item} /> */}
            <View style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }}>
                <Text style={Styles.tableCell}>{item.idfreightOrder}</Text>
                <Text style={Styles.tableCell}>{item.id_app ? item.id_app : null}</Text>
                <Text style={Styles.tableCell}>{item.truck_plate}</Text>
                <Text style={Styles.tableCell}>{item.cus_name}</Text>
                {/* <Text style={Styles.tableCell}>{item.orderDeparture ? <FormattedDate dateString={item.orderDeparture} /> : null}</Text>
                <Text style={Styles.tableCell}>{item.orderUnloaingComplete ? <FormattedDate dateString={item.orderUnloaingComplete} /> : null}</Text> */}
                <Text style={Styles.tableCell}>{item.orderPayAppointment ? <FormattedDate dateString={item.orderPayAppointment} /> : null}</Text>
                {/* <Text style={Styles.tableCell}>{item.orderStatus}</Text> */}
                {/* <Text style={Styles.tableCell}>{item.orderStatus == 'Completed' ? <AntDesign name="checkcircle" size={14} color={colors.green} onPress={() => printToFile(item)} /> : item.orderStatus}</Text> */}
                {/* <View style={Styles.tableCellIcon}>{deletingApp ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="delete" size={14} color="red" onPress={() => deleteAppointment(item.idfreightOrder)} />}</View> */}
                {/* <View style={Styles.tableCellIcon}>{deletingApp ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="printer" size={14} color={colors.primary} onPress={() => printToFile(item)} />}</View> */}
            </View>
        </>)
    };
    const clearForm = () => {
        foInputRef.current.clear()
        amountInputRef.current.clear()
        voucherInputRef.current.clear()
        reasonInputRef.current.clear()
        ExpenseSearchInputRef.current.clear()
        setFromDate(null)
        setToDate(null)
    }
    const clearAppSearch = () => {
        AppoSearchInputRef.current.clear()
    }
    const clearExpenseSearch = () => {
        ExpenseSearchInputRef.current.clear()
    }
    const submitExpense = async () => {
        try {
            setaddingExpense(true)
                setaddingExpense(true)
                const response = await services.postCostandCommissionWithCompleteStatus(fo, name, transportCost,amount, commission, voucher, reason, coTin, UserID);
                if (response.status === 200) {
                    clearForm()
                    setaddingExpense(false);
                    getReport()
                    getAppointmentsForExpense()
                    //getFO()
                    window.alert("Expense Posted.")
                } else {
                    setaddingExpense(false);
                    window.alert("Error posting Expense. Please try again.")
                    console.error(`Error: ${response.status}`);
                }
        } catch (error) {
            console.error(error);
            setaddingExpense(false);
            window.alert("Error posting Expense. Please try again.")
        }
    };
    const deletePayment = async (id_op,id_com,op_voucher,op_fo) => {
        try {
            const confirmed = window.confirm(`Are you sure you want to delete ${op_voucher ?`Invoice: `+op_voucher:`Freight Order: `+op_fo} payment?`);
            if (!confirmed) {
                return;
            }
            setdeletingPayment(true);
            const response = await services.deleteExpenseAndCommission(op_fo,id_op, id_com, coTin, UserID);
            if (response.status === 200) {
                getReport();
                window.alert("Payment deleted.");
                setdeletingPayment(false);
            } else {
                setdeletingPayment(false);
                window.alert("Error deleting payment. Please try again.");
                console.error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            setdeletingPayment(false);
        }
    };

    const printToFile = async (item) => {
        const htmlContent = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Payment Appointment</title>
          <style>
            body {
              font-family: Arial, sans-serif;
              margin: 10px;
            }
            #header {
              text-align: center;
              padding: 10px;
              background-color: #f0f0f0;
            }
            #headerText {
              font-size: 18px;
              font-weight: bold;
            }
            #appointmentDetails {
              margin-top: 20px;
              border: 1px solid #ddd;
              padding: 10px;
            }
            #signature {
              margin-top: 20px;
            }
            #date {
              margin-top: 10px;
            }
          </style>
        </head>
        <body>
          <div id="header">
            <p id="headerText">Unity Transport and Logistics SC</p>
          </div>
          <div id="appointmentDetails">
            <p>Truck: ${item.truck_plate}</p>
            <p>Freight ID: ${item.idfreightOrder}</p>
            <p>From: ${item.origin}</p>
            <p>To: ${item.destination}</p>
            <!-- <p>Payment Amount: - </p> -->
             <p>Payment Date: ${item.orderPayAppointment}</p>
            <!-- Add other appointment details as needed -->
          </div>
          <div id="signature">
            <p>Prepared By: ${UserID}</p>
            <p>Signature: ____________________________</p>
          </div>
          <div id="date">
            <p>Date: ${new Date().toLocaleDateString()}</p>
          </div>
        </body>
        </html>
      `;


        // Use WebView to open a new window with the printable content
        if (Platform.OS === 'web') {
            // Create an iframe to load the HTML content
            const iframe = document.createElement('iframe');
            iframe.style.display = 'none';
            document.body.appendChild(iframe);

            // Set the HTML content in the iframe
            iframe.contentDocument.open();
            iframe.contentDocument.write(htmlContent);
            iframe.contentDocument.close();

            // Callback function to notify the main window when the print dialog is closed
            const onPrintDialogClose = () => {
                document.body.removeChild(iframe);
                window.removeEventListener('afterprint', onPrintDialogClose);
            };

            // Listen for the 'afterprint' event, which is triggered when the print dialog is closed
            window.addEventListener('afterprint', onPrintDialogClose);

            // Trigger the print dialog
            iframe.contentWindow.print();
        } else {
            // For mobile platforms, you can use Expo Print API
            // Note: This might not work as expected on the web
            await WebView.printAsync({ html: htmlContent });
        }
    };
    //---------------------------------------APPOINTMENTS END---------------------------------------------

    const [fo, setFO] = useState('')
    const [name, setName] = useState('')
    const [transportCost, setTransportCost] = useState('')
    const [commission, setCommission] = useState('')
    const [amount, setAmount] = useState('')
    const [voucher, setVoucher] = useState('')
    const [reason, setReason] = useState('Delivery')

    const [addingExpense, setaddingExpense] = useState(false)

    const searchExpenses = async (input) => {
        try {
            if (!input || input.trim() === '') {
                return;
            }
            const response = await services.searchExpensesPosted(coTin, input);
            if (response.status === 200) {
                setPaidExpenses(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };
    const searchAppointment = async (input) => {
        try {
            if (!input || input.trim() === '') {
                return;
            }
            const response = await services.searchAppointment(coTin, input);
            if (response.status === 200) {
                setAppointments(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };
    const [searchCost, setsearchCost] = useState(false)
    const [isDatePickerVisible, setDatePickerVisible] = useState(false);
    const [downloadingInvoice, setDownloadingInvoice] = useState(false)

    const showDatePicker = () => {
        setDatePickerVisible(true);
    };

    const hideDatePicker = () => {
        setDatePickerVisible(false);
    };

    const handleConfirm = (date) => {
        setOrderDeliveryDate(date);
        hideDatePicker();
    };

    const findFOForPaidup = async () => {
        setsearchCost(true)
        try {
            if (fromDate && toDate) {
                if (searchingCustomer && fromDate && toDate) {
                    const response = await services.searchExpensePostedWithDate(coTin, searchingCustomer, fromDate, toDate);
                    if (response.status === 200) {
                        setPaidExpenses(response.data);
                        setsearchCost(false);
                    } else {
                        console.error(`Error: ${response.status}`);
                        setsearchCost(false);
                    }
                }
                else if(fromDate && toDate && !searchingCustomer) {
                    const response = await services.searchExpensePostedWithJustDate(coTin, fromDate, toDate);
                    if (response.status === 200) {
                        setPaidExpenses(response.data);
                        setsearchCost(false);
                    } else {
                        console.error(`Error: ${response.status}`);
                        setsearchCost(false);
                    }
                }
                else{ window.alert("Please select date to search!"); setsearchCost(false); }
            } else { window.alert("Please select date to search."); setsearchCost(false); }
        } catch (error) {
            console.error(error);
            setsearchCost(false);
        }
    }
    
    const clearRangeForm = () => {
        getReport()
        setSearchingCustomer(null);
        setFromDate(null);
        setToDate(null);
    }

    const downloadPaidupCost = async () => {
        setDownloadingInvoice(true)
        const headers = [
            'No.',
            'Date',
            'Name',
            'Plate No.',
            'Invoice',
            'Total Transport Cost',
            'Total Service Charge',
            'Other Deduction',
            'Total Deduction',
            'Net Pay',
        ];

        // ,,id_op, id_com,id_pen, ,, ,, ,,
        const data = paidExpenses.map((item, index) => [
            index + 1,
            item.op_added_date,
            item.op_Paid_To,
            item.truck_plate,
            item.op_voucher,
            item.op_transportCost,
            item.com_amount,
            item.pen_amount,
            item.pen_amount + item.com_amount,
            item.outgoing_payment_made
        ]);

        // Create a worksheet with custom headers and data
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

        // Create a workbook
        const wb = XLSX.utils.book_new();

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, worksheet, 'Payment Summary');

        // Generate a Blob
        const wbout = XLSX.write(wb, {
            bookType: 'xlsx',
            type: 'array',
        });

        const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
        const givenFileName = prompt("What do you want to name the downloaded document?")

        if (givenFileName){
            const filename =`${givenFileName}.xlsx`;
            saveAs(blob, filename);
            setDownloadingInvoice(false)
        }else{
            const filename = 'Payment Summary.xlsx';
            saveAs(blob, filename);
            setDownloadingInvoice(false) 
        }

    };

    return (
        <View style={Styles.truckDetailContainer}>

            <View style={Styles.modalTitleContainer}><Text style={Styles.tableTitle}>  Appointments</Text></View>
            <View style={Styles.searchContainer}>
                <TextInput
                    ref={AppoSearchInputRef}
                    style={Styles.inputTableSearch}
                    placeholder="Search Appointment"
                    onChangeText={(input) => searchAppointment(input)}
                />
                <TouchableOpacity style={Styles.smallButtonContainer} onPress={() => { getAppointmentsForExpense(), clearAppSearch() }}>
                    <Text style={Styles.buttonText}>Clear</Text>
                </TouchableOpacity>
            </View>

            <View style={Styles.topTable}>
                <View style={Styles.tableHeader}>
                    <View style={Styles.tableRow}>
                        <Text style={Styles.tableCellHeader}>Freight</Text>
                        <Text style={Styles.tableCellHeader}>App ID</Text>
                        <Text style={Styles.tableCellHeader}>Truck</Text>
                        <Text style={Styles.tableCellHeader}>Owner</Text>
                        {/* <Text style={Styles.tableCellHeader}>Departure</Text>
                        <Text style={Styles.tableCellHeader}>Arrival</Text> */}
                        <Text style={Styles.tableCellHeader}>Pay App.</Text>
                        {/* <Text style={Styles.tableCellHeader}>Status</Text> */}
                        {/* <Text style={Styles.tableCellHeaderSmall}>Delete</Text> */}
                        {/* <Text style={Styles.tableCellHeaderSmall}>Print</Text> */}
                    </View>
                </View>
                <FlatList
                    data={appointments}
                    keyExtractor={(item) => item.id_op}
                    renderItem={listAppointments}
                />
            </View>
            <View style={Styles.truckDetailHeaderContainer}> {/* title container */}
                <View style={Styles.modalTitleContainer}><Text style={Styles.modalTitle}>New Cost</Text></View>
                <View style={Styles.formContainer}>
                    <TextInput
                        ref={foInputRef}
                        style={Styles.input}
                        placeholder="Freight order No."
                        onChangeText={setFO}
                    />
                    <TextInput
                        ref={foInputRef}
                        style={Styles.inputBig}
                        placeholder="Name"
                        onChangeText={setName}
                    />
                    <TextInput
                        ref={amountInputRef}
                        style={Styles.inputBig}
                        placeholder="Transport Cost"
                        onChangeText={setTransportCost}
                    />
                    <TextInput
                        ref={amountInputRef}
                        style={Styles.input}
                        placeholder="Commission"
                        onChangeText={setCommission}
                    />
                    <TextInput
                        ref={amountInputRef}
                        style={Styles.input}
                        placeholder="Pay out"
                        onChangeText={setAmount}
                    />
                    <TextInput
                        ref={voucherInputRef}
                        style={Styles.input}
                        placeholder="Invoice No."
                        onChangeText={setVoucher}
                    />
                    <TextInput
                        ref={reasonInputRef}
                        style={Styles.input}
                        placeholder="Remark"
                        onChangeText={setReason}
                    />
                    <TouchableOpacity style={Styles.buttonContainer} onPress={clearForm}>
                        <Text style={Styles.buttonText}>Clear</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={Styles.buttonContainer} onPress={submitExpense}>
                        {addingExpense ? <ActivityIndicator size="small" color={colors.white} /> : <Text style={Styles.buttonText}>Submit</Text>}
                    </TouchableOpacity>
                </View>
                {/* <View style={Styles.twoColContainer}>
                            <View style={Styles.truckDetailLeft}>

                            </View>
                            <View style={Styles.truckDetailRight}>

                            </View>
                        </View> */}
            </View>
            <View style={Styles.modalTitleContainer}><Text style={Styles.tableTitle}>  Paid up cost</Text></View>
            <View style={Styles.searchContainerWide}>
                <TextInput
                    ref={ExpenseSearchInputRef}
                    style={Styles.inputTableSearch}
                    placeholder="Search Paid Cost"
                    onChangeText={(input) => searchExpenses(input)}
                />
                        <Picker
                        origin={origin}
                        style={{ height: 29, width: 130, marginLeft: 10,marginRight:2, borderColor: 'skyblue', borderRadius: 5, fontSize: 14.2 }}
                        onValueChange={(itemValue, itemIndex) => setSearchingCustomer(itemValue)}
                    >
                        <Picker.Item label="All Customer" value="" />
                        <Picker.Item label="ATD" value="ATD" />
                        <Picker.Item label="Negade" value="Negade" />
                        <Picker.Item label="Maheber" value="Maheber" />
                    </Picker>
                            <DatePicker
                                style={Styles.datepickerStyle}
                                appearance="default"
                                size='sm'
                                showicon='true'
                                defaultValue={null}
                                value={fromDate}
                                onChange={(date) => setFromDate(date)}
                                // showTimeSelect
                                placeholder='From Date'
                                dateformat="Pp"
                                cleanable
                            />
                            <DatePicker
                                style={Styles.datepickerStyle}
                                appearance="default"
                                size='sm'
                                showicon='true'
                                defaultValue={null}
                                value={toDate}
                                onChange={(date) => setToDate(date)}
                                // showTimeSelect
                                placeholder='To Date'
                                cleanable
                                dateformat="Pp"
                            />
                            <TouchableOpacity style={Styles.smallButtonContainer} onPress={clearRangeForm}>
                                <Text style={Styles.buttonText}>Clear</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={Styles.smallButtonContainer} onPress={findFOForPaidup}>
                                {searchCost ? <ActivityIndicator size="small" color={colors.white} /> : <Text style={Styles.buttonText}>Search</Text>}
                            </TouchableOpacity>
                      
                            <TouchableOpacity style={Styles.smallButtonContainer} onPress={downloadPaidupCost}>
                                {downloadingInvoice ? <ActivityIndicator size="small" color={colors.white} /> : <><Text style={Styles.buttonText}><Feather name="download" size={14} color={colors.white} />  Download</Text></>}
                            </TouchableOpacity>
                           
                        {/* </View> */}
            </View>
 
            <View style={Styles.tableHeader}>
                <View style={Styles.tableRow}>
                    <Text style={Styles.tableCellHeaderSmall}>No.</Text>
                    <Text style={Styles.tableCellHeader}>Date</Text>
                    <Text style={Styles.tableCellHeader}>Name</Text>
                    <Text style={Styles.tableCellHeader}>Freight No</Text>
                    <Text style={Styles.tableCellHeader}>Plate No.</Text>
                    <Text style={Styles.tableCellHeader}>Invoice</Text>
                    <Text style={Styles.tableCellHeader}>Transport Cost</Text>
                    <Text style={Styles.tableCellHeader}>Service Charge</Text>
                    <Text style={Styles.tableCellHeader}>Other Deduction</Text>
                    <Text style={Styles.tableCellHeader}>Total Deduction</Text>
                    <Text style={Styles.tableCellHeader}>Net Pay</Text>
                    {/* <Text style={Styles.tableCellHeader}>Reason</Text> */}
                    {/* <Text style={Styles.tableCellHeader}>Pay Day</Text> */}
                    {/* <Text style={Styles.tableCellHeader}>Status</Text> */}
                    <Text style={Styles.tableCellHeaderSmall}>Delete</Text>
                    {/* <Text style={Styles.tableCellHeaderSmall}>Print</Text> */}
                </View>
            </View>
            <FlatList
                data={paidExpenses}
                keyExtractor={(item) => item.id_op}
                renderItem={listExpenses}
            />
        </View>
    );
}

const Styles = StyleSheet.create({
    smallMenuContainer: {
        elevation: 8,
        backgroundColor: '#031D22',
        height: '10%',
        width: '100%',
        borderRadius: 5,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    smallMenuButton: {
        // backgroundColor: 'green',
        paddingHorizontal: 9,
        paddingVertical: 6,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    selectedsmallMenuButton: {
        // backgroundColor: 'grey',
        backgroundColor: '#09414c',
        paddingHorizontal: 9,
        paddingVertical: 6,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    smallMenuText: {
        fontSize: 16,
        fontWeight: 600,
        color: colors.offwhite,
    },
    truckDetailContainer: {
        height: '92%',
        // flex:1,
        // flexDirection:'column',
        width: '100%',
        margin: 2,
        elevation: 8,
        borderRadius: 5,
        backgroundColor: colors.AppCanvasMain,
    },
    truckDetailHeaderContainer: {
        // marginTop: 4,
        height: '10%',
        width: '99.7%',
        flexDirection: 'column',
        // alignItems: 'flex-start',
        backgroundColor: colors.AppCanvasMain,
        elevation: 9,
        borderRadius: 5,
        justifyContent: 'center',
        paddingLeft: 5,
        // marginBottom: 5,
        // paddingTop: 5,
        borderColor: colors.skyblue,
        borderWidth: 1
    },
    modalTitleContainer: {
        // backgroundColor: 'orange',

    },
    twoColContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    truckDetailLeft: {
        height: '100%',
        width: '50%',
        // backgroundColor: 'ornage'
    },
    truckDetailRight: {
        height: '100%',
        width: '50%',
        backgroundColor: 'green'
    },
    topTable: {
        height: '25%',
        width: '100%',
    },
    tableHeader: {
        // height: '40%',
        width: '100%',
        // backgroundColor: 'green',
        borderWidth: 1,
        //marginLeft: 4,
        borderColor: 'skyblue',
    },
    searchContainer: {
        width: '12%',
        flexDirection: "Row",
        height: 30,
        marginBottom: 2
    },
    searchContainerWide: {
        width: '100%',
        flexDirection: "Row",
        height: 30,
        marginBottom: 4,
    },
    tableRow: {
        flex: 1,
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'skyblue',
        //paddingVertical: 3,
        marginHorizontal: 2,
        paddingVertical: 5,
        backgroundColor: '#f0f0f0', // Header background color,
        // alignItems: 'center',
        // justifyContent: 'center'
    },
    tableCellHeader: {
        // flex: .25,
        flex: 1,
        // padding: 2,
        fontWeight: 'bold',
       // alignItems: 'center',
        justifyContent: 'center',
        // marginHorizontal: 3,
    },
    tableCellHeaderSmall: {
        flex: .2,
        // padding: 2,
        fontWeight: 'bold',
        //alignItems: 'center',
        //justifyContent: 'center'
    },
    tableDateCellHeader: {
        flex: 1,
        padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCell: {
        // flex: .25,
        flex: 1,
        flexDirection:'row',
        // marginLeft: 3,
        alignItems: 'center',
        justifyContent: 'center',
        // marginHorizontal: 3,
    },
    tableDateCell: {
        flex: .5,
        // marginLeft: 3,
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCellIcon: {
        flex: .3,
        // paddingLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop: 5,
        marginLeft: 5,
    },
    tableTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop: 5,
        marginBottom: 2,
    },
    formContainer: {
        flexDirection: 'row',
        //alignItems: 'center'
        marginTop: 5,
        marginBottom: 5,
        paddingBottom: 5,
        paddingHorizontal: 5,
        marginBottom: 2,
    },
    input: {
        height: 35,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        width: 100
    },
    inputBig: {
        height: 35,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        width: 127
    },
    inputTableSearch: {
        height: 30,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        marginRight: 2,
        marginLeft: 2,
        paddingHorizontal: 5,
        marginBottom: 2,
        width: 160
    },
    datepickerStyle: {
        width: 140,
        height: 15,
        marginRight: 2
    },
    buttonContainer: {
        // width: 100,
        marginLeft: 3,
        // alignSelf: 'flex-end',
        // justifyContent: 'space-evenly',
        // flexDirection: 'row',
        marginTop: 2,
        paddingHorizontal: 5,
        backgroundColor: '#2196F3',
        height: 33,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    smallButtonContainer: {
        // width: 100,
        marginLeft: 2,
        // alignSelf: 'flex-end',
        // justifyContent: 'space-evenly',
        // flexDirection: 'row',
        marginTop: 2,
        paddingHorizontal: 3,
        backgroundColor: '#2196F3',
        height: 28,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    mainformContainer: {
        width: '100%',
        flexDirection: 'column',
        backgroundColor: colors.AppCanvasMain,
        elevation: 9,
        borderRadius: 5,
        justifyContent: 'center',
        paddingLeft: 2,
        borderColor: colors.skyblue,
        borderWidth: 1
    },
    buttonText: {
        color: 'white',
        fontWeight: 600,
        paddingHorizontal: 3,
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.AppCanvasMain
    },
    list: {
        width: '100%',
    },
    contactItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'gray',
    },
    contactName: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    mainContainer: {
        flex: 1,
        width: '100%',
        // alignItems: 'center',
        // justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: colors.AppCanvasMain,
        height: SCREEN_HEIGHT,
    },
    headerContainer: {
        flexDirection: 'row',
        // alignItems: ''
        justifyContent: 'space-between',
        width: '100%',
        //  backgroundColor: colors.offwhite,
        paddingHorizontal: 12,
        backgroundColor: colors.AppCanvasMain,
        //paddingVertical: 10,
    },
    headerRight: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginRight: 
    },
    headeText: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    searchBarContainer: {
        width: "100%",
        height: 40,
        flexDirection: "row",
        alignContent: "space-between",
        // justifyContent: "center",
        backgroundColor: colors.AppCanvasMain
    },
    FOContainer: {
        width: "100%",
        // height: 350,
        flex: 1,
        flexDirection: "row",
        // alignContent: "space-between",
        justifyContent: "center",

        // alignItems: 'center',
        // backgroundColor: 'orange',
        paddingTop: 2,
        backgroundColor: colors.AppCanvasMain,
        // height: SCREEN_HEIGHT,
    },
    Leftview: {
        width: '27%',
        flexDirection: 'column',
        height: (SCREEN_HEIGHT - 5),
    },
    rightView: {
        backgroundColor: colors.AppCanvasMain,
        width: '73%',
        height: SCREEN_HEIGHT,
    },
    NoFOContainer: {
        width: "100%",
        // height: 350,
        flex: 1,
        flexDirection: "row",
        // alignContent: "space-between",
        justifyContent: "center",

        alignItems: 'center',
        // backgroundColor: 'orange',
        paddingTop: 5,
        flexDirection: 'column',
        backgroundColor: colors.AppCanvasMain
    },
    AppTextInput: {
        width: "80%",
        height: 40,
        borderRadius: 5,
        borderColor: colors.primary,
        borderWidth: 0.5,
        paddingLeft: 10,
        marginLeft: 5,
        //position: "relative",
        top: 0,
        //alignItems: "center",
        fontSize: 14,
        color: colors.primary,
    },
});