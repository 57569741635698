import React from 'react';

function FormattedDateNoHR({ dateString }) {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        date.setHours(date.getHours() + 2);

        const currentYear = new Date().getFullYear();
        const isCurrentYear = date.getFullYear() === currentYear;

        const options = {
            month: 'short',
            day: 'numeric',
        };

        if (!isCurrentYear) {
            options.year = 'numeric';
        }

        const formatter = new Intl.DateTimeFormat('en-US', options);
        const formattedDate = formatter.format(date);

        // Replace comma with a space before the time
        return formattedDate.replace(', ', ' ');
    };

    const formattedDate = formatDate(dateString);

    return <span>{formattedDate}</span>;
}

export default FormattedDateNoHR;
