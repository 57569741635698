import React, { useState, useRef, useContext, useEffect } from 'react';
import { View, FlatList, TouchableOpacity, Text, Picker, StyleSheet, TextInput, RefreshControl, ActivityIndicator, Button, Dimensions, Alert, Window } from 'react-native';
// import AppCanvas from '../config/AppCanvas';
// import Buttons from "../components/Buttons";
import FormattedDate from '../components/FormattedDate';
import MoneyFormat from '../components/MoneyFormat';

import FOLister from '../components/FOLister';
import colors from '../config/colors';
import { Feather } from '@expo/vector-icons';
import AuthContext from '../auth/context';
import services from '../api/services';
import AddFOForm from './AddFOForm';
import MapComponent from '../components/MapComponent';
import EditFOForm from './EditFOForm'
// import { BannerAd, BannerAdSize } from 'react-native-google-mobile-ads';
import Dashboard from './Dashboard';
import "rsuite/dist/rsuite.min.css";
import { DatePicker } from "rsuite";
import * as XLSX from 'xlsx';
import FormattedDateNoHR from '../components/FormattedDateNoHR';
const SCREEN_HEIGHT = Dimensions.get('window').height;
// document.title = "WEDAJ LMS";
export default function Freight({ navigation }) {

    const { user, setUser, selectedCo_name, setSelectedCo_name, selectedCo_Tin, setSelectedCo_Tin } = useContext(AuthContext)

    const UserID = user.userId
    const coTin = user.userCompTin
    const compInUse = user.userCompName

    const [fOrders, setFOrders] = useState('')
    const [refreshing, setRefreshing] = useState(true);
    const [addingFO, setaddingNewFo] = useState(false)

    const [freightOrdersPerTruck, setFreightOrdersPerTruck] = useState('')
    const [gettingTruckHistory, setGettingTruckHistory] = useState(false)
    const [selectedTruck, setSelectedTruck] = useState(false)


    useEffect(() => {
        const timeoutId = setInterval(() => { }, 2000);
        getFO()
        return () => clearInterval(timeoutId);
    }, []);

    const getFO = async () => {
        try {
            const response = await services.getFOForView(coTin);
            if (response.status === 200) {
                setFOrders(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    const searchInputRef = useRef('');

    const searchFO = async (newinput) => {
        try {
            const response = await services.searchFOForView(coTin, newinput);
            if (response.status === 200) {
                setFOrders(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    }

    const [isFormVisible, setFormVisible] = useState(false);
    const [isEditFormVisible, setEditFormVisible] = useState(false);
    //for editing
    const [selectedFO, setSelectedFO] = useState('')

    const handleAddFOForm = () => {
        setFormVisible(true);
    };

    const handleEditForm = (item) => {
        setSelectedFO(item)
        // console.log(item)
        setEditFormVisible(true)
    }
    const handleCloseForm = () => {
        setFormVisible(false);
        setEditFormVisible(false)
        // setTruck('');
        // setgrossWeight('');
        // setOrderDeliveryDate(null);
        // setStartDate(null);
        // setOrigin('Djibouti');
        // setDestination('Addis Abeba');
        // setTariff('');
        // setConsignee('');
        // setOrderGenerator('Company')
        // setRemark('');
    }

    const AddNewFO = async (formData) => {
        if (formData.startDate, formData.orderDeliveryAgreementDate, formData.consignee) {
            try {
                setaddingNewFo(true)
                const response = await services.postFO(formData.remark, formData.startDate, formData.orderDeliveryAgreementDate, formData.consignee, formData.container, formData.commodity, formData.fo, formData.truck, formData.grossWeight, formData.tariff, formData.origin, formData.destination, formData.orderGenerator, coTin, UserID);
                if (response.status === 200) {
                    getFO()
                    setaddingNewFo(false);
                    window.alert("Freight Order Posted.")
                } else {
                    setaddingNewFo(false);
                    window.alert("Error posting Freight Order. Please Try again.")
                    console.error(`Error: ${response.status}`);
                }
            } catch (error) {
                console.error(error);
                setaddingNewFo(false);
            }
            // setFormVisible(false);
        }
        else {
            window.alert("Please add all necessary data.")
        }
    };

    const handleUpdateFO = async (item) => {
        try {
            setaddingNewFo(true)
            const response = await services.updateFO(item.oldfo, item.fo, item.sDate, item.dDate, item.consignee, item.truck, item.grossWeight, item.tariff, item.origin, item.commodity, item.container, item.destination, item.orderGenerator, item.remark, coTin, UserID);
            if (response.status === 200) {
                getFO()
                setaddingNewFo(false);
                window.alert("Freight Order updated.")
            } else {
                setaddingNewFo(false);
                window.alert("Error updating Freight Order. Please Try again.")
                console.error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            setaddingNewFo(false);
        }
        setFormVisible(false);
    };

    const getFODetail = (item) => {
    }

    const getTruckFreightHistory = async (item) => {
        setFreightOrdersPerTruck('')
        setGettingTruckHistory(true)
        setSelectedTruck(item)
        try {
            const response = await services.getFOForTruckHistory(coTin, item);
            if (response.status === 200) {
                setFreightOrdersPerTruck(response.data);
                setGettingTruckHistory(false);
            } else {
                console.error(`Error: ${response.status}`);
                setGettingTruckHistory(false);
            }
        } catch (error) {
            console.error(error);
            setGettingTruckHistory(false);
        }
    };

    const renderHeader = () => (
        <View style={Styles.tableRow}>
            <Text style={Styles.tableCellHeader}>From</Text>
            <Text style={Styles.tableCellHeader}>To</Text>
            <Text style={Styles.tableCellHeader}>Owner</Text>
            <Text style={Styles.tableCellHeader}>Freight No.</Text>
            <Text style={Styles.tableDateCellHeader}>Departure</Text>
            <Text style={Styles.tableDateCellHeader}>Arrival</Text>
            {/* <Text style={Styles.tableCellHeader}>Truck</Text> */}
            {/* <Text style={Styles.tableCellHeader}>Trailer</Text> */}
            <Text style={Styles.tableCellHeader}>Tariff</Text>
            {/* <Text style={Styles.tableCellHeader}>Weight</Text> */}
            {/* <Text style={Styles.tableCellHeader}>Penality</Text>
            <Text style={Styles.tableCellHeader}>Reason</Text> */}
            <Text style={Styles.tableCellHeader}>Status</Text>
        </View>
    );

    const renderItem = ({ item, index }) => {
        const calculateBackgroundColor = (index) => {
            const isEven = index % 2 === 0;
            const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
            return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
        };
        return (
            <View style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }}>
                <Text style={Styles.tableCell}>{item.origin == 'Addis Ababa' ? 'A.A' : item.origin}</Text>
                <Text style={Styles.tableCell}>{item.destination == 'Addis Ababa' ? 'A.A' : item.destination}</Text>
                <Text style={Styles.tableCell}>{item.cus_name}</Text>
                <Text style={Styles.tableCell}>{item.idfreightOrder}</Text>
                <Text style={Styles.tableDateCell}>{item.orderDeparture ? <FormattedDate dateString={item.orderDeparture} /> : null}</Text>
                <Text style={Styles.tableDateCell}>{item.orderUnloaingComplete ? <FormattedDate dateString={item.orderUnloaingComplete} /> : null}</Text>
                <Text style={Styles.tableDateCell}>{item.orderWeight} x {<MoneyFormat amount={item.orderTariff} />}</Text>
                {/* <Text style={Styles.tableCell}></Text> */}
                {/* <Text style={Styles.tableCell}>{item.pen_amount = '0' ? '-' : <MoneyFormat amount={item.pen_amount} />}</Text>
                <Text style={Styles.tableCell}>{item.pen_reason}</Text> */}
                <Text style={Styles.tableCell}>{item.orderStatus}</Text>
            </View>
        )
    };
//freight search with range
const [searchCost, setsearchCost] = useState(false)
const [isDatePickerVisible, setDatePickerVisible] = useState(false);
const [downloadingFOReport, setDownloadingFOReport] = useState(false)
const ExpenseSearchInputRef = useRef('');

const [foReport, setFOReport] = useState('')
const [fetchingFOReport, setFetchingFOReport] = useState(false)
const [fetchedFOReport, setFetchedFOReport] = useState('')
const [searchingCustomer, setSearchingCustomer] = useState('');
const [fromDate, setFromDate] = useState(null);
const [toDate, setToDate] = useState(null);

const clearRangeForm = () => {
    setSearchingCustomer('');
    setFromDate(null);
    setToDate(null);
    setFetchedFOReport('')
}

const findFOForFOReport= async ()=>{
    setFetchingFOReport(true)
    try {
        if (fromDate && toDate) {
            if (searchingCustomer && fromDate && toDate) {
                const response = await services.searchFOPostedWithDate(coTin, searchingCustomer, fromDate, toDate);
                if (response.status === 200) {
                    setFetchedFOReport(response.data);
                    setFetchingFOReport(false);
                } else {
                    console.error(`Error: ${response.status}`);
                    setFetchingFOReport(false);
                }
            }
            else if(fromDate && toDate && !searchingCustomer) {
                const response = await services.searchFOPostedWithJustDate(coTin, fromDate, toDate);
                if (response.status === 200) {
                    setFetchedFOReport(response.data);
                    setFetchingFOReport(false);
                } else {
                    console.error(`Error: ${response.status}`);
                    setFetchingFOReport(false);
                }
            }
            else{ window.alert("Please select date to search!"); setFetchingFOReport(false); }
        } else { window.alert("Please select date to search."); setFetchingFOReport(false); }
    } catch (error) {
        console.error(error);
        setFetchingFOReport(false);
    }
}

const downloadFOReport = async () => {
    setDownloadingFOReport(true)
    const headers = [
        'No.',
        'FO',
        'Truck',
        'Tariff',
        'FO Added',
        'Departure',
        'Arrival',
        'Container',
        'Origin',
        'Destination',
        'Customer',
        'Status',
    ];

    // ,,id_op, id_com,id_pen, ,, ,, ,,
    const data = fetchedFOReport.map((item, index) => [
        index + 1,
        item.idfreightOrder,
        item.truck_plate,
        item.orderWeight+'x'+item.orderTariff,
        item.orderAddedDate,
        item.orderDeparture,
        item.orderUnloaingComplete,
        item.orderContainer,
        item.origin,
        item.destination,
        item.cus_name,
        item.orderStatus,
    ]);

    // Create a worksheet with custom headers and data
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

    // Create a workbook
    const wb = XLSX.utils.book_new();

    // Add the worksheet to the workbook
    XLSX.utils.book_append_sheet(wb, worksheet, 'Payment Summary');

    // Generate a Blob
    const wbout = XLSX.write(wb, {
        bookType: 'xlsx',
        type: 'array',
    });

    const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    
    const givenFileName = prompt("What do you want to name the downloaded document?")

    if (givenFileName){
        const filename =`${givenFileName}.xlsx`;
        saveAs(blob, filename);
        setDownloadingFOReport(false)
    }else{
        const filename = 'Payment Summary.xlsx';
        saveAs(blob, filename);
        setDownloadingFOReport(false) 
    }

};
const listFetchedFOReport = ({ item, index }) => {

    const calculateBackgroundColor = (index) => {
        const isEven = index % 2 === 0;
        const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
        return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
    };
    return (<>
        {/* <PrintableComponent ref={printableRef} item={item} /> */}
        <View style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }}>
            <Text style={Styles.tableCellIcon}> {index+1}</Text>
            <Text style={Styles.tableCellIcon}>{' '}</Text>
            <Text style={Styles.tableCell}>{item.idfreightOrder? item.idfreightOrder : '-'}</Text>
            <Text style={Styles.tableCell}>{item.truck_plate ? item.truck_plate : '-'}</Text>
            <Text style={Styles.tableCellIcon}>{' '}</Text>
            <Text style={Styles.tableCell}>{item.orderWeight && item.orderTariff ? (item.orderWeight+'x'+item.orderTariff) : '-'}</Text>
            {/* <Text style={Styles.tableCell}>{? item.orderTariff : '-'}</Text> */}
            {/* <Text style={Styles.tableCell}>{item.orderTotal?<MoneyFormat amount={item.orderTotal} /> : '-' }</Text> */}
            <Text style={Styles.tableCell}>{item.orderAddedDate ? <FormattedDateNoHR dateString={item.orderAddedDate}/> : '-'}</Text>
            <Text style={Styles.tableCell}>{item.orderDeparture ? <FormattedDateNoHR dateString={item.orderDeparture}/> : '-'}</Text>
            <Text style={Styles.tableCell}>{item.orderUnloaingComplete ? <FormattedDateNoHR dateString={item.orderUnloaingComplete}/> : '   -  '}</Text>
            <Text style={Styles.tableCell}>{item.orderContainer ? item.orderContainer+'  ' : '    -      '}</Text>
            <Text style={Styles.tableCellIcon}>{' '}</Text>
            <Text style={Styles.tableCell}>{item.origin == 'Addis Ababa' ? 'A.A' : item.origin}</Text>
            <Text style={Styles.tableCell}>{item.destination == 'Addis Ababa' ? 'A.A' : item.destination}</Text>
            <Text style={Styles.tableCell}>{item.cus_name ? item.cus_name : '-'}</Text>
            {/* <Text style={Styles.tableCell}>{item.orderRemark ? item.orderRemark : '-'}</Text> */}
            <Text style={Styles.tableCell}>{item.orderStatus ? item.orderStatus : '-'}</Text>
            {/* <Text style={Styles.tableCell}>{item.user_name ? item.user_name : '-'}</Text> */}
        </View>
    </>)
};
    return (
        <View style={Styles.mainContainer}>
            <AddFOForm isVisible={isFormVisible} onClose={handleCloseForm} onSubmit={AddNewFO} />
            <EditFOForm isVisible={isEditFormVisible} onClose={handleCloseForm} onUpdate={handleUpdateFO} foData={selectedFO} />
            <View style={Styles.Leftview}>
                <View style={Styles.headerContainer}>
                    <TouchableOpacity onPress={handleAddFOForm}>
                        {addingFO ? <ActivityIndicator size="small" color={colors.primary} /> : <Text style={Styles.headeText}>Add Order</Text>}
                    </TouchableOpacity>
                    {/* <TouchableOpacity style={Styles.headerRight} onPress={() => navigation.navigate('Add')}>
                    <MaterialCommunityIcons name="magnify" size={26} color="black"
                        onPress={() => {
                            navigation.navigate('Add')
                        }}
                    />
                    <Text style={Styles.headeText}>Search</Text>
                </TouchableOpacity> */}
                </View>
                <View style={Styles.searchBarContainer}>
                    <TextInput
                        ref={searchInputRef}
                        style={Styles.AppTextInput}
                        onChangeText={newinput => searchFO(newinput)}
                        keyboardType="default"
                        placeholderTextColor={colors.Grey}
                        placeholder={'Order, Plate, Trailer, Cosignee'}
                    >
                    </TextInput>
                    <Button title='Clear' onPress={() => { searchInputRef.current.clear(), getFO() }} />
                </View>
                {!refreshing ?
                    <>
                        {fOrders.length > 0 ?
                            <>
                                <View style={Styles.FOContainer} >
                                    <FlatList data={fOrders}
                                        keyExtractor={(item) => item.idfreightOrder}
                                        refreshControl={<RefreshControl refreshing={refreshing} onRefresh={getFO} />}
                                        renderItem={({ item }) => (
                                            <View>
                                                <FOLister
                                                    // id={item.acc_id}
                                                    freightOrderNo={item.idfreightOrder}
                                                    orderContainer={item.orderContainer}
                                                    commodity={item.orderCommodity}
                                                    topRightIcon={<Feather name="edit" size={20} color="black" />}
                                                    topRightIconButton={() => handleEditForm(item)}
                                                    employee_name={item.user_id + " " + item.user_name}
                                                    orderDeparture={<FormattedDate dateString={item.orderDeparture} />}
                                                    orderDeliveryAgreementDate={<FormattedDate dateString={item.orderDeliveryAgreementDate} />}
                                                    originPlace={item.origin}
                                                    destinationPlace={item.destination}
                                                    orderStatus={item.orderStatus}
                                                    truck_plate={gettingTruckHistory && selectedTruck == item.truck_plate ? <ActivityIndicator size="small" color={colors.primary} /> : item.truck_plate}
                                                    onFOpress={() => getFODetail(item.idfreightOrder)}
                                                    onPlatePress={() => getTruckFreightHistory(item.truck_plate)}
                                                    // trailer_plate={item.trailer_plate}
                                                    driver_name={item.driver_name}
                                                    middleName={item.middleName}
                                                    phone_addis={item.phone_addis}
                                                    phone_djibouti={item.phone_djibouti}
                                                    //orderWeight={item.orderWeight}
                                                    orderTariff={item.orderWeight + "x" + item.orderTariff}
                                                    orderTotal={<MoneyFormat amount={item.orderTotal} />}
                                                    paymentForTruck={item.outgoing_payment_made ? <MoneyFormat amount={item.outgoing_payment_made} /> : "-"}
                                                    paymentForCompany={item.incoming_payment_made ? item.paymentForCompany : "-"}
                                                    orderTotalTitle={item.incoming_payment_made != 0.00 ? 'Payment Received' : 'Expected Pay'}
                                                    orderPay={item.incoming_payment_made ? <MoneyFormat amount={item.incoming_payment_made} /> : <MoneyFormat amount={item.orderTotal} />}
                                                    // incoming_payment_made={<MoneyFormat amount={item.incoming_payment_made} />}
                                                    orderGenerator={item.orderGenerator}
                                                    cus_name={item.cus_name}
                                                    cus_phone={item.cus_phone}
                                                    id_pen={item.id_pen}
                                                    pen_from={item.pen_from}
                                                    pen_reason={item.pen_reason}
                                                    pen_ErrorFoundedFO={item.Pen_ErrorFoundedFO}
                                                    pen_amount={<MoneyFormat amount={item.pen_amount} />}
                                                    remark={item.orderRemark}
                                                    // qelem={!item.incoming_payment_made ? colors.stardust : ((item.incoming_payment_made + (item.orderTotal * 0.02)) < item.orderTotal && !item.id_pen ? colors.red : colors.tertiary)}
                                                    qelem={item.pen_fo == item.idfreightOrder && item.pen_from == 'Truck' ? colors.red : null}
                                                //cus_email={item.cus_email}
                                                // phone={" | " + item.comp_address}

                                                // subtext2={"| " + item.comp_phone}
                                                // interest={" " + removeFirstCharacter(item.comp_tin)}
                                                // subtext3={"| " + item.comp_status}
                                                // changeText={(quan) => update_sale(quan, item.id_item)}
                                                // actionName1={
                                                //     // <AntDesign name="edit" size={17} color={colors.secondary} />
                                                //     // <Ionicons name="ios-add" size={20} color={colors.secondary} />
                                                // }
                                                // ButtonPress1={() => setSelectedItem(item)} // console.log(item) 
                                                //ButtonPress1={()=>{console.log(item)} } ()=>setIssue(issue => [item,...issue]){()=>{getItemsList(item)}}
                                                />
                                            </View>
                                        )}
                                    />
                                </View>

                            </>
                            :
                            <View style={Styles.NoFOContainer}>
                                <Text>No Freight Order Available.</Text>
                                <Text>If this is a mistake hit clear to try again.</Text>
                            </View>

                        }
                    </>
                    :
                    <View style={Styles.FOContainer}>
                        <ActivityIndicator size="small" color={colors.primary} />
                    </View>
                }
            </View>
            <View style={Styles.rightView}>
                {/* Top Part*/}
                {freightOrdersPerTruck ? <>
                    <View style={Styles.truckDetailContainer}>
                        <View style={Styles.truckDetailHeaderContainer}> {/* title container */}
                            {selectedTruck ? <View style={Styles.loadedModalTitle}><Text style={Styles.modalTitle}>Deployment History For Truck {selectedTruck}</Text></View> : <Text style={Styles.modalTitle}>No Deployment History Found</Text>}
                            {/* <View style={Styles.twoColContainer}>
                            <View style={Styles.truckDetailLeft}>

                            </View>
                            <View style={Styles.truckDetailRight}>

                            </View>
                        </View> */}
                         <Button title='X' onPress={() => { setFreightOrdersPerTruck(null)}} />
                         </View>
                            
                        <View style={Styles.truckDetailTable}>
                            {renderHeader()}
                        </View>
                        <FlatList
                            data={freightOrdersPerTruck}
                            keyExtractor={(item) => item.idfreightOrder}
                            renderItem={renderItem}
                        />
                    </View>
                </> :
                <>
                    {/* <MapComponent /> */}
                    <View style={Styles.formContainer}>
                        <View style={Styles.modalTitleContainer}><Text style={Styles.modalFOSearchTitle}>  Freight Order Report</Text></View>
                        <View style={Styles.searchContainerWide}>
                        {/* <TextInput
                            ref={ExpenseSearchInputRef}
                            style={Styles.inputTableSearch}
                            placeholder="Search Paid Cost"
                            onChangeText={(input) => searchExpenses(input)}
                        /> */}

                        <Picker
                            selectedValue={searchingCustomer}
                            origin={origin}
                            style={{ height: 29, width: 130, marginLeft: 10,marginRight:5, borderColor: 'skyblue', borderRadius: 5, fontSize: 14.2 }}
                            onValueChange={(itemValue, itemIndex) => setSearchingCustomer(itemValue)}
                        >
                            <Picker.Item label="All Customers" value="" />
                            <Picker.Item label="ATD" value="ATD" />
                            <Picker.Item label="Negade" value="Negade" />
                            <Picker.Item label="Maheber" value="Maheber" />
                        </Picker>
                            <DatePicker
                                style={Styles.datepickerStyle}
                                appearance="default"
                                size='sm'
                                showicon='true'
                                defaultValue={null}
                                value={fromDate}
                                onChange={(date) => setFromDate(date)}
                                // showTimeSelect
                                placeholder='From Date'
                                dateformat="Pp"
                                cleanable
                            />
                            <DatePicker
                                style={Styles.datepickerStyle}
                                appearance="default"
                                size='sm'
                                showicon='true'
                                defaultValue={null}
                                value={toDate}
                                onChange={(date) => setToDate(date)}
                                // showTimeSelect
                                placeholder='To Date'
                                cleanable
                                dateformat="Pp"
                            />
                            <TouchableOpacity style={Styles.smallButtonContainer} onPress={clearRangeForm}>
                                <Text style={Styles.buttonText}>Clear</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={Styles.smallButtonContainer} onPress={findFOForFOReport}>
                                {fetchingFOReport ? <ActivityIndicator size="small" color={colors.white} /> : <Text style={Styles.buttonText}>Search</Text>}
                            </TouchableOpacity>
                      
                            <TouchableOpacity style={Styles.smallButtonContainer} onPress={downloadFOReport}>
                                {downloadingFOReport ? <ActivityIndicator size="small" color={colors.white} /> : <><Text style={Styles.buttonText}><Feather name="download" size={14} color={colors.white} />  Download</Text></>}
                            </TouchableOpacity>
                 
                    </View>
                    </View>
                    {!fetchedFOReport?
                        <View style={Styles.centeredText}>
                            <Text>☝️ Tweek the filter and hit 'Search' to generate and download freight order report;</Text>
                            <Text>👈 or press truck plate number from freight order list to see travel history of each truck.</Text>
                        </View>
                        :
                        <>
                        {fromDate && toDate ?<View style={Styles.tableTitleContainer}><Text style={Styles.tableTitle}>Showing Freight orders from </Text><Text style={Styles.tableDateTitle}>{fromDate.toDateString()}</Text><Text style={Styles.tableTitle}> to </Text><Text style={Styles.tableDateTitle} >{toDate.toDateString()}</Text></View>:null}
                        <View style={Styles.tableHeader}>
                            <View style={Styles.tableRow}>
                                <Text style={Styles.tableCellHeaderSmall}>No.</Text>
                                <Text style={Styles.tableCellHeaderSmall}> </Text>
                                <Text style={Styles.tableCellHeader}>FO</Text>
                                <Text style={Styles.tableCellHeader}>Truck</Text>
                                <Text style={Styles.tableCellHeaderSmall}> </Text>
                                <Text style={Styles.tableCellHeader}>Tariff</Text>
                                <Text style={Styles.tableCellHeader}>FO Added</Text>
                                <Text style={Styles.tableCellHeader}>Departure</Text>
                                <Text style={Styles.tableCellHeader}>Arrival</Text>
                                {/* <Text style={Styles.tableCellHeader}>Commodity</Text> */}
                                <Text style={Styles.tableCellHeader}>Container</Text>
                                <Text style={Styles.tableCellHeaderSmall}> </Text>
                                <Text style={Styles.tableCellHeader}>Origin</Text>
                                <Text style={Styles.tableCellHeader}>Destination</Text>
                                <Text style={Styles.tableCellHeader}>Customer</Text>
                                {/* <Text style={Styles.tableCellHeader}>Remark</Text> */}
                                <Text style={Styles.tableCellHeader}>Status</Text>
                            </View>
                        </View>
                        <FlatList
                            data={fetchedFOReport}
                            keyExtractor={(item) => item.idfreightOrder}
                            renderItem={listFetchedFOReport}
                        />
                        </>
                    }
                </>
                }
            </View>
        </View>
    );
}

const Styles = StyleSheet.create({
    formContainer: {
        // marginTop: 4,
        height: '9%',
        width: '98.9%',
        flexDirection: 'column',
        // alignItems: 'flex-start',
        backgroundColor: colors.AppCanvasMain,
        elevation: 9,
        borderRadius: 5,
        // justifyContent: 'center',
        justifyContent:'space-evenly',
        // paddingLeft: 5,
        marginBottom: 5,
        marginTop:10,
        // paddingTop: 5,
        borderColor: colors.skyblue,
        borderWidth: 1
    },
    searchContainerWide: {
        flexDirection: "Row",
        height: 30,
        marginBottom: 4,
        // justifyContent:'space-between'
    },
    smallButtonContainer: {
        // width: 100,
        marginRight: 5,
        // alignSelf: 'flex-end',
        // justifyContent: 'space-evenly',
        // flexDirection: 'row',
        marginTop: 2,
        // paddingHorizontal: 3,
        backgroundColor: '#2196F3',
        height: 28,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        paddingHorizontal:10,
        paddingVertical:10,
    },
    centeredText:{
        alignItems:'center',
        justifyContent:'center',
        flex:1
    },
    modalFOSearchTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        // marginTop: 5,
        // marginLeft: 5,
    },
    tableDateTitle:{
        fontSize:16,
        fontWeight:'500'
    },
    tableTitle:{
        fontSize:15,
        fontWeight:'400'
    },
    tableTitleContainer:{
        flexDirection:'row',
        marginBottom:5
    },
    modalTitleContainer:{
        // padding:5
    },
    datepickerStyle: {
        width: 140,
        height: 15,
        marginRight:5
    },
    inputTableSearch: {
        height: 30,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        marginRight: 2,
        marginLeft: 2,
        paddingHorizontal: 5,
        marginBottom: 2,
        width: 160
    },
    buttonText: {
        color: 'white',
        fontWeight: 600,
        paddingHorizontal: 3,
    },
    truckDetailContainer: {
        height: '95%',
        width: '97%',
        margin: 2,
        elevation: 8,
        borderRadius: 5,
        backgroundColor: colors.AppCanvasMain,
    },
    truckDetailHeaderContainer: {
        // marginTop: 4,
        height: '7%',
        width: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-around'
    },
    twoColContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    truckDetailLeft: {
        height: '100%',
        width: '50%',
        backgroundColor: 'ornage'
    },
    truckDetailRight: {
        height: '100%',
        width: '50%',
        backgroundColor: 'green'
    },
    truckDetailTable: {
        // height: '40%',
        width: '100%',
        // backgroundColor: 'green',
        borderWidth: 1,
        marginLeft: 4,
        borderColor: 'skyblue',
    },
    tableRow: {
        flex: 1,
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'skyblue',
        paddingVertical: 3,
        backgroundColor: '#f0f0f0', // Header background color,
        // alignItems: 'center',
        // justifyContent: 'center'
    },
    tableCellHeader: {
        flex: .8,
        // padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCellHeaderSmall: {
        flex: .2,
        // padding: 2,
        fontWeight: 'bold',
        //alignItems: 'center',
        //justifyContent: 'center'
    },
    tableDateCellHeader: {
        flex: 1,
        padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCell: {
        flex: .8,
        // padding: 2,
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCellIcon: {
        flex: .3,
        // paddingLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableDateCell: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginVertical: 8,
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.AppCanvasMain
    },
    list: {
        width: '100%',
    },
    contactItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'gray',
    },
    contactName: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    mainContainer: {
        flex: 1,
        width: '100%',
        // alignItems: 'center',
        // justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: colors.AppCanvasMain,
        height: SCREEN_HEIGHT,
    },
    headerContainer: {
        flexDirection: 'row',
        // alignItems: ''
        justifyContent: 'space-between',
        width: '100%',
        //  backgroundColor: colors.offwhite,
        paddingHorizontal: 12,
        backgroundColor: colors.AppCanvasMain,
        //paddingVertical: 10,
    },
    headerRight: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginRight: 
    },
    headeText: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    searchBarContainer: {
        width: "100%",
        height: 40,
        flexDirection: "row",
        alignContent: "space-between",
        // justifyContent: "center",
        backgroundColor: colors.AppCanvasMain
    },
    FOContainer: {
        width: "100%",
        // height: 350,
        flex: 1,
        flexDirection: "row",
        // alignContent: "space-between",
        justifyContent: "center",

        // alignItems: 'center',
        // backgroundColor: 'orange',
        paddingTop: 2,
        backgroundColor: colors.AppCanvasMain,
        // height: SCREEN_HEIGHT,
    },
    Leftview: {
        width: '27%',
        flexDirection: 'column',
        height: (SCREEN_HEIGHT - 5),
    },
    rightView: {
        backgroundColor: colors.AppCanvasMain,
        width: '73%',
        height: SCREEN_HEIGHT,
    },
    NoFOContainer: {
        width: "100%",
        // height: 350,
        flex: 1,
        flexDirection: "row",
        // alignContent: "space-between",
        justifyContent: "center",

        alignItems: 'center',
        // backgroundColor: 'orange',
        paddingTop: 5,
        flexDirection: 'column',
        backgroundColor: colors.AppCanvasMain
    },
    AppTextInput: {
        width: "80%",
        height: 40,
        borderRadius: 5,
        borderColor: colors.primary,
        borderWidth: 0.5,
        paddingLeft: 10,
        marginLeft: 5,
        //position: "relative",
        top: 0,
        //alignItems: "center",
        fontSize: 14,
        color: colors.primary,
    },
});