import React, { useState, useEffect } from 'react';
import * as Font from 'expo-font';
import { View, ActivityIndicator, Text, StyleSheet } from 'react-native';

const loadFonts = async () => {
    await Font.loadAsync({
      'Roboto-Black': require('../assets/fonts/Roboto-Black.ttf'),
      'Roboto-Bold': require('../assets/fonts/Roboto-Bold.ttf'),
      'Roboto-Light': require('../assets/fonts/Roboto-Light.ttf'),
      'Roboto-Medium': require('../assets/fonts/Roboto-Medium.ttf'),
      'Roboto-Regular': require('../assets/fonts/Roboto-Regular.ttf'),
      'Roboto-Thin': require('../assets/fonts/Roboto-Thin.ttf'),
    });
  };
 
  const FontLoader = ({ children }) => {
    const [fontsLoaded, setFontsLoaded] = useState(false);
  
    useEffect(() => {
      const fetchFonts = async () => {
        try {
          await loadFonts();
        } catch (error) {
          console.log("Error loading fonts: ", error);
        } finally {
          setFontsLoaded(true);
        }
      };
  
      fetchFonts();
    }, []);
  
    return (
      <View style={styles.container}>
        {children}
        {!fontsLoaded && <ActivityIndicator size="small" style={styles.loadingIndicator} />}
      </View>
    );
  };
  
  const styles = StyleSheet.create({
    container: {
      flex: 1,
    },
    loadingIndicator: {
      position: 'absolute',
      top: 10,
      right: 10,
    },
  });
  
  export default FontLoader;
  