import React from 'react';
import { Text } from 'react-native';

function MoneyFormat({ amount }) {
    if (amount === null) {
        return null
    }
    // Convert the amount to a string with 2 decimal places
    const amountStr = amount.toFixed(2);

    // Split the amount into dollars and cents
    const [dollars, cents] = amountStr.split(".");

    // Add commas to the dollars part
    const dollarsWithCommas = addCommas(dollars);

    // Combine the dollars and cents parts
    let formattedAmount = dollarsWithCommas;
    if (cents === "00") {
        formattedAmount += ".00";
    } else {
        formattedAmount += `.${cents}`;
    }

    return (
        <Text>{formattedAmount}</Text>
    );
}

function addCommas(number) {
    return number.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default MoneyFormat;