import React, { useState, useRef, useContext, useEffect } from 'react';
import { View, FlatList, TouchableOpacity, Text, Picker, StyleSheet, TextInput, RefreshControl, ActivityIndicator, Button, Dimensions, Alert, Window, Platform } from 'react-native';
// import AppCanvas from '../config/AppCanvas';
// import Buttons from "../components/Buttons";
import FormattedDate from '../components/FormattedDate';
import MoneyFormat from '../components/MoneyFormat';
import * as Print from 'expo-print';
// import { shareAsync } from 'expo-sharing';
// import { Print } from 'expo';
import FOLister from '../components/FOLister';
import colors from '../config/colors';
import { Feather } from '@expo/vector-icons';
import AuthContext from '../auth/context';
import services from '../api/services';
import AddFOForm from './AddFOForm';
// import MapComponent from '../components/MapComponent';
import EditFOForm from './EditFOForm'
import HomeScreen from './HomeScreen';
import { AntDesign } from '@expo/vector-icons';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import PrintableComponent from '../components/PrintableComponent'
import "rsuite/dist/rsuite.min.css";
import { DatePicker } from "rsuite";
import * as XLSX from 'xlsx';
const SCREEN_HEIGHT = Dimensions.get('window').height;

export default function Commission({ navigation }) {

    const { user, setUser, selectedCo_name, setSelectedCo_name, selectedCo_Tin, setSelectedCo_Tin } = useContext(AuthContext)

    const [fOrders, setFOrders] = useState('')
    const [refreshing, setRefreshing] = useState(true);


    const UserID = user.userId
    const coTin = user.userCompTin
    const compInUse = user.userCompName

    useEffect(() => {
        const timeoutId = setInterval(() => { }, 2000);
        getFO()
        // getCommissions()
        getCommissions()
        const currentDate = new Date();
        const oneMonthFromNow = new Date();
        oneMonthFromNow.setMonth(currentDate.getMonth() + 1);
        setAppDate(oneMonthFromNow);
        return () => clearInterval(timeoutId);
    }, []);

    const getFO = async () => {
        try {
            const response = await services.getFOForView(coTin);
            if (response.status === 200) {
                setFOrders(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    //---------------------------------------APPOINTMENTS---------------------------------------------

    const [appDate, setAppDate] = useState(null);
    const foInputRef = useRef('');
    const amountInputRef = useRef('');
    const voucherInputRef = useRef('');
    const remarkInputRef = useRef('');
    const AppoSearchInputRef = useRef('');
    const ExpenseSearchInputRef = useRef('');

    const [commissions, setCommissions] = useState('')
    const [deletingCom, setdeletingCom] = useState(false)

    const getCommissions = async () => {
        try {
            const response = await services.getCommissions(coTin);
            if (response.status === 200) {
                setCommissions(response.data);
                // console.log(response.data)
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    // const renderAppointment = () => (

    // );
    const listExpenses = ({ item, index }) => {

        const calculateBackgroundColor = (index) => {
            const isEven = index % 2 === 0;
            const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
            return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
        };
        return (<>
            {/* <PrintableComponent ref={printableRef} item={item} /> */}
            <View style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }}>
                <Text style={Styles.tableCell}>{item.op_voucher ? item.op_voucher : null}</Text>
                <Text style={Styles.tableCell}>{item.idfreightOrder ? item.idfreightOrder : null}</Text>
                <Text style={Styles.tableCell}>{item.truck_plate ? item.truck_plate : null}</Text>
                {/* <Text style={Styles.tableCell}>{item.origin == 'Addis Ababa' ? 'A.A' : item.origin}</Text>
                <Text style={Styles.tableCell}>{item.destination == 'Addis Ababa' ? 'A.A' : item.destination}</Text> */}
                {/* <Text style={Styles.tableCell}>{item.cus_name}</Text> */}
                <Text style={Styles.tableDateCell}>{item.orderDeparture ? <FormattedDate dateString={item.orderDeparture} /> : null}</Text>
                <Text style={Styles.tableDateCell}>{item.orderUnloaingComplete ? <FormattedDate dateString={item.orderUnloaingComplete} /> : null}</Text>
                <Text style={Styles.tableCell}>{item.outgoing_payment_made ? <MoneyFormat amount={item.outgoing_payment_made} /> : null}</Text>
                <Text style={Styles.tableCell}>{item.op_reason}</Text>
                <Text style={Styles.tableDateCell}>{item.op_added_date ? <FormattedDate dateString={item.op_added_date} /> : null}</Text>
                {/* <Text style={Styles.tableCell}>{item.orderStatus}</Text> */}
                <View style={Styles.tableCellIcon}>{deletingCom ? <ActivityIndicator size={14} color={colors.primary} /> : <>{item.op_reason === 'Loan' ? <Text> </Text> : <AntDesign name="delete" size={14} color="red" onPress={() => deletePayment(item.id_op, item.op_voucher)} />}</>}</View>
                {/* <View style={Styles.tableCellIcon}>{deletingCom ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="printer" size={14} color={colors.primary} onPress={() => printToFile(item)} />}</View> */}
            </View>
        </>)
    };
    const listFoWCommission = ({ item, index }) => {

        const calculateBackgroundColor = (index) => {
            const isEven = index % 2 === 0;
            const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
            return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
        };
        return (<>
            {/* <PrintableComponent ref={printableRef} item={item} /> */}
            <View style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }}>
                <Text style={Styles.tableCell}>{item.idfreightOrder}</Text>
                <Text style={Styles.tableCell}>{item.truck_plate}</Text>
                <Text style={Styles.tableCell}>{item.cus_name}</Text>
                <Text style={Styles.tableCell}>{item.origin == 'Addis Ababa' ? 'A.A' : item.origin}</Text>
                <Text style={Styles.tableCell}>{item.destination == 'Addis Ababa' ? 'A.A' : item.destination}</Text>
                <Text style={Styles.tableCell}>{item.orderWeight} x {item.orderTariff}</Text>
                <Text style={Styles.tableCell}>{item.incoming_payment_made == '0' ? '-' : item.outgoing_payment_made}</Text>
                <Text style={Styles.tableCell}>{item.outgoing_payment_made == '0' ? '-' : item.outgoing_payment_made}</Text>
                <Text style={Styles.tableCell}>{item.commission ? item.commission : '-'}</Text>
                <Text style={Styles.tableCell}>{item.com_voucher ? item.com_voucher : '-'}</Text>
                <View style={Styles.tableCellIcon}>{deletingCom ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="delete" size={14} color="red" onPress={() => deleteCommission(item.id_com, item.com_voucher)} />}</View>
            </View>
        </>)
    };
    const clearForm = () => {
        foInputRef.current.clear()
        amountInputRef.current.clear()
        voucherInputRef.current.clear()
        remarkInputRef.current.clear()
    }
    const clearAppSearch = () => {
        AppoSearchInputRef.current.clear()
    }
    const clearExpenseSearch = () => {
        ExpenseSearchInputRef.current.clear()
    }

    const [fo, setFO] = useState('')
    const [amount, setAmount] = useState('')
    const [voucher, setVoucher] = useState('')
    const [reason, setRemark] = useState('Delivery')

    const [addingCommission, setaddingCommission] = useState(false)

    const submitCommission = async () => {
        try {
            setaddingCommission(true)
            const response = await services.postCommission(fo, amount, voucher, reason, coTin, UserID);
            if (response.status === 200) {
                clearForm()
                setaddingCommission(false);
                getCommissions()
                getCommissions()
                getFO()
                window.alert("Commission Posted.")
            } else {
                setaddingCommission(false);
                window.alert("Error posting Commission. Please try again.")
                console.error(`Error: ${response.status}`);
            }

        } catch (error) {
            console.error(error);
            setaddingCommission(false);
            clearForm()
        }
    };

    const deleteCommission = async (id_com, com_voucher) => {
        try {
            const confirmed = window.confirm(`Are you sure you want to delete voucher: ${com_voucher} payment?`);
            if (!confirmed) {
                return;
            }
            setdeletingCom(true);
            const response = await services.deleteCommission(id_com, coTin, UserID);
            if (response.status === 200) {
                getCommissions();
                window.alert("Payment deleted.");
                setdeletingCom(false);
            } else {
                setdeletingCom(false);
                window.alert("Error deleting payment. Please try again.");
                console.error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            setdeletingCom(false);
        } 
    };
    const searchSpecificCommission = async (input) => {
        try {
            if (!input || input.trim() === '') {
                return;
            }
            const response = await services.searchCommission(coTin, input);
            if (response.status === 200) {
                setCommissions(response.data);
                console.log(response.data)
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };
    const [searchCommission, setsearchCommission] = useState(false)
    const [isDatePickerVisible, setDatePickerVisible] = useState(false);
    const [downloadingInvoice, setDownloadingInvoice] = useState(false)

    const showDatePicker = () => {
        setDatePickerVisible(true);
    };

    const hideDatePicker = () => {
        setDatePickerVisible(false);
    };

    const handleConfirm = (date) => {
        setOrderDeliveryDate(date);
        hideDatePicker();
    };
    const clearRangeForm = () => {
        AppoSearchInputRef.current.clear()
        getCommissions()
        setSearchingCustomer(null);
        setFromDate(null);
        setToDate(null);
    }
    const [searchingCustomer, setSearchingCustomer] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    
    const findCommissionWithDateRange = async () => {
        setsearchCommission(true)
        try {
            if (fromDate && toDate) {
                if (searchingCustomer && fromDate && toDate) {
                    const response = await services.searchCommissionWithDate(coTin, searchingCustomer, fromDate, toDate);
                    if (response.status === 200) {
                        setCommissions(response.data);
                        setsearchCommission(false);
                    } else {
                        console.error(`Error: ${response.status}`);
                        setsearchCommission(false);
                    }
                }
                else if(fromDate && toDate) {
                    const response = await services.searchCommissionWithJustDate(coTin, fromDate, toDate);
                    if (response.status === 200) {
                        setCommissions(response.data);
                        setsearchCommission(false);
                    } else {
                        console.error(`Error: ${response.status}`);
                        setsearchCommission(false);
                    }
                }
                else{ window.alert("Please select date to search!"); setsearchCommission(false); }
            } else { window.alert("Please select date to search."); setsearchCommission(false); }
        } catch (error) {
            console.error(error);
            setsearchCommission(false);
        }
    }
    const downloadCommission = async () => {
        setDownloadingInvoice(true)
        const headers = [
            'No.',
            'Payment ID',
            'Customer',
            'Freight',
            'Truck',
            'Container',
            'Invoice',
            'Amount',
            'Payment On',
        ];

        const data = commissions.map((item, index) => [
            index + 1,
            item.id_op,
            item.cus_name,
            item.idfreightOrder,
            item.truck_plate,
            item.orderContainer, 
            item.op_voucher,
            item.outgoing_payment_made,
            item.op_added_date,
        ]);

        // Create a worksheet with custom headers and data
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

        // Create a workbook
        const wb = XLSX.utils.book_new();

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, worksheet, 'Cost');

        // Generate a Blob
        const wbout = XLSX.write(wb, {
            bookType: 'xlsx',
            type: 'array',
        });

        const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        
        const givenFileName = prompt("What should i name the downloaded document?")

        if (givenFileName){
            const filename =`${givenFileName}.xlsx`;
            saveAs(blob, filename);
            setDownloadingInvoice(false)
        }else{
            const filename = 'Cost.xlsx';
            saveAs(blob, filename);
            setDownloadingInvoice(false) 
        }

    };
    return (
        <View style={Styles.truckDetailContainer}>
            <View style={Styles.truckDetailHeaderContainer}> {/* title container */}
                <View style={Styles.modalTitleContainer}><Text style={Styles.modalTitle}>New Commission</Text></View>
                <View style={Styles.formContainer}>
                    <TextInput
                        ref={foInputRef}
                        style={Styles.input}
                        placeholder="Freight order No."
                        onChangeText={setFO}
                    />
                    <TextInput
                        ref={amountInputRef}
                        style={Styles.input}
                        placeholder="Commission Amount"
                        onChangeText={setAmount}
                    />
                    <TextInput
                        ref={voucherInputRef}
                        style={Styles.input}
                        placeholder="Voucher No."
                        onChangeText={setVoucher}
                    />
                    <TextInput
                        ref={remarkInputRef}
                        style={Styles.input}
                        placeholder="Remark"
                        onChangeText={setRemark}
                    />
                    <TouchableOpacity style={Styles.buttonContainer} onPress={clearForm}>
                        <Text style={Styles.buttonText}>Clear</Text>
                    </TouchableOpacity>
                    <TouchableOpacity style={Styles.buttonContainer} onPress={submitCommission}>
                        {addingCommission ? <ActivityIndicator size="small" color={colors.white} /> : <Text style={Styles.buttonText}>Submit</Text>}
                    </TouchableOpacity>
                </View>
                {/* <View style={Styles.twoColContainer}>
                            <View style={Styles.truckDetailLeft}>

                            </View>
                            <View style={Styles.truckDetailRight}>

                            </View>
                        </View> */}
            </View>
            <View style={Styles.modalTitleContainer}><Text style={Styles.tableTitle}>  {/*Appointments*/}</Text></View>
            <View style={Styles.searchContainerWide}>
                <TextInput
                    ref={AppoSearchInputRef}
                    style={Styles.inputTableSearch}
                    placeholder="Search Freight Orders"
                    onChangeText={(input) => searchSpecificCommission(input)}
                />
               
                
                {/* <View style={Styles.formContainer}> */}
                <Picker
                        origin={origin}
                        style={{ height: 29, width: 130, marginLeft: 10,marginRight:2, borderColor: 'skyblue', borderRadius: 5, fontSize: 14.2 }}
                        onValueChange={(itemValue, itemIndex) => setSearchingCustomer(itemValue)}
                    >
                        <Picker.Item label="All Customer" value="" />
                        <Picker.Item label="ATD" value="ATD" />
                        <Picker.Item label="Negade" value="Negade" />
                        <Picker.Item label="Maheber" value="Maheber" />
                    </Picker>
                            <DatePicker
                                style={Styles.datepickerStyle}
                                appearance="default"
                                size='sm'
                                showicon='true'
                                defaultValue={null}
                                selected={fromDate}
                                onChange={(date) => setFromDate(date)}
                                // showTimeSelect
                                placeholder='From Date'
                                dateformat="Pp"
                                cleanable
                            />
                            <DatePicker
                                style={Styles.datepickerStyle}
                                appearance="default"
                                size='sm'
                                showicon='true'
                                defaultValue={null}
                                selected={toDate}
                                onChange={(date) => setToDate(date)}
                                // showTimeSelect
                                placeholder='To Date'
                                cleanable
                                dateformat="Pp"
                                onClear={clearRangeForm}
                            />
                            <TouchableOpacity style={Styles.smallButtonContainer} onPress={clearRangeForm}>
                                <Text style={Styles.buttonText}>Clear</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={Styles.smallButtonContainer} onPress={findCommissionWithDateRange}>
                                {searchCommission ? <ActivityIndicator size="small" color={colors.white} /> : <Text style={Styles.buttonText}>Search</Text>}
                            </TouchableOpacity>
                      
                            <TouchableOpacity style={Styles.smallButtonContainer} onPress={downloadCommission}>
                                {downloadingInvoice ? <ActivityIndicator size="small" color={colors.white} /> : <><Text style={Styles.buttonText}><Feather name="download" size={14} color={colors.white} />  Download</Text></>}
                            </TouchableOpacity>
                           
                        {/* </View> */}
            </View>
            <View style={Styles.topTable}>
                <View style={Styles.tableHeader}>
                    <View style={Styles.tableRow}>
                        <Text style={Styles.tableCellHeader}>Freight</Text>
                        <Text style={Styles.tableCellHeader}>Truck</Text>
                        <Text style={Styles.tableCellHeader}>   Owner</Text>
                        <Text style={Styles.tableCellHeader}>   From</Text>
                        <Text style={Styles.tableCellHeader}>   To</Text>
                        <Text style={Styles.tableCellHeader}>   Tarrif</Text>
                        <Text style={Styles.tableCellHeader}>Income</Text>
                        <Text style={Styles.tableCellHeader}>Cost</Text>
                        <Text style={Styles.tableCellHeader}>Comm</Text>
                        <Text style={Styles.tableCellHeader}>  PV</Text>
                        <Text style={Styles.tableCellHeaderSmall}>Delete</Text>
                    </View>
                </View>
                <FlatList
                    data={commissions}
                    keyExtractor={(item) => item.idfreightOrder}
                    renderItem={listFoWCommission}
                />
            </View>

        </View>
    );
}

const Styles = StyleSheet.create({
    smallMenuContainer: {
        elevation: 8,
        backgroundColor: '#031D22',
        height: '10%',
        width: '100%',
        borderRadius: 5,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    smallMenuButton: {
        // backgroundColor: 'green',
        paddingHorizontal: 9,
        paddingVertical: 6,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    selectedsmallMenuButton: {
        // backgroundColor: 'grey',
        backgroundColor: '#09414c',
        paddingHorizontal: 9,
        paddingVertical: 6,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    smallMenuText: {
        fontSize: 16,
        fontWeight: 600,
        color: colors.offwhite,
    },
    truckDetailContainer: {
        height: '86%',
        width: '100%',
        margin: 2,
        elevation: 8,
        borderRadius: 5,
        backgroundColor: colors.AppCanvasMain,
    },
    truckDetailHeaderContainer: {
        // marginTop: 4,
        height: '12%',
        width: '99.7%',
        flexDirection: 'column',
        // alignItems: 'flex-start',
        backgroundColor: colors.AppCanvasMain,
        elevation: 9,
        borderRadius: 5,
        justifyContent: 'center',
        paddingLeft: 5,
        // marginBottom: 5,
        paddingTop: 5,
        paddingBottom: 5,
        borderColor: colors.skyblue,
        borderWidth: 1
    },
    modalTitleContainer: {
        // backgroundColor: 'orange',

    },
    twoColContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    truckDetailLeft: {
        height: '100%',
        width: '50%',
        // backgroundColor: 'ornage'
    },
    truckDetailRight: {
        height: '100%',
        width: '50%',
        backgroundColor: 'green'
    },
    topTable: {
        // height: '35%',
        flex: 1,
        flexDirection: 'column',
        width: '100%',
    },
    tableHeader: {
        // height: '40%',
        width: '100%',
        // backgroundColor: 'green',
        borderWidth: 1,
        // marginLeft: 4,
        paddingHorizontal: 2,
        borderColor: 'skyblue',
    },
    searchContainer: {
        width: '12%',
        flexDirection: "Row",
        height: 30,
        marginBottom: 2
    },
    searchContainerWide: {
        width: '100%',
        flexDirection: "Row",
        height: 30,
        marginBottom: 4,
    },
    tableRow: {
        flex: 1,
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'skyblue',
        //paddingVertical: 3,
        // marginHorizontal: 5,
        paddingVertical: 5,
        backgroundColor: '#f0f0f0', // Header background color,
        // alignItems: 'center',
        justifyContent: 'space-between'
    },
    tableCellHeader: {
        flex: 1,
        // padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center',
    },
    tableCellHeaderSmall: {
        flex: .2,
        // padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableDateCellHeader: {
        flex: .5,
        padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCell: {
        flex: 1,
        // marginLeft: 3,
        alignItems: 'center',
        justifyContent: 'center',
        alignSelf: 'center',
        marginHorizontal: 3,
    },
    tableDateCell: {
        flex: .5,
        // marginLeft: 3,
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCellIcon: {
        flex: .2,
        // paddingLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop: 5,
    },
    tableTitle: {
        fontSize: 15,
        fontWeight: 'bold',
        marginTop: 5,
        marginBottom: 2,
    },
    formContainer: {
        flexDirection: 'row',
        //alignItems: 'center'
        marginTop: 5,
        marginBottom: 5,
        paddingBottom: 5,
    },
    input: {
        height: 35,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        width: 160
    },
    inputTableSearch: {
        height: 30,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        marginLeft: 2,
        marginBottom: 2,
        width: 160
    },
    datepickerStyle: {
        // backgroundColor: 'grey',
        //  width: 50
        // height: 30
    },
    buttonContainer: {
        // width: 100,
        marginLeft: 3,
        // alignSelf: 'flex-end',
        // justifyContent: 'space-evenly',
        // flexDirection: 'row',
        marginTop: 2,
        paddingHorizontal: 5,
        backgroundColor: '#2196F3',
        height: 33,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    smallButtonContainer: {
        // width: 100,
        marginLeft: 2,
        // alignSelf: 'flex-end',
        // justifyContent: 'space-evenly',
        // flexDirection: 'row',
        marginTop: 2,
        paddingHorizontal: 3,
        backgroundColor: '#2196F3',
        height: 28,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    buttonText: {
        color: 'white',
        fontWeight: 600,
        paddingHorizontal: 3,
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.AppCanvasMain
    },
    list: {
        width: '100%',
    },
    contactItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'gray',
    },
    contactName: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    mainContainer: {
        flex: 1,
        width: '100%',
        // alignItems: 'center',
        // justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: colors.AppCanvasMain,
        height: SCREEN_HEIGHT,
    },
    headerContainer: {
        flexDirection: 'row',
        // alignItems: ''
        justifyContent: 'space-between',
        width: '100%',
        //  backgroundColor: colors.offwhite,
        paddingHorizontal: 12,
        backgroundColor: colors.AppCanvasMain,
        //paddingVertical: 10,
    },
    headerRight: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginRight: 
    },
    headeText: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    searchBarContainer: {
        width: "100%",
        height: 40,
        flexDirection: "row",
        alignContent: "space-between",
        // justifyContent: "center",
        backgroundColor: colors.AppCanvasMain
    },
    FOContainer: {
        width: "100%",
        // height: 350,
        flex: 1,
        flexDirection: "row",
        // alignContent: "space-between",
        justifyContent: "center",

        // alignItems: 'center',
        // backgroundColor: 'orange',
        paddingTop: 2,
        backgroundColor: colors.AppCanvasMain,
        // height: SCREEN_HEIGHT,
    },
    Leftview: {
        width: '27%',
        flexDirection: 'column',
        height: (SCREEN_HEIGHT - 5),
    },
    rightView: {
        backgroundColor: colors.AppCanvasMain,
        width: '73%',
        height: SCREEN_HEIGHT,
    },
    NoFOContainer: {
        width: "100%",
        // height: 350,
        flex: 1,
        flexDirection: "row",
        // alignContent: "space-between",
        justifyContent: "center",

        alignItems: 'center',
        // backgroundColor: 'orange',
        paddingTop: 5,
        flexDirection: 'column',
        backgroundColor: colors.AppCanvasMain
    },
    AppTextInput: {
        width: "80%",
        height: 40,
        borderRadius: 5,
        borderColor: colors.primary,
        borderWidth: 0.5,
        paddingLeft: 10,
        marginLeft: 5,
        //position: "relative",
        top: 0,
        //alignItems: "center",
        fontSize: 14,
        color: colors.primary,
    },
});