import React, { useContext, useState } from 'react';
import { StyleSheet, ScrollView, TextInput, Text, Button, View, Image, Platform, StatusBar, Alert, TouchableOpacity, Linking, ActivityIndicator } from 'react-native';
import AppCanvas from '../config/AppCanvas';
import AppOneWideButton from '../components/AppOneWideButton';
import colors from '../config/colors';
import { Formik, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import AppText from '../components/AppText';
import authApi from '../api/auth';
import {jwtDecode} from 'jwt-decode'
import AuthContext from '../auth/context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import services from '../api/services';

export default function LoginScreen(props) {
    //const AuthContext = useContext(AuthContext)

    const [loggingIn, setLoggingIn] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false)
    const [trigerForgotPassword, setTrigerForgotPassword] = useState(false)
    const [phoneNumber, setPhoneNumber] = useState(''); // For phonenumber input cache in login 

    useEffect(() => {
        // Retrieve phone number from AsyncStorage when component mounts
        const fetchPhoneNumber = async () => {
            const storedPhoneNumber = await AsyncStorage.getItem('phoneNumber');
            if (storedPhoneNumber) {
                setPhoneNumber(storedPhoneNumber);
            }
        };
        fetchPhoneNumber();
    }, []);

    const handleSubmit = async ({ email, password }) => {
        setLoggingIn(true);
        try {
            const result = await authApi.login(email, password);
            if (result.status === 200) {
                const token = result.data;
                const decodedToken = jwtDecode(token);
                const user = decodedToken.users;

                await AsyncStorage.setItem('user', JSON.stringify(user));
                await AsyncStorage.setItem('token', token);
                await AsyncStorage.setItem('expTime', decodedToken.exp)
                await AsyncStorage.setItem('phoneNumber', email);

                console.log(user)

                AuthContext.setUser(user);
                setLoggingIn(false);
            } else {
                setLoginFailed(true);
                setLoggingIn(false);
            }
        } catch (error) {
            setLoggingIn(false);
            setLoginFailed(true);
        }
    };

    //Optionally, you can add an effect to handle automatic logout if the app is closed and reopened after the token expires
    useEffect(() => {
        const checkTokenExpiration = async () => {
            const token = await AsyncStorage.getItem('token');
            if (token) {
                const decodedToken = jwtDecode(token);
                if (Date.now() >= decodedToken.exp * 1000) {
                    AuthContext.setUser(null);
                    AsyncStorage.removeItem('user');
                    AsyncStorage.removeItem('token');
                }
            }
        };
    
        checkTokenExpiration();
    }, []);

    const validationSchema = Yup.object().shape({
        email: Yup.string().required().label("Phone number"),
        password: Yup.string().required().label("Password")
    })

    const [phoneFornewPassword, setPhoneforNewPassword] = useState('')
    const [initiateReset, setInitiatereset] = useState(false)
 
    //---------------working-----------
    const handleResetPassword = async () => {
        if (phoneFornewPassword == '') {
            alert("Please fill in the input field");
            return;
        }
        try {
            const response = await services.resetPassFromLogin(phoneFornewPassword);
            if (response.status === 200) {
                alert("Password reset successful.");
                await AsyncStorage.removeItem('user');
                setInitiatereset(false);
                AuthContext.setUser(null);
            } else if (response.status === 400) {
                alert("Please enter a valid phone number.");
            } else {
                alert("An error occurred. Please try again later.");
            }
        } catch (error) {
            alert("An error occurred. Please try again later.");
        }
    }

    return (
        <AppCanvas>
            <ScrollView style={styles.scroll_View}>
                <View resizeMode='contain' style={styles.background}>
                    <View style={styles.logoContainer}>
                        {/* <Image style={styles.logo} source={{ uri: 'https://app.tenaline.com/assets/app_logo.jpg' }} /> */}
                        <Text style={styles.logoText}>Wedaj LMS</Text>
                        {/* <Text style={styles.logoText2}>Connecting the dots in healthcare</Text> */}
                    </View>
                    {!initiateReset ?
                        <Formik
                            initialValues={{ email: '', password: '' }}
                            onSubmit={handleSubmit}
                            validationSchema={validationSchema}
                        >
                            {({ handleChange, handleSubmit, errors, setFieldTouched, touched }) => (
                                <>

                                    <View style={styles.formContainer}>
                                        {/*------FORM-------*/}
                                        <TextInput
                                            style={styles.IdInput}
                                            onBlur={() => setFieldTouched("User name")}
                                            onChangeText={handleChange('email')}
                                            blurOnSubmit={true}
                                            placeholder="Phone Number"
                                            value={phoneNumber}
                                            placeholderTextColor={colors.secondary}
                                            keyboardType='numeric'>
                                        </TextInput>
                                        <View style={styles.errorText}>{touched.email && <AppText >{errors.email}</AppText>}</View>
                                        <TextInput
                                            style={styles.PasswordInput}
                                            onChangeText={handleChange('password')}
                                            secureTextEntry
                                            placeholder="Password"
                                            placeholderTextColor={colors.secondary}
                                            textContentType='password'
                                            keyboardType='default'>
                                        </TextInput>
                                        <View style={styles.errorText}>{touched.password && <AppText> {errors.password}</AppText>}</View>
                                        {/*-----LOG IN-------<ErrorMessage error="Invalid user name and/or password" visible={loginFailed}/>*/}
                                        <View style={styles.errorText}>{loginFailed ? <AppText> Invalid user name and/or password</AppText> : null}</View>
                                        <TouchableOpacity onPress={handleSubmit} style={styles.loginButtonContainer}>
                                            {loggingIn ? <ActivityIndicator size="small" color={colors.white} /> : <><Text style={styles.buttonText}>Login</Text></>}
                                        </TouchableOpacity>

                                        {/* <Text style={styles.CreateAccountText} onPress={() => { setInitiatereset(true) }} >Create new account</Text> */}

                                        <Text style={styles.ForgotPasswordText} onPress={() => handleResetPassword()} >Forgot Password?</Text>

                                    </View>
                                </>
                            )}
                        </Formik>
                        :
                        <View style={styles.formContainer}>

                            <TextInput
                                style={styles.IdInput}
                                //  onBlur={()=> setFieldTouched("User name")} 
                                onChangeText={newinput => setPhoneforNewPassword(newinput)}
                                blurOnSubmit={true}
                                placeholder="Phone Number"
                                placeholderTextColor={colors.grey}
                                keyboardType='numeric'
                                >
                            </TextInput>


                            <TouchableOpacity style={styles.FpasswordContainer2}>
                                <AppOneWideButton Button='Submit' onButtonPress={() => handleResetPassword()} />
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.FpasswordContainer}>
                                <AppOneWideButton Button='Cancel' onButtonPress={() => { setInitiatereset(false) }} />
                            </TouchableOpacity>

                        </View>
                    }
                    {/*-----call px------ */}
                    <View style={styles.CallPxSoftwareContainer}>
                        {/* <Text style={styles.CallPxSoftware} onPress={() => { Linking.openURL(`tel:{0910873030}`) }}>Help - Contact - About</Text> */}
                        <Text style={styles.CallPxSoftware}>Help - Contact - About</Text>
                    </View>
                </View>
            </ScrollView>
        </AppCanvas >
    );
}

const styles = StyleSheet.create({
    background: {
        width: "100%",
        justifyContent: "flex-end",
        flex: 1,
        alignItems: "center"
    },
    scroll_View: {
        width: "100%",
        //   justifyContent:"flex-end",
        flex: 1,
        //   alignItems:"center"
    },
    logoContainer: {
        // backgroundColor: colors.primary,
        // width:'100%',
        // height:'30%',
        width: 200,
        height: 200,
        justifyContent: 'flex-end',
        alignItems: 'center',
        paddingTop: 0,
        marginBottom: 30,
        borderRadius: 5
    },
    logo: {
        paddingTop: 20,
        marginTop: 60,
        width: '80%',
        bottom: 10,
        height: '80%',
        borderRadius: 100,
        transform: [
            { scaleX: 0.5 },
            { scaleY: 0.5 }
        ],
    },
    logoText: {
        fontSize: 24,
        fontWeight: "600",
        color: colors.secondary,
        // bottom: 50,
        // position:'absolute',
        // marginBottom:60
    },
    logoText2: {
        fontSize: 10,
        fontWeight: "600",
        color: colors.Grey,
        bottom: 50,
        // position:'absolute',
        // marginBottom:60
    },
    formContainer: {
        width: "20%",
        flex: 2,
        alignItems: 'center',
        // justifyContent:'center',
    },
    IdInput: {
        marginLeft: 2,
        paddingHorizontal: 3,
        height: 40,
        width: "80%",
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        borderBottomColor: colors.skyblue,
        borderRightColor: colors.white,
        borderTopColor: colors.white,
        borderLeftColor: colors.white,
        borderWidth: .1,
        paddingLeft: 10
    },
    PasswordInput: {
        marginLeft: 2,
        paddingHorizontal: 3,
        height: 40,
        width: "80%",
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        borderBottomColor: colors.skyblue,
        borderRightColor: colors.white,
        borderTopColor: colors.white,
        borderLeftColor: colors.white,
        borderWidth: .1,
        fontSize: 14,
        paddingLeft: 10,
    },
    loginButtonContainer: {
        marginLeft: 2,
        paddingHorizontal: 3,
        backgroundColor: '#2196F3',
        height: 40,
        width: "80%",
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    buttonText: {
        color: 'white',
        fontWeight: 600,
        paddingHorizontal: 3,
    },
    signupButtonContainer: {
        width: "80%",
        height: 40,
        marginBottom: 0,
        marginTop: 3,
        borderRadius: 10,
        alignItems: 'center',
        marginBottom: 15,
    },
    loginText: {
        alignItems: 'center',
        fontSize: 12,
        marginTop: 5,
        marginBottom: 5,
        color: 'white'
    },
    errorText: {
        height: 16,
        marginVertical: 0,
    },
    FpasswordContainer: {
        width: "80%",
        height: 40,
        marginBottom: 0,
        marginTop: 3,
        borderRadius: 10,
        alignItems: 'center',
        marginBottom: 10,
    },
    FpasswordContainer2: {
        width: "80%",
        height: 40,
        marginBottom: 0,
        marginTop: 27,
        borderRadius: 10,
        alignItems: 'center',
        marginBottom: 10,
    },
    FpasswordText: {
        alignItems: 'center',
        fontSize: 12,
        marginTop: 5,
        color: colors.black
    },
    CreateAccountText: {
        fontSize: 12,
        fontWeight: '400',
        marginTop: 3,
        marginBottom: 16,
        position: 'relative',
        color: colors.primary
    },
    ForgotPasswordText: {
        fontSize: 12,
        fontWeight: '400',
        marginTop: 3,
        marginBottom: 16,
        position: 'relative',
        color: colors.primary
    },
    CallPxSoftwareContainer: {
        paddingTop: 150,
        justifyContent: 'center'
    },
    CallPxSoftware: {
        fontSize: 12,
        fontWeight: '400',
        marginTop: 3,
        marginBottom: 16,
        position: 'relative',
        color: colors.primary,
    },

})