import React from 'react';

export default {
    // primary: '#1E2129',
    primary: '#002D62',
    secondary: "#2A2F3B",
    Charcoal: '#36454F',
    tertiary: '#DDE4EA',
    offwhite: '#ececec',
    offwhite2: '#f2f3f4',
    white: '#ffffff',
    RasinBlack: '#242124',
    MediumGrey: '#6e6969',
    black: 'black',
    AppCanvasMain: '#FAFAFA',// AppCanvasMain:'#F2F2F2',//#F8F8FF
    ForeGroundWhite: '#FAFAFA',
    Grey: 'grey',
    orange: 'orange',
    red: 'red',
    green: 'green',
    yellow: 'yellow',
    skyblue: 'skyblue',
    stardust: '#E1F6FF',
    baby_blue: '#89CFF0',
    baby_light_blue: '#d2e8fe'
    // iceberg:''
}