import React, { useState, useContext, useEffect } from 'react';
import { View, Text, TouchableOpacity, StyleSheet, Image, ScrollView, Dimensions } from 'react-native';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { Feather, AntDesign, MaterialCommunityIcons, MaterialIcons } from '@expo/vector-icons'; // Import Feather icons (or any other icon library)

import colors from './config/colors';
import Freight from './Screens/Freight';
import Trucks from './Screens/Trucks';
import Owner from './Screens/Owner';
import Driver from './Screens/Driver';
import Consignee from './Screens/Consignee';
import HomeScreen from './Screens/HomeScreen';
import FreightOG from './Screens/Freight OG';
import FinanceHome from './Screens/FinanceHome';
import LoginScreen from './Screens/LoginScreen';
import AuthContext from './auth/context';
import AsyncStorage from '@react-native-async-storage/async-storage';
import SplashScreen from './components/SplashScreen';
import {jwtDecode} from 'jwt-decode'
import Appointment from './Screens/Appointment';
import Expense from './Screens/Expense';
import Loan from './Screens/Loan';
import Penality from './Screens/Penality';
import Invoice from './Screens/Invoice';
import Income from './Screens/Income'
import Commission from './Screens/Commission';
import Dashboard from './Screens/Dashboard';
import Landing from './Screens/Landing';
import FontLoader from './components/FontLoader';

const Stack = createStackNavigator();

const SCREEN_HEIGHT = Dimensions.get('window').height;

// document.title = "WEDAJ LMS";
// // const { user, setUser, selectedCo_name, setSelectedCo_name, selectedCo_Tin, setSelectedCo_Tin } = useContext(AuthContext)
// const UserID = user.users[0].userId
// const coTin = user.users[0].userCompTin
// const compInUse = user.users[0].userCompName

const MainScreen = ({ navigation }) => {
   //const authContext = useContext(AuthContext)

  // const checkTokenExpiration = async () => {
  //   const storedToken = await AsyncStorage.getItem('user');
  //   if (storedToken) {
  //     const decodedToken = jwtDecode(storedToken);
  //     const currentTimestamp = Math.floor(Date.now() / 1000);

  //     if (decodedToken.exp && decodedToken.exp < currentTimestamp) {
  //       // Token is expired, perform logout
  //       await AsyncStorage.removeItem('user');
  //       await AsyncStorage.removeItem('token'); // Remove the taki token as well
  //       setUser(null);
  //     } else {
  //     }
  //   }
  // }  
//     useEffect(() => {
//     const checkTokenExpiration = async () => {
//         const token = await AsyncStorage.getItem('token');
//         if (token) {
//             const decodedToken = jwtDecode(token);
//             if (Date.now() >= decodedToken.exp * 1000) {
//                 AuthContext.setUser(null);
//                 AsyncStorage.removeItem('user');
//                 AsyncStorage.removeItem('token');
//             }
//         }
//     };

//     checkTokenExpiration();
// }, []);


  // Call this function when the app starts or when needed
  const [user, setUser] = useState()
  const [selectedCo_name, setSelectedCo_name] = useState()
  const [selectedCo_Tin, setSelectedCo_Tin] = useState()
  const [isLoading, setIsLoading] = useState(true);
  const [loadingComplete, setLoadingComplete] = useState(false);
  const [compDisplayName, setcompDisplayName] = useState('');


  useEffect(() => {

    const getUser = async () => {
      const userString = await AsyncStorage.getItem('user');
      const user = JSON.parse(userString);
      setUser(user);
      setLoadingComplete(true)
    };
 
    const getData = async () => {
      try {
        const selectedCoName = await AsyncStorage.getItem('selectedCo_name');
        const selectedCoTin = await AsyncStorage.getItem('selectedCo_Tin');
        setSelectedCo_name(selectedCoName);
        setSelectedCo_Tin(selectedCoTin);
      } catch (error) {
        console.log('Error retrieving data:', error);
      }
      setIsLoading(false)
    };

    getData();
    getUser();

    return () => clearInterval(intervalId);

  }, []);

  const SideMenuButton = ({ title, icon, onPress, screenName, selectedScreen }) => (
    <TouchableOpacity onPress={() => setSelectedScreen(screenName)} style={selectedScreen === screenName ? styles.selectedMenuButton : styles.menuButton}>
      {React.cloneElement(icon, { color: selectedScreen === screenName ? 'white' : 'grey' })}
      <Text style={[styles.menuText, selectedScreen === screenName && styles.selectedButton]}>{title}</Text>
    </TouchableOpacity>
  );

  const [selectedScreen, setSelectedScreen] = useState();
  const [isMenuVisible, setMenuVisible] = useState(false);
  const [testing, setTesting] = useState(false);

  const toggleMenu = () => {
    setMenuVisible(!isMenuVisible);
  };
  const renderScreenContent = () => {
    switch (selectedScreen) {
      case 'Dashboard':
        return <Dashboard />;
      case 'Freight':
        return <Freight />;
      case 'Appointment':
        return <Appointment />;
      case 'Cost':
        return <Expense />;
      case 'Commission':
        return <Commission />;
      case 'Invoice':
        return <Invoice />;
      case 'Income':
        return <Income />;
      case 'Penality':
        return <Penality />;
      case 'Advance':
        return <Loan />;
      case 'Payments':
        return <FinanceHome />;
      case 'Trucks':
        return <FinanceHome />;
      case 'Owner':
        return <FinanceHome />;
      case 'Driver':
        return <FinanceHome />;
      case 'Consignee':
        return <FinanceHome />;
      default:
        // return <Dashboard/>;
      return <Freight />; 
    }
  };

  const logout = async () => {
    await AsyncStorage.removeItem('user');
    await AsyncStorage.removeItem('token');
    setUser(null)
    AuthContext.setUser(null);
  };

  const renderMainMenu = () => {
    return (
      <FontLoader>
      <View style={styles.container}>
        {/* Header */}
        {/* <View style={styles.header}>
        <TouchableOpacity onPress={() => setSelectedScreen('screen4')}>
          <Text style={styles.WedajLogo}>WEDAJ LMS</Text>
        </TouchableOpacity>
        <TouchableOpacity onPress={() => console.log('Logout')}>
          <Text style={styles.menuText}>Logout</Text>
        </TouchableOpacity> 
        <Feather
          name="chevron-down"
          size={24}
          color="black"
          style={styles.dropdownIcon}
        />
        <ProfileDropdown isVisible={isMenuVisible} />
      </View> */}

        {/* Vertical Menu */}
        <View style={styles.Body}>
          <View style={styles.menu}>
            <View>
              <TouchableOpacity style={styles.WedajLogoContainer} onPress={() => setSelectedScreen('Dashboard')} screenName='Dashboard'>
                <Text style={styles.WedajLogo}>WEDAJ LMS</Text>
              </TouchableOpacity>
              <View style={{ backgroundColor: 'grey', height: 1, width: '100%', marginBottom: 2 }}></View>
              <SideMenuButton title="Dashboard" icon={<AntDesign name="dashboard" size={16} color={'white'} />} onPress={() => setSelectedScreen('Dashboard')} screenName='Dashboard' />
              <SideMenuButton title="Freight" icon={<MaterialCommunityIcons name="truck-delivery-outline" size={16} color={'white'}/>} onPress={() => setSelectedScreen('Freight')} screenName='Freight' />
              <SideMenuButton title="Appointment" icon={<AntDesign name="calendar" size={16} color={'white'} />} onPress={() => setSelectedScreen('Appointment')} screenName='Appointment' />
              {/* <SideMenuButton title="Cost" icon={<AntDesign name="calendar" size={16} color={'white'} />} onPress={() => setSelectedScreen('Cost')} screenName='Appointment' />
              <SideMenuButton title="Invoice" icon={<AntDesign name="calendar" size={16} color={'white'} />} onPress={() => setSelectedScreen('Invoice')} screenName='Invoice' />
              <SideMenuButton title="Income" icon={<AntDesign name="calendar" size={16} color={'white'} />} onPress={() => setSelectedScreen('Income')} screenName='Income' />
              <SideMenuButton title="Penalty" icon={<AntDesign name="calendar" size={16} color={'white'} />} onPress={() => setSelectedScreen('Penality')} screenName='Penality' />
              <SideMenuButton title="Advance" icon={<AntDesign name="calendar" size={16} color={'white'} />} onPress={() => setSelectedScreen('Advance')} screenName='Advance' /> */}
              <SideMenuButton title="Payments" icon={<MaterialCommunityIcons name="finance" size={16} color={'white'} />} onPress={() => setSelectedScreen('Payments')} screenName='Payments' />
              <SideMenuButton title="Trucks" icon={<MaterialCommunityIcons name="truck-plus-outline" size={16} color={'white'} />} onPress={() => setSelectedScreen('Trucks')} screenName='Trucks' />
              <SideMenuButton title="Owner" icon={<AntDesign name="profile" size={16} color={'white'} />} onPress={() => setSelectedScreen('Owner')} screenName='Owner' />
              <SideMenuButton title="Driver" icon={<MaterialCommunityIcons name="badge-account-horizontal-outline" size={16} color={'grey'} />} onPress={() => setSelectedScreen('Driver')} screenName='Driver' />
              <SideMenuButton title="Consignee" icon={<Feather name="users" size={16} color={'white'} />} onPress={() => setSelectedScreen('Consignee')} screenName='Consignee' />
            </View>
            <View>
              <TouchableOpacity style={styles.logoutButton} onPress={() => { logout() }}>
                {/* <AntDesign name="logout" size={16} color="grey" /> */}
                <MaterialIcons name="logout" size={17} color="grey" />
                <Text style={styles.logoutText}>Logout</Text>
              </TouchableOpacity>
              <Text style={styles.menuText}></Text>
              <Text style={styles.menuText}>{user ? user.userCompName : null}</Text>
            </View>
          </View>
          {/* Content Area .*/}
          <ScrollView style={styles.content}>{renderScreenContent()}</ScrollView>
        </View>
      </View>
      </FontLoader>
    )
  }
  if (isLoading) {
    return <SplashScreen />;
  }

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      {user ?
        <>
          {renderMainMenu()}
        </>
        : <FontLoader>
            <Landing />
          </FontLoader>}
    </AuthContext.Provider>
  );
  
}

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
    flex: 1,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 16,
    paddingVertical: 8,
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    // backgroundColor: 'green'
  },
  WedajLogoContainer: {
    backgroundColor: '#031D22',
    paddingVertical: 5,
    alignItems: 'center'
  },
  WedajLogo: {
    fontSize: 21,
    fontWeight: 600,
    color: colors.offwhite,
  },
  profileIcon: {
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  dropdownIcon: {
    marginLeft: 8,
  },
  dropdownMenu: {
    position: 'absolute',
    top: 40,
    right: 16,
    // backgroundColor: 'green',
    borderRadius: 8,
    elevation: 4,
    padding: 8,
  },
  menuItem: {
    paddingVertical: 8,
    backgroundColor: colors.Charcoal,
  },
  Body: {
    flex: 1,
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  menu: {
    //flex: 1,
    width: 135,
    backgroundColor: '#031D22',
    // backgroundColor:colors.primary,
    paddingLeft:2,
    justifyContent: 'space-between',
    paddingBottom: 30,
    // alignItems: 'center'
  },
  menuButton: {
    marginBottom: 4,
    padding: 10,
    backgroundColor: '#031D22',
    // backgroundColor:colors.primary,
    borderRadius: 4,
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 15,
  }, 
  selectedMenuButton: {
    // backgroundColor: '#1c3338'
    backgroundColor: 'orange',
    marginBottom: 4,
    padding: 8,
    borderRadius: 4,
    alignItems: 'center',
    flexDirection: 'row',
    paddingLeft: 15
  },
  selectedButton: {
    color: 'white',
  },
  logoutButton:{
    paddingBottom:40,
    flexDirection:'row',
    paddingLeft: 10,
  },
  logoutText: {
    fontWeight: 600,
    color: '#ACACAC',
    marginLeft:4
  },
  menuText: {
    fontWeight: 600,
    paddingLeft: 7,
    color: '#ACACAC',
    fontFamily:'Roboto-Light',
    //marginBottom:40
  },
  content: {
    flex: 1,
    paddingLeft: 1,
    width: '100%',
    height: SCREEN_HEIGHT,
  },
});

const App = () => {
  return (
    <NavigationContainer>
      <Stack.Navigator initialRouteName="Main" headerMode="none">
        <Stack.Screen name="Wedaj / ወዳጅ" component={MainScreen} />
        <Stack.Screen name="Freight" component={Freight} />
        <Stack.Screen name="Payments" component={FinanceHome} />
        <Stack.Screen name="Screen2" component={Trucks} />
        <Stack.Screen name="Screen3" component={Owner} />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default App;
