import client from './client'

const login = (email, password) => client.post('/login', { email, password }, { 'Access-Control-Allow-Origin': '*' })

const resetPass = (email, password) => client.post('/resetPass', { email, password }, { 'Access-Control-Allow-Origin': '*' })

export default {
    login,
    resetPass
}
