// AboutScreen.js
import React from 'react';
import { View, Text, Button } from 'react-native';

const Trucks = ({ navigation }) => {
    return (
        <View>
            <Text></Text>
        </View>
    );
};

export default Trucks;
