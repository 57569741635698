import React from 'react';

function FormattedDateFancy({ dateString }) {
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        date.setHours(date.getHours() + 2); // Adjusting the time if necessary

        const options = {
            month: 'long',
            day: 'numeric',
            year: 'numeric',
        };

        const formatter = new Intl.DateTimeFormat('en-US', options);
        const formattedDate = formatter.format(date);

        return formattedDate;
    };

    const formattedDate = formatDate(dateString);

    return <span>{formattedDate}</span>;
}

export default FormattedDateFancy;
