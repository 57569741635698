import React, { useState, useEffect } from 'react';
import { ScrollView, View, Text, StyleSheet, ActivityIndicator } from 'react-native';
// import { EvilIcons } from '@expo/vector-icons'; // Uncomment if you use icons
import FormattedDate from './FormattedDate';
import colors from '../config/colors'

const ActivitiesList = ({activities}) => {
    return (
        <ScrollView style={styles.activitiesTile}>
            {activities.map((activity, index) => (
                <View key={index} style={styles.activitiesTileCard}>
                    <View style={styles.activitiesUserIcon}>
                        {/* <EvilIcons name="user" size={45} color="black" /> */}
                    </View>
                    <View style={styles.activitiesNameandActionContainer}>
                        <View style={styles.activitiesNameContainer}>
                            <Text style={styles.activitiesText}>{activity.user_name},</Text>
                            <Text style={styles.roleText}> {activity.role_name}</Text>
                            {/* <Text style={styles.activitiesSubText}><FormattedDate dateString={activity.activity_date}/></Text> */}
                        </View>
                        <View style={styles.activitiesNameContainers}>
                            <Text style={styles.activitiesSubText}><FormattedDate dateString={activity.activity_date}/> {`${activity.activity_type_report}`}</Text>
                        </View>
                    </View>
                        {/* <View style={styles.activitiesActionContainer}>
                            <Text style={styles.activitiesSubText}><FormattedDate dateString={activity.activity_date}/></Text>
                        </View> */}
                </View>
            ))}
        </ScrollView>
    );
};
const styles = StyleSheet.create({
    activitiesTile:{
        height:'95%',
        width:'97%',
        backgroundColor:colors.offwhite2,
        // paddginTop:2,
        // paddingRight:2,
        // paddingBottom:2
        backgroundColor:colors.ForeGroundWhite,
        elevation:10,
        shadowRadius:.5,
        shadowColor:colors.primary,
        borderRadius:7,
        paddingTop:5,
        paddingBottom:5,
      },
    activitiesTileCard:{
        height:40,
        //flexDirection:'row',
        backgroundColor:colors.white,
        marginHorizontal:5,
        borderRadius:4,
        marginBottom:4,
    },
    activitiesUserIcon:{
        width:'1%',
        // width:'14%',
        alignItems:'center',
        justifyContent:'center',
        //backgroundColor:'white',
    },
    activitiesNameandActionContainer:{
        //flexDirection:'row',
        justifyContent:'space-between'
    },
    activitiesNameContainer:{
        flexDirection:'row',
        //justifyContent:'space-evenly'
    },
    activitiesText:{
        fontSize:16,
        fontWeight:'500',
        paddingBottom:3,
        color:colors.primary,
        fontFamily:'Roboto-Medium'
    },
    roleText:{
        fontSize:15,
        fontWeight:'400',
        paddingBottom:3,
        color:colors.primary
    },
    activitiesSubText:{
        fontSize:15,
        fontWeight:'400',
        color:colors.primary,
        fontFamily:'Roboto-Light'
    },
    activitiesActionContainer:{
    },

})
export default ActivitiesList