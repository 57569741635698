// PopupForm.js
import React, { useState, useEffect } from 'react';
import { View, Text, TextInput, Button, StyleSheet, Alert, Picker } from 'react-native';
import Modal from 'react-native-modal';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import colors from '../config/colors'
import FormattedDate from '../components/FormattedDate';
import FormattedDateNoHR from '../components/FormattedDateNoHR';
import FormattedDateFormal from '../components/FormattedDateFormal';

const EditFOForm = ({ isVisible, onClose, onUpdate, foData }) => {

    const [idfo, setidFO] = useState('');
    const [oldfo, setOldFO] = useState('');
    const [fo, setFO] = useState('');
    const [truck, setTruck] = useState('');
    const [grossWeight, setgrossWeight] = useState('');
    const [orderDeliveryAgreementDate, setOrderDeliveryDate] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [origin, setOrigin] = useState('');
    const [destination, setDestination] = useState('');
    const [tariff, setTariff] = useState('');
    const [consignee, setConsignee] = useState('');
    const [orderGenerator, setOrderGenerator] = useState('Company')
    const [remark, setRemark] = useState('');
    const [commodity, setCommodity] = useState('');
    const [container, setContainer] = useState('');
    const [isDatePickerVisible, setDatePickerVisible] = useState(false);

    const [incomingDeliveryDate, setIncomingDeliveryDate] = useState(null);
    const [incomingStartDate, setIncomingStartDate] = useState(null);

    const [newDeliveryDate, setNewDeliveryDate] = useState(null);
    const [newStartDate, setNewStartDate] = useState(null);

    //change container to uppercase
    const handleChangeText =(newText) =>{
        setContainer(newText.toUpperCase())
    }

    useEffect(() => {
        if (foData) {
            console.log("Is remark" + foData.orderRemark)
            setIncomingDeliveryDate(foData.orderDeliveryAgreementDate || '')
            setIncomingStartDate(foData.orderDeparture || '')

            setFO(foData.idfreightOrder || '');
            setOldFO(foData.idfreightOrder || '');//for the use of a query 

            setTruck(foData.truck_plate || '');
            setgrossWeight(foData.orderWeight || '');

            setOrderDeliveryDate(foData.orderDeliveryAgreementDate || null);
            setStartDate(foData.orderDeparture || null);

            setOrigin(foData.origin_id);
            setDestination(foData.destination_id);

            setTariff(foData.orderTariff || '');
            setConsignee(foData.cus_name || '');
            setOrderGenerator(foData.orderGenerator || 'Company');
            setRemark(foData.orderRemark || '');
            setCommodity(foData.orderCommodity || '');
            setContainer(foData.orderContainer || '');
        }
    }, [foData]);

    // ... other code


    const showDatePicker = () => {
        setDatePickerVisible(true);
    };

    const hideDatePicker = () => {
        setDatePickerVisible(false);
    };

    const handleConfirm = (date) => {
        setOrderDeliveryDate(date);
        hideDatePicker();
    };
    let sDate = newStartDate ? newStartDate : startDate;
    let dDate = newDeliveryDate ? newDeliveryDate : orderDeliveryAgreementDate;

    const handleUpdate = () => {
        // Validate and submit the form data
        // You can add your validation logic here
        // Call the onSubmit prop with the form data
        onUpdate({
            oldfo,
            fo,
            sDate,
            dDate,
            consignee,
            truck,
            grossWeight,
            tariff,
            origin,
            commodity,
            container,
            destination,
            orderGenerator,
            remark
        });
        // Close the modal
        onClose();
    };

    return (
        <Modal isVisible={isVisible} onBackdropPress={onClose} >
            <View style={styles.modalContainer}>
                <Text style={styles.modalTitle}>Edit Freight Order {fo}</Text>
                {/* <FormattedDateFormal formattedDateString={startDate} />
                <Text>{incomingDeliveryDate}</Text>
            <Text>{startDate}</Text> */}
                {/* <Text>{incomingStartDate}</Text> */}
                <View style={styles.modalRow}>
                    <TextInput
                        style={styles.input}
                        placeholder={fo ? "Freight Order: " + fo : "Freight order No."}
                        onChangeText={setFO}
                    />
                    <TextInput
                        style={styles.input}
                        placeholder={truck ? "Truck: " + truck : "Truck"}
                        onChangeText={setTruck}
                    />
                    <TextInput
                        style={styles.input}
                        placeholder={grossWeight ? "Weight: " + grossWeight : "Weight"}
                        onChangeText={setgrossWeight}
                    />
                    <TextInput
                        style={styles.input}
                        placeholder={tariff ? "Tarrif: " + tariff : "Tarrif"} onChangeText={setTariff}
                        onChange={setTariff}
                    />
                    <TextInput
                        style={styles.input}
                        value={container}
                        placeholder={container ? "Container: " + container : "Container"}
                        onChangeText={handleChangeText}
                    />
                </View>

                <View style={styles.modalRow}>
                    <Picker
                        origin={origin}
                        style={{ height: 30, width: 160, marginLeft: 2, borderColor: 'skyblue', borderRadius: 5, fontSize: 14.2 }}
                        onValueChange={(itemValue, itemIndex) => setOrigin(itemValue)}
                    >
                        <Picker.Item label={`From`} value="" />
                        <Picker.Item label="Addis Abeba" value="1" />
                        <Picker.Item label="Mojo" value="2" />
                        <Picker.Item label="Djibouti" value="3" />
                        <Picker.Item label="Bahirdar" value="4" />
                        <Picker.Item label="Adama" value="5" />
                        <Picker.Item label="Dukem" value="6" />
                        <Picker.Item label="Yirgalem" value="7" />
                        <Picker.Item label="Mekele" value="8" />
                        <Picker.Item label="Bole Lemi" value="9" />
                        <Picker.Item label="Gelan" value="10" />
                        <Picker.Item label="Jijiga" value="11" />
                        <Picker.Item label="Deredewa" value="12" />
                        <Picker.Item label="Komet" value="13" />
                        <Picker.Item label="Afar" value="14" />
                        <Picker.Item label="Welenchiti" value="15" />
                        <Picker.Item label="Legetafo" value="16" />
                        <Picker.Item label="Sululta" value="17" />
                        <Picker.Item label="Kombolcha" value="18" />
                        <Picker.Item label="Abay" value="19" />
                        <Picker.Item label="Hawassa" value="20" />
                        <Picker.Item label="Zeway" value="21" />
                        <Picker.Item label="Debrebirhan" value="22" />
                        <Picker.Item label="Gese" value="23" />
                        <Picker.Item label="Tulufa" value="24" />
                        <Picker.Item label="Kayish" value="25" />
                        <Picker.Item label="Welkite" value="26" />
                        <Picker.Item label="Mendid" value="27" />
                        <Picker.Item label="Asela" value="28" />
                        <Picker.Item label="Gambela" value="29" />
                        <Picker.Item label="Welayita" value="30" />
                        <Picker.Item label="Semera" value="31" />
                        <Picker.Item label="Koyish" value="32" />
                        <Picker.Item label="Sebeta" value="33" />
                        <Picker.Item label="Woreta" value="34" />
                        <Picker.Item label="Desae" value="35" />
                        <Picker.Item label="Arbaminch" value="36" />
                        <Picker.Item label="Ginche" value="37" />
                        <Picker.Item label="Shashemene" value="38" />
                        <Picker.Item label="Asosa" value="39" />
                        <Picker.Item label="Sendafa" value="40" />
                        <Picker.Item label="Durame" value="41" />
                        <Picker.Item label="Arerti" value="42" />
                        <Picker.Item label="Koyisha" value="43" />
                    </Picker>
                    <Picker
                        destination={destination}
                        style={{ height: 30, width: 160, marginLeft: 2, borderColor: 'skyblue', borderRadius: 5, fontSize: 14.2 }}
                        onValueChange={(itemValue, itemIndex) => setDestination(itemValue)}
                    >
                        <Picker.Item label={`To`} value="" />
                        <Picker.Item label="Addis Abeba" value="1" />
                        <Picker.Item label="Mojo" value="2" />
                        <Picker.Item label="Djibouti" value="3" />
                        <Picker.Item label="Bahirdar" value="4" />
                        <Picker.Item label="Adama" value="5" />
                        <Picker.Item label="Dukem" value="6" />
                        <Picker.Item label="Yirgalem" value="7" />
                        <Picker.Item label="Mekele" value="8" />
                        <Picker.Item label="Bole Lemi" value="9" />
                        <Picker.Item label="Gelan" value="10" />
                        <Picker.Item label="Jijiga" value="11" />
                        <Picker.Item label="Deredewa" value="12" />
                        <Picker.Item label="Komet" value="13" />
                        <Picker.Item label="Afar" value="14" />
                        <Picker.Item label="Welenchiti" value="15" />
                        <Picker.Item label="Legetafo" value="16" />
                        <Picker.Item label="Sululta" value="17" />
                        <Picker.Item label="Kombolcha" value="18" />
                        <Picker.Item label="Abay" value="19" />
                        <Picker.Item label="Hawassa" value="20" />
                        <Picker.Item label="Zeway" value="21" />
                        <Picker.Item label="Debrebirhan" value="22" />
                        <Picker.Item label="Gese" value="23" />
                        <Picker.Item label="Tulufa" value="24" />
                        <Picker.Item label="Kayish" value="25" />
                        <Picker.Item label="Welkite" value="26" />
                        <Picker.Item label="Mendid" value="27" />
                        <Picker.Item label="Asela" value="28" />
                        <Picker.Item label="Gambela" value="29" />
                        <Picker.Item label="Welayita" value="30" />
                        <Picker.Item label="Semera" value="31" />
                        <Picker.Item label="Koyish" value="32" />
                        <Picker.Item label="Sebeta" value="33" />
                        <Picker.Item label="Woreta" value="34" />
                        <Picker.Item label="Desae" value="35" />
                        <Picker.Item label="Arbaminch" value="36" />
                        <Picker.Item label="Ginche" value="37" />
                        <Picker.Item label="Shashemene" value="38" />
                        <Picker.Item label="Asosa" value="39" />
                        <Picker.Item label="Sendafa" value="40" />
                        <Picker.Item label="Durame" value="41" />
                        <Picker.Item label="Arerti" value="42" />
                        <Picker.Item label="Koyisha" value="43" />
                    </Picker>
                    <Picker
                        orderGenerator={orderGenerator}
                        style={{ height: 30, width: 180, marginHorizontal: 2, borderColor: 'skyblue', borderRadius: 5, fontSize: 14.2 }}
                        onValueChange={(itemValue, itemIndex) => setOrderGenerator(itemValue)}
                    >
                        <Picker.Item label={`Order Generator: ${orderGenerator}`} value="" />
                        <Picker.Item label="Company" value="Company" />
                        <Picker.Item label="Other" value="Other" />
                    </Picker>
                    <Picker
                        consignee={consignee}
                        style={{ height: 30, width: 130, marginLeft: 2, borderColor: 'skyblue', borderRadius: 5, fontSize: 14.2 }}
                        onValueChange={(itemValue, itemIndex) => setConsignee(itemValue)}
                    >
                        <Picker.Item label="Owner" value="" />
                        <Picker.Item label="ATD" value="ATD" />
                        <Picker.Item label="Mahiber" value="Mahiber" />
                        <Picker.Item label="Negade" value="Negade" />
                    </Picker>
                    <TextInput
                        style={styles.input}
                        placeholder={commodity ? "Commodity: " + commodity : "Commodity"}
                        onChangeText={setCommodity}
                    />
                </View>
                <View style={styles.modalRowDate}>
                    <View style={styles.datePickerContainer}>
                        <DatePicker
                            selected={newStartDate}
                            onChange={(date) => setNewStartDate(date)}
                            // showTimeSelect
                            placeholderText={`Exit Date :${incomingStartDate}`}
                            dateFormat="Pp"
                        />

                    </View>
                    <View style={styles.deliveryDatePickerContainer}>
                        <DatePicker
                            borderRadius={5}
                            height={35}
                            selected={newDeliveryDate}
                            onChange={(date) => setNewDeliveryDate(date)}
                            // showTimeSelect
                            placeholderText={`Delivery Date :${incomingDeliveryDate}`}
                            dateFormat="Pp"
                        />
                    </View>
                    <TextInput
                        style={styles.remarkInput}
                        placeholder={remark ? "Remark: " + remark : "Remark"}
                        onChangeText={setRemark}
                    />
                </View>

                {/* Add other input fields */}
                <View style={styles.buttonContainer}>
                    <Button title="Update" onPress={handleUpdate} />
                    <Button title="Cancel" onPress={onClose} />
                </View>
            </View>
        </Modal>
    );
};

const styles = StyleSheet.create({
    modalContainer: {
        backgroundColor: 'white',
        padding: 8,
        borderRadius: 8,
        width: '65%',
        height: '60%',
        alignSelf: 'center'
    },
    modalRow: {
        flexDirection: 'row',
        margin: 2,
        marginTop: 15,
        // alignItems: 'space-between',
        justifyContent: 'space-between'
    },
    modalRowDate: {
        flexDirection: 'row',
        margin: 4,
        marginTop: 15,
        // alignItems: 'space-between',
        // justifyContent: 'space-between'
    },
    datePickerContainer: {
        //height: 30,
        borderColor: 'gray',
        //borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 1,
        // marginLeft: 3,
        alignContent: 'center',
        justifyContent: 'center',
    },
    deliveryDatePickerContainer: {
        height: 35,
        borderColor: 'gray',
        //borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 1,
        marginLeft: 3,
        marginRight: 4,
        alignContent: 'center',
        justifyContent: 'center',
    },
    modalTitle: {
        fontSize: 18,
        fontWeight: 'bold',
        marginBottom: 16,
    },
    input: {
        height: 30,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        width: 160
    },
    remarkInput: {
        height: 30,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        width: 300
    },
    dateInputCotnainer: {
        // innerHeight: 40
    },
    buttonContainer: {
        width: 200,
        alignSelf: 'flex-end',
        justifyContent: 'space-evenly',
        flexDirection: 'row',
        marginVertical: 10
    },
    placeholderText: {
        color: '#ccc', // Placeholder text color
        marginTop: 8,
    },
});

export default EditFOForm;
