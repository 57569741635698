import React, { useState, useRef, useContext, useEffect } from 'react';
import { View, FlatList, TouchableOpacity, Text, StyleSheet, TextInput, RefreshControl, ActivityIndicator, Button, Dimensions, Alert, Window, Platform } from 'react-native';
// import AppCanvas from '../config/AppCanvas';
// import Buttons from "../components/Buttons";
import FormattedDate from '../components/FormattedDate';
import FormattedDateNoHR from '../components/FormattedDateNoHR';
import MoneyFormat from '../components/MoneyFormat';
import * as Print from 'expo-print';
// import { shareAsync } from 'expo-sharing';
// import { Print } from 'expo';
import FOLister from '../components/FOLister';
import colors from '../config/colors';
import { Feather } from '@expo/vector-icons';
import AuthContext from '../auth/context';
import services from '../api/services';
import AddFOForm from './AddFOForm';
// import MapComponent from '../components/MapComponent';
import EditFOForm from './EditFOForm'
import HomeScreen from './HomeScreen';
import { AntDesign } from '@expo/vector-icons';
// import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import PrintableComponent from '../components/PrintableComponent'
import "rsuite/dist/rsuite.min.css";
import { DatePicker } from "rsuite";
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
const SCREEN_HEIGHT = Dimensions.get('window').height;

export default function Invoice({ navigation }) {

    const { user, setUser, selectedCo_name, setSelectedCo_name, selectedCo_Tin, setSelectedCo_Tin } = useContext(AuthContext)

    const [fOrders, setFOrders] = useState([])
    const [refreshing, setRefreshing] = useState(true);

    const [freightOrdersPerTruck, setFreightOrdersPerTruck] = useState('')
    const [gettingTruckHistory, setGettingTruckHistory] = useState(false)
    const [selectedTruck, setSelectedTruck] = useState(false)

    const UserID = user.userId
    const coTin = user.userCompTin
    const compInUse = user.userCompName

    useEffect(() => {
        const timeoutId = setInterval(() => { }, 2000);
        getFO()
        getInvoices()
        const currentDate = new Date();
        const oneMonthFromNow = new Date();
        oneMonthFromNow.setMonth(currentDate.getMonth() + 1);
        setFromDate(oneMonthFromNow);
        return () => clearInterval(timeoutId);
    }, [coTin]);

    const getFO = async () => {
        try {
            const response = await services.getFreightForInvoice(coTin);
            if (response.status === 200) {
                setFOrders(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };


    const searchFO = async (newinput) => {
        try {
            const response = await services.searchFOForView(coTin, newinput);
            if (response.status === 200) {
                setFOrders(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    }

    const [isFormVisible, setFormVisible] = useState(false);
    const [isEditFormVisible, setEditFormVisible] = useState(false);

    const handleAddFOForm = () => {
        setFormVisible(true);
    };
    const handleEditForm = () => {
        setEditFormVisible(true)
    }
    const handleCloseForm = () => {
        setFormVisible(false);
        setFO('');
        setTruck('');
        setgrossWeight('');
        setOrderDeliveryDate(null);
        setFromDate(null);
        setOrigin('Djibouti');
        setDestination('Addis Abeba');
        setTariff('');
        setConsignee('');
        setOrderGenerator('Company')
        setRemark('');
    }

    const UpdateFO = (item) => {
        // navigation.navigate('Edit', { item });
        console.log(item)
    };

    const getFODetail = (item) => {
        console.log(item)
    }

    const getTruckFreightHistory = async (item) => {
        setGettingTruckHistory(true)
        setSelectedTruck(item)
        try {
            const response = await services.getFOForTruckHistory(coTin, item);
            if (response.status === 200) {
                setFreightOrdersPerTruck(response.data);
                setGettingTruckHistory(false);
                console.log(freightOrdersPerTruck)
            } else {
                console.error(`Error: ${response.status}`);
                setGettingTruckHistory(false);
            }
        } catch (error) {
            console.error(error);
            setGettingTruckHistory(false);
        }
        console.log(freightOrdersPerTruck)
    };
    const renderHeader = () => (
        <View style={Styles.tableRow}>
            <Text style={Styles.tableCellHeader}>From</Text>
            <Text style={Styles.tableCellHeader}>To</Text>
            <Text style={Styles.tableCellHeader}>Owner</Text>
            <Text style={Styles.tableCellHeader}>Freight No.</Text>
            <Text style={Styles.tableDateCellHeader}>Departure</Text>
            <Text style={Styles.tableDateCellHeader}>Arrival</Text>
            {/* <Text style={Styles.tableCellHeader}>Truck</Text> */}
            {/* <Text style={Styles.tableCellHeader}>Trailer</Text> */}
            <Text style={Styles.tableCellHeader}>Tariff</Text>
            {/* <Text style={Styles.tableCellHeader}>Weight</Text> */}
            {/* <Text style={Styles.tableCellHeader}>Penality</Text>
            <Text style={Styles.tableCellHeader}>Reason</Text> */}
            <Text style={Styles.tableCellHeader}>Status</Text>
        </View>
    );
    const [selectedScreen, setSelectedScreen] = useState(null);

    const renderScreenContent = () => {
        switch (selectedScreen) {
            case 'truckTable':
                return renderTruckTable();
            case 'Appointment':
                return renderAppointment();
            case 'Loan':
                return renderLoan();
            case 'Penality':
                return renderPenality();
            case 'Income':
                return renderIncome();
            case 'Invoice':
                return renderInvoive();
            case 'Expense':
                return renderExpense();
            default:
                return renderAppointment();
            // return <HomeScreen />;
        }
    };
    const renderTruckTable = () => (
        <View style={Styles.truckDetailContainer}>
            <View style={Styles.mainformContainer}> {/* title container */}
                <Text style={Styles.modalTitle}>{selectedTruck ? <View style={Styles.loadedModalTitle}>Deployment History For Truck {selectedTruck}</View> : <>Deployment History</>}</Text>
                {/* <View style={Styles.twoColContainer}>
                            <View style={Styles.truckDetailLeft}>

                            </View>
                            <View style={Styles.truckDetailRight}>

                            </View>
                        </View> */}
            </View>
            <View style={Styles.tableHeader}>
                {renderHeader()}
            </View>
            <FlatList
                data={freightOrdersPerTruck}
                keyExtractor={(item) => item.idfreightOrder}
                renderItem={renderItem}
            />
        </View>
    );
    const renderExpense = () => (UserID == 1 ? <View><Text>Expense</Text></View> : <View><Text>Unauthorized</Text></View>)
    const renderInvoive = () => (<View><Text>Invoice</Text></View>)
    const renderIncome = () => (<View><Text>Income</Text></View>)
    const renderPenality = () => (<View><Text>Penality</Text></View>)
    const renderLoan = () => (<View><Text>Loan</Text></View>)

    // const printableRef = useRef();



    //---------------------------------------APPOINTMENTS---------------------------------------------

    const [searchingCustomer, setSearchingCustomer] = useState(null);
    const [searchingFO, setSearchingFO] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);

    const foInputRef = useRef('');
    const amountInputRef = useRef('');
    const invoiceInputRef = useRef('');
    const customerInputRef = useRef('');
    const AddToInvoiceAmountInputRef = useRef('');
    const AddToInvoiceFOInputRef = useRef('');
    const AppoSearchInputRef = useRef('');
    const ExpenseSearchInputRef = useRef('');

    const [appointments, setAppointments] = useState('')
    const [deletingApp, setdeletingApp] = useState(false)
    const [paidExpenses, setPaidExpenses] = useState('')

    const getAppointmentsForExpense = async () => {
        try {
            const response = await services.getAppointmentsForExpense(coTin);
            if (response.status === 200) {
                setAppointments(response.data);
                // console.log(response.data)
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    const clearForm = () => {
        foInputRef.current.clear()
        amountInputRef.current.clear()
        // voucherInputRef.current.clear()
        // reasonInputRef.current.clear()
    }

    const searchCustomerInputRef = useRef('');
    const searchingFOInputRef = useRef('');

    const clearRangeForm = () => {
        getFO()
        searchCustomerInputRef.current.clear();
        searchingFOInputRef.current.clear();
        setSearchingCustomer(null);
        setSearchingFO(null);
        setFromDate(null);
        setToDate(null);
    }

    const clearInvoice = () => {
        customerInputRef.current.clear()
        invoiceInputRef.current.clear()
        getInvoices()
    }
    const clearAddToInvoice = () => {
        AddToInvoiceFOInputRef.current.clear()
        AddToInvoiceAmountInputRef.current.clear()
        // setSelectedInvoice('')
        // setInvoiceItemsList('')
    }

    const [invoice, setInvoice] = useState('')
    const [customer, setCustomer] = useState('')
    const [addingInvoice, setaddingInvoice] = useState(false)
    const [invoiceList, setInvoiceList] = useState(false)

    const [invoiceItemsList, setInvoiceItemsList] = useState('')

    const addInvoice = async () => {
        if (invoice && customer) {
            try {
                setaddingInvoice(true);
                const response = await services.postNewInvoice(invoice, customer, coTin, UserID);
                if (response.status === 200) {
                    clearInvoice();
                    setInvoice('')
                    setCustomer('')
                    setaddingInvoice(false);
                    getInvoices();
                    window.alert("Invoice Posted.");
                } else {
                    setaddingInvoice(false);
                    window.alert("Error posting Invoice. Please try again.");
                    console.error(`Error: ${response.status}`);
                }
            } catch (error) {
                console.error(error);
                setaddingInvoice(false);
                clearForm();
            }
        } else {
            window.alert("Please fill all the forms.");
        }
    };

    const getInvoices = async () => {
        try {
            const response = await services.getInvoices(coTin);
            if (response.status === 200) {
                setInvoiceList(response.data);
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    const [FOAddToInvoice, setFOAddToInvoice] = useState('')
    const [amountAddToInvoice, setAmountAddToInvoice] = useState('')
    const [addingToInvoice, setAddingToInvoice] = useState(false)

    const [downloadingInvoice, setDownloadingInvoice] = useState(false)

    const addToInvoice = async () => {
        let invoiceid = selectedInvoice.InvoiceID
        if (selectedInvoice.InvoiceID) {
            try {
                setAddingToInvoice(true)
                const response = await services.addToInvoice(FOAddToInvoice, amountAddToInvoice, invoiceid, coTin, UserID);
                if (response.status === 200) {
                    clearAddToInvoice()
                    setAddingToInvoice(false);
                    getInvoiceItems(selectedInvoice.InvoiceID)
                    // getFO()
                } else {
                    setAddingToInvoice(false);
                    window.alert("Error posting to invoice. Please try again.")
                    console.error(`Error: ${response.status}`);
                }
            } catch (error) {
                console.error(error);
                setAddingToInvoice(false);
            }
        } else {
            window.alert("Please select Invoice before adding Invoice items.")
        }
    };


    const [fo, setFO] = useState('')
    const [amount, setAmount] = useState('')
    const [voucher, setVoucher] = useState('')
    const [reason, setReason] = useState('Delivery')

    const [addingExpense, setaddingExpense] = useState(false)
    const [selectedInvoice, setSelectedInvoice] = useState('')

    const searchExpenses = async (input) => {
        try {
            if (!input || input.trim() === '') {
                return;
            }
            const response = await services.searchExpensesPosted(coTin, input);
            if (response.status === 200) {
                setPaidExpenses(response.data);
                console.log(response.data)
                setRefreshing(false);
            } else {
                console.error(`Error: ${response.status}`);
                setRefreshing(false);
            }
        } catch (error) {
            console.error(error);
            setRefreshing(false);
        }
    };

    const listFO = ({ item, index }) => {

        const calculateBackgroundColor = (index) => {
            const isEven = index % 2 === 0;
            const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
            return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
        };
        return (<>
            {/* <PrintableComponent ref={printableRef} item={item} /> */}
            <View style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }}>
                <Text style={Styles.tableCell}>{item.idfreightOrder}</Text>
                <Text style={Styles.tableCell}>{item.truck_plate}</Text>
                <Text style={Styles.tableCell}>{item.cus_name.substring(0, 8)}</Text>
                <Text style={Styles.tableCell}>{item.orderDeparture ? <FormattedDateNoHR dateString={item.orderDeparture} /> : null}</Text>
                <Text style={Styles.tableCell}>{item.orderUnloaingComplete ? <FormattedDateNoHR dateString={item.orderUnloaingComplete} /> : null}</Text>
                {/* <Text style={Styles.tableCell}>{item.incoming_payment_made ? <MoneyFormat amount={item.incoming_payment_made} /> : null}</Text> */}
                <Text style={Styles.tableCell}>{item.orderStatus}</Text>
                {/* <View style={Styles.tableCellIcon}>{deletingApp ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="delete" size={14} color="red" onPress={() => deleteAppointment(item.idfreightOrder)} />}</View> */}
                {/* <View style={Styles.tableCellIcon}>{deletingApp ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="printer" size={14} color={colors.primary} onPress={() => printToFile(item)} />}</View> */}
            </View>
        </>)
    };

    const listInvoice = ({ item, index }) => {

        const calculateBackgroundColor = (index) => {
            const isEven = index % 2 === 0;
            const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
            return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
        };
        return (<>
            {/* <PrintableComponent ref={printableRef} item={item} /> */}
            <TouchableOpacity style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }} onPress={() => assignAndGet(item)}>
                {/* <Text style={Styles.tableCell}>{item.InvoiceID}</Text> */}
                <Text style={Styles.tableCell}>{item.InvoiceNumber}</Text>
                <Text style={Styles.tableCell}>{item.customer.substring(0, 8)}</Text>
                <Text style={Styles.tableCell}>{item.addedDate ? <FormattedDateNoHR dateString={item.addedDate} /> : null}</Text>
                {/* <Text style={Styles.tableCell}>{item.orderUnloaingComplete ? <FormattedDateNoHR dateString={item.orderUnloaingComplete} /> : null}</Text>
                <Text style={Styles.tableCell}>{item.incoming_payment_made ? <MoneyFormat amount={item.incoming_payment_made} /> : null}</Text>
            <Text style={Styles.tableCell}>{item.orderStatus}</Text> */}
                {/* <View style={Styles.tableCellIcon}>{deletingApp ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="delete" size={14} color="red" onPress={() => deleteAppointment(item.idfreightOrder)} />}</View> */}
                <View style={Styles.tableCellIcon}>{deletingInvoice ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="delete" size={14} color={colors.red} onPress={() => deleteInvoice(item)} />}</View>
            </TouchableOpacity>
        </>)
    };

    const [deletingInvoice, setDeletingInvoice] = useState(false)

    const deleteInvoice = async (item) => {
        let invoiceId = item.InvoiceID
        try {
            const confirmed = window.confirm(`Are you sure you want to delete Invoice: ${item.InvoiceNumber}?\nItems registered under it will be lost.`);
            if (!confirmed) {
                return;
            }
            setDeletingInvoice(true);
            const response = await services.deleteInvoice(invoiceId, coTin, UserID);
            if (response.status === 200) {
                getInvoices();
                window.alert("Invoice deleted.");
                setDeletingInvoice(false);
            } else {
                setDeletingInvoice(false);
                window.alert("Error deleting invoice. Please try again.");
                console.error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            setDeletingInvoice(false);
        }
    };
    const [searchFo4Invoice, setsearchFo4Invoice] = useState(false)

    const findFOForInvoice = async (item) => {
        setsearchFo4Invoice(true)
        try {
            if (searchingCustomer) {
                if (fromDate && toDate) {
                    const response = await services.searchFOForInvoiceWithDate(coTin, searchingFO, searchingCustomer, fromDate, toDate);
                    if (response.status === 200) {
                        setFOrders(response.data);
                        setsearchFo4Invoice(false);
                    } else {
                        console.error(`Error: ${response.status}`);
                        setsearchFo4Invoice(false);
                    }
                }
                else {
                    const response = await services.searchFOForInvoice(coTin, searchingFO, searchingCustomer);// has limit to 15 because there are too many FO under one customer.
                    if (response.status === 200) {
                        setFOrders(response.data);
                        setsearchFo4Invoice(false);
                    } else {
                        console.error(`Error: ${response.status}`);
                        setsearchFo4Invoice(false);
                    }
                }
            } else { window.alert("Please add customer name to search."); setsearchFo4Invoice(false); }
        } catch (error) {
            console.error(error);
            setsearchFo4Invoice(false);
        }
    }

    const assignAndGet = (item) => {
        setSelectedInvoice(item)
        getInvoiceItems(item.InvoiceID)
    }

    const [gettingInvoiceItems, setGettingInvoiceItems] = useState('false')

    const getInvoiceItems = async (invoiceId) => {
        try {
            setGettingInvoiceItems(true);
            const response = await services.getInvoiceItems(invoiceId, coTin);
            if (response.status === 200) {
                setInvoiceItemsList(response.data);
                setGettingInvoiceItems(false);
            } else {
                console.error(`Error: ${response.status}`);
                setGettingInvoiceItems(false);
            }
        } catch (error) {
            console.error(error);
            setGettingInvoiceItems(false);
        }
    };

    const [deletingInvoiceItem, setDeletingInvoiceItem] = useState(false)

    const deleteInvoiceItems = async (item) => {
        try {
            const confirmed = window.confirm(`Are you sure you want to delete item: ${item}?`);
            if (!confirmed) {
                return;
            }
            setDeletingInvoiceItem(true);
            const response = await services.deleteInvoiceItems(item, coTin, UserID);
            if (response.status === 200) {
                getInvoiceItems();
                window.alert("Invoice Item deleted.");
                setDeletingInvoiceItem(false);
            } else {
                setDeletingInvoiceItem(false);
                window.alert("Error deleting invoice item. Please try again.");
                console.error(`Error: ${response.status}`);
            }
        } catch (error) {
            console.error(error);
            setDeletingInvoiceItem(false);
        }
    };

    const listInvoiceItems = ({ item, index }) => {

        const calculateBackgroundColor = (index) => {
            const isEven = index % 2 === 0;
            const shade = isEven ? 20 : 40; // Adjust the shade value based on your preference
            return `rgb(240, 240, 240, ${shade / 100})`; // Using RGB with alpha for transparency
        };
        return (<>
            <View style={{ ...Styles.tableRow, backgroundColor: calculateBackgroundColor(index) }}>
                <Text style={Styles.tableCell}>{index + 1}</Text>
                <Text style={Styles.tableCell}>{item.id_app ? item.id_app : null}</Text>
                <Text style={Styles.tableCell}>{item.idfreightOrder}</Text>
                <Text style={Styles.tableCell}>{item.truck_plate}</Text>
                <Text style={Styles.tableCell}>{item.customer.substring(0, 12)}</Text>
                <Text style={Styles.tableCell}>{item.origin}</Text>
                <Text style={Styles.tableCell}>{item.destination}</Text>
                <Text style={Styles.tableCell}>{item.orderWeight}</Text>
                <Text style={Styles.tableCell}>{item.orderTariff}</Text>
                <Text style={Styles.tableCell}>{item.invoiceItem_Amount ? <MoneyFormat amount={item.invoiceItem_Amount} /> : null}</Text>
                <View style={Styles.tableCellDelete}>{deletingInvoiceItem ? <ActivityIndicator size={14} color={colors.primary} /> : <AntDesign name="delete" size={14} color="red" onPress={() => deleteInvoiceItems(item.invoiceItemID)} />}</View>
            </View>

        </>)
    };

    const downloadInvoiceItems = async () => {
        // Create a worksheet with custom headers
        const headers = [
            'No.',
            'Plate No.',
            'Freight Order No',
            // 'Owner',
            'Origin',
            'Destination',
            'Commodity',
            'Quintal',
            'Container No.',
            'Rate',
            'Birr',
            'Appointment ID',
        ];

        const data = invoiceItemsList.map((item, index) => [
            index + 1,
            item.truck_plate,
            item.idfreightOrder,
            // item.customer,
            item.origin,
            item.destination,
            item.orderCommodity,
            item.orderWeight,
            item.orderContainer,
            item.orderTariff,
            item.invoiceItem_Amount,
            item.id_app,
        ]);

        // Create a worksheet with custom headers and data
        const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);

        // Create a workbook
        const wb = XLSX.utils.book_new();

        // Add the worksheet to the workbook
        XLSX.utils.book_append_sheet(wb, worksheet, 'Invoice');

        // Generate a Blob
        const wbout = XLSX.write(wb, {
            bookType: 'xlsx',
            type: 'array',
        });

        const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

        const filename = selectedInvoice ? `${selectedInvoice.InvoiceNumber}.xlsx` : 'invoice.xlsx';

        saveAs(blob, filename);
    };

    return (
        <View style={Styles.mainContainer}>
            <View style={Styles.topContainer}>
                <View style={Styles.topLeftContainer}>
                    <View style={Styles.mainformContainer}> {/* title container */}
                        <View style={Styles.modalTitleContainer}><Text style={Styles.modalTitle}>Freight Orders</Text></View>
                        <View style={Styles.formContainer}>
                            <TextInput
                                ref={searchingFOInputRef}
                                style={Styles.inputsmall}
                                placeholder="Freight Order"
                                onChangeText={setSearchingFO}
                            />
                            <TextInput
                                ref={searchCustomerInputRef}
                                style={Styles.inputsmall}
                                placeholder="Customer"
                                onChangeText={setSearchingCustomer}
                            />
                            <DatePicker
                                style={Styles.datepickerStyle}
                                appearance="default"
                                size='sm'
                                showicon='true'
                                defaultValue={null}
                                selected={fromDate}
                                onChange={(date) => setFromDate(date)}
                                // showTimeSelect
                                placeholder='From Date'
                                dateformat="Pp"
                                cleanable
                            />
                            <DatePicker
                                style={Styles.datepickerStyle}
                                appearance="default"
                                size='sm'
                                showicon='true'
                                defaultValue={null}
                                selected={toDate}
                                onChange={(date) => setToDate(date)}
                                // showTimeSelect
                                placeholder='To Date'
                                cleanable
                                dateformat="Pp"
                            />
                            <TouchableOpacity style={Styles.buttonContainer} onPress={clearRangeForm}>
                                <Text style={Styles.buttonText}>Clear</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={Styles.buttonContainer} onPress={findFOForInvoice}>
                                {searchFo4Invoice ? <ActivityIndicator size="small" color={colors.white} /> : <Text style={Styles.buttonText}>Search</Text>}
                            </TouchableOpacity>
                        </View>
                    </View>

                    <View style={Styles.tableHeader}>
                        <View style={Styles.tableRow}>
                            <Text style={Styles.tableCellHeader}>Freight</Text>
                            <Text style={Styles.tableCellHeader}>Truck</Text>
                            <Text style={Styles.tableCellHeader}>Owner</Text>
                            <Text style={Styles.tableCellHeader}>Departure</Text>
                            <Text style={Styles.tableCellHeader}>Arrival</Text>
                            {/* <Text style={Styles.tableCellHeader}>Income</Text> */}
                            <Text style={Styles.tableCellHeader}>Status</Text>
                            {/* <Text style={Styles.tableCellHeaderSmall}>Delete</Text> */}
                            {/* <Text style={Styles.tableCellHeaderSmall}>Print</Text> */}
                        </View>
                    </View>
                    <FlatList
                        data={fOrders}
                        keyExtractor={(item) => item.idfreightOrder}
                        renderItem={listFO}
                    />
                </View>

                <View style={Styles.topRightContainer}>
                    <View style={Styles.mainformContainer2}> {/* title container */}
                        <View style={Styles.modalTitleContainer}><Text style={Styles.modalTitle}>New Invoice</Text></View>
                        <View style={Styles.formContainer}>
                            <TextInput
                                ref={invoiceInputRef}
                                style={Styles.inputsmall}
                                placeholder="Invoice No."
                                onChangeText={setInvoice}
                            />
                            <TextInput
                                ref={customerInputRef}
                                style={Styles.inputmedium}
                                placeholder="Customer"
                                onChangeText={setCustomer}
                            />
                            <TouchableOpacity style={Styles.buttonContainer} onPress={clearInvoice}>
                                <Text style={Styles.buttonText}>Clear</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={Styles.buttonContainer} onPress={addInvoice}>
                                {addingInvoice ? <ActivityIndicator size="small" color={colors.white} /> : <Text style={Styles.buttonText}>Submit</Text>}
                            </TouchableOpacity>
                        </View>
                        <View style={Styles.tableHeader}>
                            <View style={Styles.tableRow}>
                                {/* <Text style={Styles.tableCellHeader}>ID</Text> */}
                                <Text style={Styles.tableCellHeader}>Invoice</Text>
                                <Text style={Styles.tableCellHeader}>Customer</Text>
                                <Text style={Styles.tableCellHeader}>Created</Text>
                                <Text style={Styles.tableCellHeaderSmall}>Delete</Text>
                            </View>
                        </View>
                        <FlatList
                            data={invoiceList}
                            keyExtractor={(item) => item.InvoiceID}
                            renderItem={listInvoice}
                        />
                    </View>
                </View>
            </View>

            <View style={Styles.bottomContainer}>
                <View style={Styles.mainformContainer}> {/* title container */}
                    <View style={Styles.modalTitleContainer}><Text style={Styles.modalTitle}>{selectedInvoice ? (`Adding Freight Orders to Invoice No: ${selectedInvoice.InvoiceNumber}`) : (`Select Invoice to add freight orders to`)}</Text></View>
                    <View style={Styles.formContainer}>
                        <TextInput
                            ref={AddToInvoiceFOInputRef}
                            style={Styles.input}
                            placeholder="Freight order No."
                            onChangeText={setFOAddToInvoice}
                        />
                        <TextInput
                            ref={AddToInvoiceAmountInputRef}
                            style={Styles.input}
                            placeholder="Amount"
                            onChangeText={setAmountAddToInvoice}
                        />
                        <TouchableOpacity style={Styles.buttonContainer} onPress={clearAddToInvoice}>
                            <Text style={Styles.buttonText}>Clear</Text>
                        </TouchableOpacity>
                        <TouchableOpacity style={Styles.buttonContainer} onPress={addToInvoice}>
                            {addingToInvoice ? <ActivityIndicator size="small" color={colors.white} /> : <Text style={Styles.buttonText}>Submit</Text>}
                        </TouchableOpacity>
                        {invoiceItemsList != '' ?
                            <TouchableOpacity style={Styles.buttonContainer} onPress={downloadInvoiceItems}>
                                {downloadingInvoice ? <ActivityIndicator size="small" color={colors.white} /> : <><Text style={Styles.buttonText}><Feather name="download" size={14} color={colors.white} />  Download</Text></>}
                            </TouchableOpacity>
                            : null}
                    </View>
                </View>

                <View style={Styles.tableHeader}>
                    <View style={Styles.tableRow}>
                        <Text style={Styles.tableCellHeader}>ID</Text>
                        <Text style={Styles.tableCellHeader}>App ID</Text>
                        <Text style={Styles.tableCellHeader}>Freight</Text>
                        <Text style={Styles.tableCellHeader}>Truck</Text>
                        <Text style={Styles.tableCellHeader}>Owner</Text>
                        <Text style={Styles.tableCellHeader}>Origin</Text>
                        <Text style={Styles.tableCellHeader}>Destination</Text>
                        <Text style={Styles.tableCellHeader}>Weight</Text>
                        <Text style={Styles.tableCellHeader}>Tariff</Text>
                        <Text style={Styles.tableCellHeader}>Rate</Text>
                        <Text style={Styles.tableCellHeader}>Delete</Text>
                    </View>
                </View>
                {gettingInvoiceItems && selectedInvoice ?
                    <View style={Styles.loadingContainer}>
                        <ActivityIndicator size={14} color={colors.primary} />
                    </View> :
                    <FlatList
                        data={invoiceItemsList}
                        keyExtractor={(item) => item.idfreightOrder}
                        renderItem={listInvoiceItems}
                    />
                }
            </View>
        </View>
    );
}

const Styles = StyleSheet.create({
    mainContainer: {
        flex: 1,
        width: '100%',
        // alignItems: 'center',
        // justifyContent: 'center',
        flexDirection: 'column',
        backgroundColor: colors.AppCanvasMain,
        height: SCREEN_HEIGHT,
    },
    loadingContainer: {
        flex: 1,
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
    topContainer: {
        height: '45%',
        width: '100%',
        backgroundColor: colors.ForeGroundWhite,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    topLeftContainer: {
        height: '100%',
        width: '61%',
        backgroundColor: colors.ForeGroundWhite,
    },
    topRightContainer: {
        height: '100%',
        width: '38%',
        // backgroundColor: 'red'
    },
    bottomContainer: {
        height: '55%',
        width: '100%',
        backgroundColor: colors.ForeGroundWhite,
        paddingBottom: 5,
    },
    table: {
        borderWidth: 1,
        borderColor: '#ddd',
        margin: 10,
    },
    row: {
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: '#ddd',
    },
    cell: {
        flex: 1,
        padding: 10,
        textAlign: 'center',
    },
    headerCell: {
        flex: 1,
        padding: 10,
        textAlign: 'center',
        fontWeight: 'bold',
    },



    smallMenuContainer: {
        elevation: 8,
        backgroundColor: '#031D22',
        height: '10%',
        width: '100%',
        borderRadius: 5,
        flexDirection: 'row',
        justifyContent: 'space-evenly',
        alignItems: 'center'
    },
    smallMenuButton: {
        // backgroundColor: 'green',
        paddingHorizontal: 9,
        paddingVertical: 6,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    selectedsmallMenuButton: {
        // backgroundColor: 'grey',
        backgroundColor: '#09414c',
        paddingHorizontal: 9,
        paddingVertical: 6,
        borderRadius: 5,
        alignItems: 'center',
        justifyContent: 'center'
    },
    smallMenuText: {
        fontSize: 16,
        fontWeight: 600,
        color: colors.offwhite,
    },
    truckDetailContainer: {
        height: '86%',
        width: '100%',
        margin: 2,
        elevation: 8,
        borderRadius: 5,
        backgroundColor: colors.AppCanvasMain,
    },
    mainformContainer: {
        width: '100%',
        flexDirection: 'column',
        backgroundColor: colors.AppCanvasMain,
        elevation: 9,
        borderRadius: 5,
        justifyContent: 'center',
        paddingLeft: 2,
        borderColor: colors.skyblue,
        borderWidth: 1
    },
    mainformContainer2: {
        width: '100%',
        flexDirection: 'column',
        backgroundColor: colors.AppCanvasMain,
        elevation: 9,
        borderRadius: 5,
        justifyContent: 'center',
        paddingLeft: 4,
        borderColor: colors.skyblue,
        borderWidth: 1
    },
    modalTitleContainer: {
        // backgroundColor: 'orange',

    },
    twoColContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    truckDetailLeft: {
        height: '100%',
        width: '50%',
        // backgroundColor: 'ornage'
    },
    truckDetailRight: {
        height: '100%',
        width: '50%',
        backgroundColor: 'green'
    },
    tableTop: {
        // height: '40%',
        width: '100%',
        // backgroundColor: 'green',
        borderWidth: 1,
        marginLeft: 4,
        borderColor: 'skyblue',
        flexDirection: 'row'
    },
    tableLeft: {
        // height: '40%',
        width: '48%',
        // backgroundColor: 'green',
        borderWidth: 1,
        marginLeft: 4,
        borderColor: 'skyblue',
    },
    tableHeader: {
        // height: '40%',
        width: '100%',
        // backgroundColor: 'green',
        borderWidth: 1,
        borderColor: 'skyblue',
    },
    searchContainer: {
        width: '12%',
        flexDirection: "Row",
        height: 30,
        marginBottom: 2
    },
    tableRow: {
        flex: 1,
        flexDirection: 'row',
        borderBottomWidth: 1,
        borderBottomColor: 'skyblue',
        //paddingVertical: 3,
        // marginHorizontal: 5,
        paddingVertical: 5,
        backgroundColor: '#f0f0f0', // Header background color,
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCellHeader: {
        flex: .25,
        // padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 3,
    },
    tableCellHeaderSmall: {
        flex: .2,
        // padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableDateCellHeader: {
        flex: .5,
        padding: 2,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCell: {
        flex: .25,
        // marginLeft: 3,
        alignItems: 'center',
        justifyContent: 'center',
        marginHorizontal: 3,
    },
    tableCellDelete: {
        flex: .25,
        justifyContent: 'center',
    },
    tableDateCell: {
        flex: .5,
        // marginLeft: 3,
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableCellIcon: {
        flex: .25,
        // paddingLeft: 10,
        alignItems: 'center',
        justifyContent: 'center',
    },
    modalTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        // marginTop: 1,
    },
    tableTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        marginBottom: 2,
    },
    formContainer: {
        flexDirection: 'row',
        marginTop: 2,
        marginBottom: 2,
    },
    input: {
        height: 27,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        width: 130
    },
    inputsmall: {
        height: 27,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        width: 100
    },
    inputmedium: {
        height: 27,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 2,
        marginRight: 2,
        width: 116
    },
    datepickerStyle: {
        width: 120,
        height: 15,
        marginRight: 2
    },
    inputTableSearch: {
        height: 27,
        borderColor: 'skyblue',
        borderWidth: 1,
        borderRadius: 5,
        paddingHorizontal: 5,
        marginRight: 2,
        marginLeft: 2,
        marginBottom: 2,
        width: 160
    },
    buttonContainer: {
        marginLeft: 2,
        paddingHorizontal: 3,
        backgroundColor: '#2196F3',
        height: 27,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    smallButtonContainer: {
        marginLeft: 2,
        marginTop: 2,
        paddingHorizontal: 3,
        backgroundColor: '#2196F3',
        height: 28,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
    },
    buttonText: {
        color: 'white',
        fontWeight: 600,
        paddingHorizontal: 3,
    },
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: colors.AppCanvasMain
    },
    list: {
        width: '100%',
    },
    contactItem: {
        padding: 10,
        borderBottomWidth: 1,
        borderBottomColor: 'gray',
    },
    contactName: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    headerContainer: {
        flexDirection: 'row',
        // alignItems: ''
        justifyContent: 'space-between',
        width: '100%',
        //  backgroundColor: colors.offwhite,
        paddingHorizontal: 12,
        backgroundColor: colors.AppCanvasMain,
        //paddingVertical: 10,
    },
    headerRight: {
        flexDirection: 'row',
        alignItems: 'center',
        // marginRight: 
    },
    headeText: {
        fontSize: 16,
        fontWeight: 'bold'
    },
    searchBarContainer: {
        width: "100%",
        height: 40,
        flexDirection: "row",
        alignContent: "space-between",
        // justifyContent: "center",
        backgroundColor: colors.AppCanvasMain
    },
    FOContainer: {
        width: "100%",
        // height: 350,
        flex: 1,
        flexDirection: "row",
        // alignContent: "space-between",
        justifyContent: "center",

        // alignItems: 'center',
        // backgroundColor: 'orange',
        paddingTop: 2,
        backgroundColor: colors.AppCanvasMain,
        // height: SCREEN_HEIGHT,
    },
    Leftview: {
        width: '27%',
        flexDirection: 'column',
        height: (SCREEN_HEIGHT - 5),
    },
    rightView: {
        backgroundColor: colors.AppCanvasMain,
        width: '73%',
        height: SCREEN_HEIGHT,
    },
    NoFOContainer: {
        width: "100%",
        // height: 350,
        flex: 1,
        flexDirection: "row",
        // alignContent: "space-between",
        justifyContent: "center",

        alignItems: 'center',
        // backgroundColor: 'orange',
        paddingTop: 5,
        flexDirection: 'column',
        backgroundColor: colors.AppCanvasMain
    },
    AppTextInput: {
        width: "80%",
        height: 40,
        borderRadius: 5,
        borderColor: colors.primary,
        borderWidth: 0.5,
        paddingLeft: 10,
        marginLeft: 5,
        //position: "relative",
        top: 0,
        //alignItems: "center",
        fontSize: 14,
        color: colors.primary,
    },
});