import React from 'react';
import { StyleSheet, View, StatusBar } from 'react-native';
import colors from './colors';

function AppCanvas({ children }) {

    return (
        <View style={styles.Canvas}>
            <StatusBar barStyle='light-content' backgroundColor={colors.black} />
            {children}
        </View>
    )
}

const styles = StyleSheet.create({
    Canvas: {
        flex: 1,
        alignItems: 'center',
        backgroundColor: colors.AppCanvasMain,
        width: '100%'
    },
})

export default AppCanvas
